export enum ApiMockModesEnum {
  NoMocks = 'NoMocks',
  AllMocks = 'AllMocks',
  MockUnimplemented = 'MockUnimplemented'
}

export enum AccountType {
  Unknown = 'Unknown',
  Patient = 'Patient',
  Clinician = 'Clinician',
  Navigator = 'Navigator',
  SiteAdmin = 'SiteAdmin',
  Implementer = 'Implementer',
  SuperUser = 'SuperUser',
  ROSARep = 'ROSARep',
  Surgeon = 'Surgeon',
  CustomerSupport = 'CustomerSupport'
}

export enum SupportedLanguageStatus {
  Off = 'Off',
  Provisional = 'Provisional',
  Active = 'Active',
  None = 'None'
}

export enum OrientationTypeEnum {
  OrientationType_None = 'OrientationType_None',
  OrientationType_Left = 'OrientationType_Left',
  OrientationType_Right = 'OrientationType_Right',
  OrientationType_Bilateral = 'OrientationType_Bilateral',
  OrientationType_NotApplicable = 'OrientationType_NotApplicable',
}

export enum UserProfileStatusEnum
{
  Activate = 'Activate',
  Deactivate = 'Deactivate',
  CanDeactivate = 'CanDeactivate',
  None = 'None'
}


// Alert

export enum AlertVariant {
  Error = 'error',
  Message = 'message',
  Warning = 'warning'
}

// Box

export enum BoxPaddingEnum {
  none = '',
  pa15 = 'pa-15',
  pa20 = 'pa-20',
  pa30 = 'pa-30',
  pa60 = 'pa-60',
  p2025 = 'p-20-25',
  p4030 = 'p-40-30',
  p303040 = 'p-30-30-40'
}

export enum BoxTypeEnum {
  none = '',
  blue = 'blue-box',
  white = 'white-box'
}

// Button

export enum ButtonHeightEnum {
  sm = 'sm',
  md = 'md',
  lg = 'lg'
}

export enum ButtonLnkColorEnum {
  default = '',
  body = 'body',
  bodyAlt = 'body-alt'
}

export enum ButtonStdColorEnum {
  accent = 'accent',
  primaryColor = 'primary-color',
  secondaryColor = 'secondary-color',
  primaryColorText = 'primary-color-txt',

  // Deprecated
  darkTeal = 'primary-color',
  darkTealText = 'primary-color-txt',
}

export enum ButtonStyleEnum {
  primary = 'button-primary',
  secondary = 'button-secondary',
  link = 'btn-link btn-link--other',
}

export enum ButtonTypeEnum {
  button = 'button',
  reset = 'reset',
  submit = 'submit'
}

// Clinical Assignment

export enum AssignmentAction {
  clinician_assignment_action_create = 'clinician_assignment_action_create',
  clinician_assignment_action_edit = 'clinician_assignment_action_edit',
  clinician_assignment_action_comment = 'clinician_assignment_action_comment',
  clinician_assignment_action_in_progress = 'clinician_assignment_action_in_progress',
  clinician_assignment_action_reopen = 'clinician_assignment_action_reopen',
  clinician_assignment_action_resolve = 'clinician_assignment_action_resolve',
}

export enum AssignmentDetail {
  clinician_assignment_detail_assignee_from_change = 'clinician_assignment_detail_assignee_from_change',
  clinician_assignment_detail_assignee_to_change = 'clinician_assignment_detail_assignee_to_change',
  clinician_assignment_detail_urgency_change = 'clinician_assignment_detail_urgency_change',
  clinician_assignment_detail_status_change = 'clinician_assignment_detail_status_change',
  clinician_assignment_detail_description_change = 'clinician_assignment_detail_description_change',
  clinician_assignment_detail_comment = 'clinician_assignment_detail_comment',
}

export enum AssignmentStatus {
  clinician_assignment_status_assigned = 'clinician_assignment_status_assigned',
  clinician_assignment_status_in_progress = 'clinician_assignment_status_in_progress',
  clinician_assignment_status_resolved = 'clinician_assignment_status_resolved',
}

// Dialog

export enum DialogBorderRadius {
  br12 = 'br-12'
}

export enum DialogPadding {
  pbs40 = 'pbs-40'
}

export enum DialogType {
  confirm = 'q-dialog--confirm'
}

// Form

export enum FormElWidthEnum {
  qFormElNone = '',
  qFormElAuto = 'q-form-el--auto',
  qFormElFull = 'q-form-el--full',
  qFormEl100 = 'q-form-el--100',
  qFormEl150 = 'q-form-el--150',
  qFormEl200 = 'q-form-el--200',
  qFormEl250 = 'q-form-el--250',
  qFormEl310 = 'q-form-el--310'
}

// Input Group

export enum InputGroupWidthEnum {
  auto = 'input-group--w-auto',
  short = 'input-group--w-short',
  regular = 'input-group--w-regular'
}

export enum InputGroupModsEnum {
  default = '',
  onlyBtmBrdr = 'input-group--p-no-x input-group--brdr-bottom'
}

export enum InputGroupColorSchemeEnum {
  default = '',
  white = 'input-group--white'
}
