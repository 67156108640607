<template>
  <q-page padding id="patientDetailPage">

    <div id="pageError" v-if="pageErrorMessage">
      <div class="errorMessage">{{pageErrorMessage}}</div>
      <button class="button cancel" type="button" @click="ToPatientsList">{{ $t('gen_return_to_patients') }}</button>
    </div>

    <div v-if="loaded && !pageErrorMessage">

      <div id="patientDetailHeader">
        <h1>
          <q-icon name="far fa-solid fa-chevron-left" class="cursor-pointer" @click="ToPatientsList" />
          {{patient?.firstName}} {{patient?.lastName}}
        </h1>
        <span>
          {{ $t('local_time_label_prefix') }} <b>{{patientLocalTime}}</b>
        </span>
      </div>

      <div id="patientAccountSection">
        <div id="patientAccount" v-bind:class="{ editMode: editAccountMode }">
          <div class="panelActionButtonWrap">
            <q-icon name="far fa-solid fa-pencil" class="editIcon" @click="toggleEditProfile" />
            <q-icon name="far fa-solid fa-xmark" class="cancelIcon" @click="toggleEditProfile" />
          </div>
          <h5>{{$t('patient_details_title')}}</h5>

          <q-form @submit.prevent ref="formPatient">
            <div class="row">
              <div class="column">
                <dl>
                  <dt>{{$t('gen_first_name_initialCap')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{dashesIfMissing(patient?.firstName)}}</span>
                    <zbm-input-with-validation v-if="editAccountMode" v-model="pendingProfile.firstName"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                               :rules="[$zb.validators.required(),$zb.validators.maxLen(100),$zb.validators.minLen(1)]" />
                  </dd>
                  <dt>{{$t('gen_last_name_initialCap')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{dashesIfMissing(patient?.lastName)}}</span>
                    <zbm-input-with-validation v-if="editAccountMode" v-model="pendingProfile.lastName"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                               :rules="[$zb.validators.required(),$zb.validators.maxLen(100),$zb.validators.minLen(1)]" />
                  </dd>
                  <dt>{{$t('gen_date_of_birth_initialCap')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{formatDisplayedDate(patient?.birthday)}}</span>
                    <zbm-date-picker v-if="editAccountMode" v-model="pendingProfile.birthday" :showIcon=true
                                     :maxDate="maxBirthDate" :minDate="minBirthDate" />
                  </dd>
                  <dt>{{$t('phone')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{dashesIfMissing(formatPhoneNumber( patient?.phoneCountryCode, patient?.phone ))}}</span>

                    <zbm-telephone-with-validation v-model="phoneTelNumberInputModel" v-if="editAccountMode"
                                                   :patientCountryCode="pendingProfile?.phoneCountryCode"
                                                   placeholder="(###) ###-####"
                                                   :rules="[$zb.validators.required()]" />
                  </dd>
                  <dt>{{$t('gen_label_email')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{dashesIfMissing(patient?.email)}}</span>
                    <zbm-input-with-validation v-if="editAccountMode" v-model="pendingProfile.email"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                               :rules="[$zb.validators.requiredIf(() => patient?.hasPiqProcedure),
                                               $zb.validators.maxLen(100),
                                               $zb.validators.email()]" />
                  </dd>
                </dl>
              </div>
              <div class="column">
                <dl>
                  <dt>{{$t('gen_upid')}}</dt>
                  <dd>{{patient?.patientId}}</dd>
                  <dt>{{$t('gen_username_initialCap')}}</dt>
                  <dd>{{patient?.userName}}</dd>
                  <dt>{{$t('gen_activation_date')}}</dt>
                  <dd>{{formatDisplayedDate(patient?.activationDate)}}</dd>
                  <dt>{{$t('gen_enrollment_date')}}</dt>
                  <dd>{{dashesIfMissing(formatDisplayedDate(patient?.enrollmentDate))}}</dd>
                  <dt>{{$t('gen_deactivation_date')}}</dt>
                  <dd>{{dashesIfMissing(formatDisplayedDate(patient?.deactivationDate))}}</dd>
                  <dt>{{$t('gen_external_id')}}</dt>
                  <dd>{{dashesIfMissing(patient?.externalId)}}</dd>
                </dl>
              </div>
            </div>

            <div id="editAccountControls" v-if="editAccountMode">

              <dl>
                <dt>{{$t('gen_salesforce_case_id')}}</dt>
                <dd>
                  <zbm-input-with-validation v-model="pendingProfile.salesforceCaseId"
                                             :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                             :rules="[$zb.validators.required(),$zb.validators.maxLen(50),$zb.validators.minLen(1)]" />
                </dd>
                <dt>{{$t('gen_additional_notes')}}</dt>
                <dd><q-input outlined placeholder="" type="textarea" v-model="pendingProfile.notes" /></dd>
              </dl>

              <div class="column items-end">
                <div class="col q-gutter-sm">
                  <BtnStandard :color="ButtonStdColorEnum.primaryColorText"
                               :height="ButtonHeightEnum.md"
                               :to="{ name: 'cancelChanges', params: {  } }"
                               :label="$t('gen_cancel')"
                               @click="toggleEditProfile" />

                  <BtnStandard :color="ButtonStdColorEnum.primaryColor"
                               :height="ButtonHeightEnum.md"
                               :to="{ name: 'saveChanges', params: {  } }"
                               :label="$t('gen_save_changes')"
                               :disabled="!isPatientFormDirty" 
                               @click="savePatientProfile" />
                </div>
              </div>
            </div>
          </q-form>

        </div>

        <div id="patientAccount2FAWrap">
          <div id="patientAccount2FA">
            <h5>{{$t('latest_mfa_title')}}</h5>

            <div id="noVerificationsMsg" v-if="patient?.recentMFAResponses && patient?.recentMFAResponses.length==0">
              {{$t('gen_no_results_found')}}
            </div>

            <ul v-if="patient?.recentMFAResponses && patient?.recentMFAResponses.length>0">
              <li v-for="mfa in patient.recentMFAResponses">
                <span>
                  <q-icon v-if="mfa.status=='Verified'" name="far fa-solid fa-circle-check" title="{{$t('gen_verified')}}" color="success-icon-color" />
                  <q-icon v-if="mfa.status=='Pending'" name="far fa-solid fa-clock" title="{{$t('gen_pending')}}" />
                  <q-icon v-if="mfa.status=='Expired' || mfa.status==='Invalid'" name="far fa-solid fa-xmark" title="{{$t('gen_expired')}}" color="error-icon-color" />
                </span>
                <span>{{formatRecentDayTime(mfa.sentTime)}}</span>
                <span>{{mfa.trailingPinCode}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="procedureSelectorWrap">
        <h3>{{$t('patient_procedure_list_tab_title')}}</h3>
        <q-select v-if="procedureOptions && procedureOptions.length > 0" outlined v-model="selectedEOC" :options="procedureOptions" :dense="true" @update:model-value="eoc => selectProcedure(eoc)">
          <template v-slot:prepend>
            <q-icon name="fas fa-circle" size="xs" :color="selectedProcedureDisplayColor" />
          </template>

          <template v-slot:option="scope">
            <q-item class="options-border" v-bind="scope.itemProps" >
              <q-item-section avatar>
                <q-icon name="fas fa-circle" size="xs" :color="getProcedureDisplayColor(scope.opt.value)" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>

      <div id="patientProceduresSection">

        <div id="procedureDetails" v-bind:class="{ editMode: editProcedureMode }">

          <div class="panelActionButtonWrap">
            <q-icon name="far fa-solid fa-pencil" class="editIcon" @click="toggleEditProcedure" />
            <q-icon name="far fa-solid fa-xmark" class="cancelIcon" @click="toggleEditProcedure" />
          </div>
          <h5>{{$t('patient_procedure_details_title')}}</h5>

          <q-form @submit.prevent ref="formProcedure">

            <div class="row">
              <dl class="column">
                <dt>{{$t('gen_date_created')}}</dt>
                <dd>{{eoc?.createdDateLocalized}}</dd>
                <dt>{{$t('gen_date_discharged')}}</dt>
                <dd>{{dashesIfMissing(eoc?.ineffectiveDateLocalized)}}</dd>
                <dt>{{$t('gen_protocol')}}</dt>
                <dd>{{formattedProtocol}}</dd>
              </dl>
              <dl class="column">
                <dt>{{ $t('patient_implant_type') }}</dt>
                <dd>{{eoc?.hasZBImplant ? $t('patient_zbimplant') : $t('patient_otherimplant')}}</dd>

                <dt>{{ $t('gen_rosa_uppercase') }}</dt>
                <dd>
                  <span v-if="!editProcedureMode">{{eoc?.isROSAPatient}}</span>
                  <Toggle :disable="disableRosa" v-if="editProcedureMode && eoc" v-model="pendingProcedure.rosaEnabled" />
                </dd>
                <dt>{{ $t('piq_stats_header') }}</dt>
                <dd>
                  <span v-if="!editProcedureMode">{{eoc?.isPIQPatient}}</span>
                  <Toggle :disable="disablePIQ" v-if="editProcedureMode && eoc" v-model="pendingProcedure.piqEnabled" />
                </dd>
                <dt>{{$t('gen_clinical_study_cap')}}</dt>
                <dd>{{eoc?.isClinicalStudyPatient}}</dd>
              </dl>
              <dl class="column">
                <dt>{{$t('gen_client_name')}}</dt>
                <dd>{{patient?.client?.clientName}}</dd>
                <dt>{{$t('gen_surgeon_initialCap')}}</dt>
                <dd>{{dashesIfMissing(eoc?.surgeonNameLocalized)}}</dd>
                <dt>{{$t('gen_care_team')}}</dt>
                <dd>{{dashesIfMissing(eoc?.careTeam?.careTeamName)}}</dd>
                <dt>{{$t('gen_location_initialCap')}}</dt>
                <dd>{{dashesIfMissing(eoc?.procedureLocation)}}</dd>
              </dl>
            </div>

            <div id="editProcedureControls" v-if="editProcedureMode">

              <dl>
                <dt>{{$t('gen_salesforce_case_id')}}</dt>
                <dd>
                  <zbm-input-with-validation v-model="pendingProcedure.salesforceCaseId"
                                             :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                             :rules="[$zb.validators.required(),$zb.validators.maxLen(50),$zb.validators.minLen(1)]" />
                </dd>
                <dt>{{$t('gen_additional_notes')}}</dt>
                <dd><q-input outlined placeholder="" type="textarea" v-model="pendingProcedure.notes" /></dd>
              </dl>

              <div class="column items-end">
                <div class="col q-gutter-sm">
                  <BtnStandard :color="ButtonStdColorEnum.primaryColorText"
                               :height="ButtonHeightEnum.md"
                               :to="{ name: 'cancelChanges', params: {  } }"
                               :label="$t('gen_cancel')"
                               @click="toggleEditProcedure" />

                  <BtnStandard :color="ButtonStdColorEnum.primaryColor"
                               :height="ButtonHeightEnum.md"
                               :to="{ name: 'saveChanges', params: {  } }" 
                               :label="$t('gen_save_changes')" 
                               :disabled="!isProcedureFormDirty"
                               @click="savePatientProcedure" />
                </div>
              </div>

            </div>

          </q-form>

        </div>
      </div>

      <div id="patientDetailsTabSection">
        <q-card>
          <q-tabs v-model="tab"
                  dense
                  class="text-grey"
                  active-color="primary"
                  indicator-color="primary"
                  align="justify"
                  narrow-indicator>
            <q-tab name="careTeam" :label="$t('gen_care_team')" />
            <q-tab name="client" :label="$t('gen_client')" />
            <q-tab name="personaIq" :label="$t('piq_stats_header')" />
          </q-tabs>

          <q-separator />

          <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="careTeam">
              <CareTeamTab :careTeam="eoc?.careTeam" />
            </q-tab-panel>

            <q-tab-panel name="client">
              <q-scroll-area id="clientScrollArea">
                <ClientTab :client-features=client?.clientFeatures />
              </q-scroll-area>
            </q-tab-panel>

            <q-tab-panel name="personaIq" v-if="patient?.hasPiqProcedure == true">
              <PersonaIqTab :canaryDetail="patient?.canaryDetail" :selectedProcedureLaterality="eoc?.lateralityAbbreviationLocalized == 'L' ? 'LeftKnee' : eoc?.lateralityAbbreviationLocalized == 'R' ? 'RightKnee' : ''" />
            </q-tab-panel>
          </q-tab-panels>
        </q-card>
      </div>

      <BtnStandard :color="ButtonStdColorEnum.primaryColorText"
                   :height="ButtonHeightEnum.md"
                   :to="{ name: 'ChangeLog', params: { 'userProfileId':patientId  } }"
                   :label="$t('gen_view_change_log')"/>


    </div>
  </q-page>
</template>

<script setup lang="ts">
  import { ref, computed, onMounted, nextTick, type Ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useQuasar } from 'quasar';
  import BtnStandard from "@/components/BtnStandard.vue"
  import {ButtonHeightEnum, ButtonStdColorEnum} from "@/constants/enums";
  import { handleApiRequest } from '@/api/handleApiRequest';
  import {
    getPatientDetail, updatePatientProfile, updatePatientProcedure,
    type RecentMFAResponse, type EpisodeResponse, type UpdatePatientRequest, type UpdatePatientResponse,
    type UpdatePatientProcedureRequest, type UpdatePatientProcedureResponse, type GetPatientSupportDetailsResponse
  } from "@/api/endpoints/patientWeb";
  import { getAntiForgeryToken } from "@/api/endpoints/userWeb";
  import moment from "moment";
  import CareTeamTab from './components/CareTeamTab.vue';
  import ClientTab from './components/ClientTab.vue';
  import PersonaIqTab from './components/PersonaIqTab.vue';
  import { parsePhoneNumber, type CountryCode } from 'libphonenumber-js';
  import Toggle from "@/components/form/Toggle.vue";
  import {i18n} from '@/plugins/i18n';
  import {getClientDetail, type GetClientSupportDetailsResponse} from "@/api/endpoints/clientWeb";
  import {type DropdownItem} from "@/components/Dropdown.vue";


  const patientId=ref(0),
        loaded=ref(false),
        pageErrorMessage = ref(''),
        editAccountMode = ref(false),
        editProcedureMode = ref(false),
        disableRosa = ref(false),
        disablePIQ = ref(false),
        priorNotify= function(){ },
        eoc: Ref<EpisodeResponse | undefined> = ref(),
        selectedEOCId = ref(0),
        selectedEOC = ref<DropdownItem>(),
        patient: Ref<GetPatientSupportDetailsResponse | undefined> = ref(),
        pendingProfile: Ref<UpdatePatientRequest | undefined> = ref(),
        pendingProcedure: Ref<UpdatePatientProcedureRequest | undefined> = ref(),
        formPatient = ref(),
        formProcedure = ref(),
        $q=useQuasar(),
        $route = useRoute(),
        router = useRouter(),
        phoneTelNumberInputModel = ref({
          value: '',
          countryCode:'',
          phoneObject: {
            national: '',
            nationalNumber: '',
            countryCallingCode: '',
            valid:true
          }
        }),
        client: Ref<GetClientSupportDetailsResponse | undefined> = ref(),
        tab = ref('careTeam');

  let initialPatientFormState = '',
      initialProcedureFormState = '';

  const props = defineProps({
    minBirthDate: {
      type: String,
      default: moment().add(-110, 'year').format()
    },
    maxBirthDate: {
      type: String,
      default: moment().add(-13, 'year').format()
    },
  });

  onMounted(async () => {
    patientId.value = $route.params?.patientId != null ? parseInt($route.params.patientId + "") : 0;
    await loadPatient();
  });

  const loadPatient = async () => {
    loaded.value = false;
    $q.loading.show();

    if (patientId?.value && patientId.value > 0) {
      const patientResponse = await handleApiRequest<GetPatientSupportDetailsResponse>(() => getPatientDetail(patientId.value));

      if (patientResponse?.data?.result == null || patientResponse?.data?.errorMessage) {
        pageErrorMessage.value = patientResponse?.data?.errorMessage ?? i18n.global.t('error_unexpected_occured').toString();
      } else {
        patient.value = patientResponse.data.result as GetPatientSupportDetailsResponse;

        if (patient.value?.episodes != null && patient.value?.episodes.length > 0) {
          eoc.value = patient.value?.episodes[0];
          if (!selectedEOC.value) {
            selectedEOC.value = {
              value: eoc.value.eocId,
              label: selectProcedureOptionText(eoc.value),
            } as DropdownItem

            selectedEOCId.value = eoc.value.eocId;
          }
        }

        if (patient?.value?.client != null) {
          const clientResponse = await handleApiRequest<GetClientSupportDetailsResponse>(() => getClientDetail(patient?.value?.client?.id));
          client.value = clientResponse.data;
        }
      }

    } else {
      pageErrorMessage.value = i18n.global.t('error_invalid_user_id').toString();
    }

    loaded.value = true;
    $q.loading.hide();
  }

  const formatDisplayedDate = (dateStr: string) => {
    if (!dateStr) return null;
    var d = new Date(dateStr);
    return d.toLocaleString('default', { month: 'long' }) + " " + d.getDate() + ", " + d.getFullYear();
  }

  const formatRecentDayTime = (dateStr: string) => {
    if (!dateStr) return null;
    var d = new Date(dateStr);
    return d.toLocaleDateString('default', { weekday: 'long' }) +", "+d.toLocaleString('default', { month: 'long' }) + ", " + d.getDate() + " " + d.toLocaleTimeString();
  }

  const patientLocalTime = computed(() => {
    let d = new Date();
    const patientOffset = patient?.value?.timezoneOffset ?? 0;
    const timezoneOffset = d.getTimezoneOffset() / 60;
    const difference = patientOffset + timezoneOffset;
    const localDate = new Date(d.getTime() + difference * 3600000);
    const day = localDate.toLocaleDateString('default', { weekday: 'long' });
    const timeAMPM = localDate.toLocaleTimeString(undefined, { hour12: true, timeStyle: 'short' });
    const time24Hr = localDate.toLocaleTimeString(undefined, { hour12: false, timeStyle: 'short' });
    return `${day}, ${timeAMPM} (${time24Hr})`;
  })

  const formatPhoneNumber = (countryCodeNumber: string, phoneNumber: string) => {
    if (!countryCodeNumber || !phoneNumber || phoneNumber?.length < 3)
      return phoneNumber;

    let fullNumber = '+' + countryCodeNumber + phoneNumber;
    let countryCodeStr = (countryCodeNumber == '1' ? 'US' : '');
    let parsedPhoneNumber = parsePhoneNumber(fullNumber, countryCodeStr as CountryCode);

    return parsedPhoneNumber
      ? parsedPhoneNumber.formatInternational()
       : countryCodeNumber +" "+phoneNumber;
  }

  const formattedProtocol = computed(() => {
    if (!eoc?.value?.protocolNameLocalized) return '--';
    return eoc?.value.protocolNameLocalized+' ('+ eoc?.value?.protocolEventLevelNameLocalized+')';
  })


  const dashesIfMissing = (str: string | undefined) => {
    return str ? str : '--';
  }


  const toggleEditProfile = () => {
    formPatient.value.resetValidation();
    editAccountMode.value = !editAccountMode.value;
    const data = {} as UpdatePatientRequest;
    data.patientId = patient?.value?.patientId??0;
    data.firstName = patient?.value?.firstName;
    data.lastName = patient?.value?.lastName;
    data.email = patient?.value?.email;
    data.birthday = patient?.value?.birthday;
    data.phone = patient?.value?.phone;
    data.phoneCountryCode = patient?.value?.phoneCountryCode;
    data.salesforceCaseId = '';
    data.notes = '';
    phoneTelNumberInputModel.value.value = '+' + patient.value?.phoneCountryCode + patient.value?.phone;
    phoneTelNumberInputModel.value.countryCode = patient.value?.phoneCountryCode ?? '';
    initialPatientFormState = JSON.stringify(data);
    pendingProfile.value = data;
  }


  const toggleEditProcedure = () => {
    formProcedure.value.resetValidation();
    editProcedureMode.value = !editProcedureMode.value;
    const data = {} as UpdatePatientProcedureRequest;
    data.patientId = patient.value?.patientId ?? 0;
    data.eocId = eoc.value?.eocId ?? 0;
    disableRosa.value = patient.value?.client?.rosaEnabled != true || eoc.value?.isROSAProcedure != true || eoc.value?.isROSASurgeon != true;
    disablePIQ.value = patient.value?.client?.piqEnabled != true || eoc.value?.isPIQProcedure != true || eoc.value?.isPIQSurgeon != true;
    data.rosaEnabled = disableRosa.value ? undefined : eoc.value?.isROSAPatient;
    data.piqEnabled = disablePIQ.value ? undefined : eoc.value?.isPIQPatient;
    data.salesforceCaseId = '';
    data.notes = '';
    initialProcedureFormState = JSON.stringify(data);
    pendingProcedure.value = data;
  }


  const procedureOptions = computed(() => {
    return patient?.value?.episodes?.map((episode) => {
      return {
        value: episode.eocId,
        label: selectProcedureOptionText(episode),
      } as DropdownItem
    })})

  const selectProcedure = (item: DropdownItem) => {
    editProcedureMode.value = false;
    eoc.value = patient.value?.episodes?.find(e => e.eocId == item.value);
    selectedEOCId.value = eoc.value?.eocId ?? 0;
  }

  const selectProcedureOptionText = (eoc: EpisodeResponse | undefined) => {
    if (eoc?.procedureNameLocalized) {
      const lateralityString = eoc?.lateralityAbbreviationLocalized ? `(${eoc?.lateralityAbbreviationLocalized})` : '';
      return `${eoc?.procedureNameLocalized} ${lateralityString} on ${eoc.surgeryDateLocalized}`;
    } else {
      return i18n.global.t('gen_no_procedure_assigned').toString();
    }
  }

  const getProcedureDisplayColor = (eocId: number) => {
    const eoc = patient.value?.episodes?.find(e => e.eocId == eocId);
    if (!eoc) return '';

    if (eoc.status == 'Active')
      return 'green';

    return 'grey';
  }

  const selectedProcedureDisplayColor = computed(() => {
    return getProcedureDisplayColor(selectedEOCId.value);
  })

  const isPatientFormDirty = computed(() => {
    return initialPatientFormState !== JSON.stringify(pendingProfile.value);
  });

  const isProcedureFormDirty = computed(() => {
    return initialProcedureFormState !== JSON.stringify(pendingProcedure.value);
  });

  const savePatientProfile = async (ev: Event) => {

    if (phoneTelNumberInputModel.value?.phoneObject?.valid === false || !pendingProfile.value) return false;
    pendingProfile.value!.phone = phoneTelNumberInputModel.value?.phoneObject?.nationalNumber ?? pendingProfile?.value?.phone;
    pendingProfile.value!.phoneCountryCode = phoneTelNumberInputModel.value?.phoneObject?.countryCallingCode ?? pendingProfile?.value?.phoneCountryCode;

    // clear prior notification
    priorNotify?.value && priorNotify.value();

    const isValid = await formPatient.value.validate()
    if (!isValid) return;

    $q.loading.show();

    pendingProfile.value.birthday = moment(pendingProfile.value.birthday).format('YYYY-MM-DD').toString();
    await getAntiForgeryToken();
    const updateResponse = await handleApiRequest<UpdatePatientResponse>(() => updatePatientProfile(pendingProfile.value!));
    console.log(updateResponse);

    if (updateResponse == null || updateResponse?.data == null || updateResponse?.data?.errorMessage || updateResponse.status != 200) {
      priorNotify.value = $q.notify({
        type: 'warning',
        message: updateResponse?.data?.errorMessage ?? updateResponse?.errorMessage ?? i18n.global.t('error_unexpected_occured').toString()
      });
    }
    else {
      loaded.value = true;
      editAccountMode.value = false;

      await loadPatient();

      priorNotify.value = $q.notify({
        type: 'message',
        message:  i18n.global.t('edit_profile_changes_saved').toString()
      });
    }

    $q.loading.hide();
  }

  const savePatientProcedure = async (ev: Event) => {

    if (!pendingProcedure.value) return;

    // clear prior notification 
    priorNotify?.value && priorNotify.value();

    const isValid = await formProcedure.value.validate()
    if (!isValid) return;

    $q.loading.show();
    
    await getAntiForgeryToken();
    const updateResponse = await handleApiRequest<UpdatePatientProcedureResponse>(() => updatePatientProcedure(pendingProcedure.value as UpdatePatientProcedureRequest));
    console.log(updateResponse);

    if (updateResponse == null || updateResponse?.data == null || updateResponse?.data?.errorMessage || updateResponse.status != 200) {
      priorNotify.value = $q.notify({
        type: 'warning',
        message: updateResponse?.data?.errorMessage ?? updateResponse?.errorMessage ?? i18n.global.t('error_unexpected_occured').toString()
      });
    }
    else {
      loaded.value = true;
      editProcedureMode.value = false;

      await loadPatient();

      priorNotify.value = $q.notify({
        type: 'message',
        message: i18n.global.t('edit_profile_changes_saved').toString()
      });
    }

    $q.loading.hide();
  }


  const ToPatientsList = (evt:Event) => {
    router.push({
      name: 'Patients',
      params: {}
    });
  }

</script>

<style lang="scss">

  @import "@/css/styles-quasar";

  #clientScrollArea {
    height: 200px;
  }

  .options-border {
    border: 1px solid rgba($black, 0.12);
    border-radius: $generic-border-radius-sm;
  }

  #patientDetailPage {

    #pageError {
      text-align: center;
      font-weight: bold;
      padding: $space-double;

      .errorMessage {
        margin-bottom: $space-double;
        font-size: 18px;
      }
    }

    .panelActionButtonWrap {
      float: right;
      text-align: right;
      width: 20px;
      height: 20px;

      .editIcon,
      .cancelIcon {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      .cancelIcon {
        display: none;
      }
    }

    .editMode {
      .panelActionButtonWrap {
        .editIcon {
          display: none;
        }

        .cancelIcon {
          display: block;
        }
      }
    }

    dl {
      display: grid;
      grid-gap: 4px 16px;
      grid-template-columns: auto minmax(0, 1fr);
      align-content: flex-start;
      padding: 0;
      max-width: 100%;

      dt {
        color: $body-dim-txt-clr;
      }

      dd {
        margin: 0;
        grid-column-start: 2;
        overflow-wrap: anywhere;
      }
    }

    .row {
      gap: $space-base;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      max-width: 100%;

      .column {
        flex: 1;
        max-width: 48%;
      }
    }

    #patientDetailHeader {
      margin-bottom: $space-double;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: $space-base;

      .fa-chevron-left {
        font-weight: bold;
        margin-right: $space-base;
      }

      span {
        padding-top: 10px;
      }
    }

    #patientAccountSection {
      display: flex;
      flex-direction: row;
      gap: $space-base;
      justify-content: space-between;
      margin-bottom: $space-double;

      #patientAccount {
        @extend .panel;
        flex: 66%;
        position: relative;

        #editAccountControls {
          border-top: 1px solid $border-clr;
          margin-top: $space-base;
          padding-top: $space-base;
        }
      }

      #patientAccount2FAWrap {
        flex: 33%;
        padding: 0;

        #patientAccount2FA {
          @extend .panel;
          flex: 33%;
          padding: 0;

          h5 {
            padding: $space-base;
          }

          #noVerificationsMsg {
            color: $body-dim-txt-clr;
            padding: 0 $space-base $space-base $space-base;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              display: flex;
              border-top: 1px solid $border-clr;
              justify-content: space-between;
              gap: $space-half;
              padding: $space-half $space-base;
              margin: 0;

              span {
                flex: 70%;
                color: $body-txt-clr;
                text-align: left;

                &:first-child {
                  flex: 5%;
                  font-size: 1.1rem;
                  line-height: 1.1rem;
                  text-align: center;
                }

                &:last-child {
                  flex: 25%;
                  font-size: $note-font-size;
                  line-height: $note-line-height;
                  color: $body-dim-txt-clr;
                  text-align: right;
                }
              }
            }
          }
        }
      }

      @media screen and ( max-width:$breakpoint-lg ) {
        flex-direction: column;
      }
    }

    #procedureSelectorWrap {
      margin-bottom: $space-base;
      width: 66%;
    }

    #patientProceduresSection {
      @extend .panel;
      margin-bottom: $space-double;

      #procedureDetails {
        position: relative;
      }
    }


    #patientDetailsTabSection {
      margin-bottom: $space-double;
    }
  }
</style>
