import axios from '@/api/axiosConfig'
import {ApiRoutes} from '../ApiRoutes'
import type { ApiResponse } from '@/types/apiTypes';
import type { AxiosResponse } from 'axios';
import type {AccountType} from "@/constants/enums";


export interface GetPatientSupportDetailsResponse {
  patientId: number;
  email: string;
  phone: string;
  phoneCountryCode: string;
  userName: string;
  firstName: string;
  lastName: string;
  age: number;
  gender: string;
  mRN: string;
  isDeactivated: boolean;
  timezoneOffset: number;
  birthday: Date;
  enrollmentDate: Date;
  activationDate: Date
  deactivationDate: Date;
  externalId: string;
  recentMFAResponses: RecentMFAResponse[];
  episodes: EpisodeResponse[];
  errorMessage: string;
  client: Client;
  hasPiqProcedure: boolean;
  canaryDetail: CanaryDetail;
}

export interface RecentMFAResponse {
  trailingPinCode: string;
  sentTime: Date;
  sentTimeLocalized: string;
  status: string;
}

export async function getPatients() {
  return await axios.get(ApiRoutes.Patient.GetPatientsList);
}


export async function getPatientsWithFilter(applyFilter: string, accountType: AccountType, upId: string, status: string,
                                            firstName: string, lastName: string, email: string, phoneNumber: string,
                                            clientName: string, birthDate: string, mrn: string, studyId: string,
                                            clientCountry: string, clientType: string, clients: Array<string>, permissions: Array<string>,
                                            orderDesc: boolean, orderByHeader: string, page: number, pageSize: number) {
  return await axios.get(ApiRoutes.Patient.GetPatientsList,
      {params: {applyFilter, accountType, upId, status, firstName, lastName, email, phoneNumber, clientName,
          birthDate, mrn, studyId, clientCountry, clientType, clients, permissions, orderDesc, orderByHeader, page, pageSize},
          paramsSerializer: { indexes: null }});
}

export interface EpisodeResponse {
  eocId: number;
  status: string;
  surgeryDate: Date;
  surgeryDateLocalized: string;
  createdDate: Date;
  createdDateLocalized: string;
  ineffectiveDate: Date;
  ineffectiveDateLocalized: string;
  surgeonNameLocalized: string;
  protocolNameLocalized: string;
  protocolEventLevelNameLocalized: string;
  lateralityAbbreviationLocalized: string;
  procedureLocation: string;
  hasZBImplant: boolean;
  isROSAPatient: boolean;
  isPIQPatient: boolean;
  isPIQProcedure: boolean;
  isROSAProcedure: boolean;
  isROSASurgeon: boolean;
  isPIQSurgeon: boolean;
  isClinicalStudyPatient: boolean;
  procedureNameLocalized: string;
  careTeam: CareTeam;
}

export interface Client {
  id: number;
  clientName: string;
  rosaEnabled: boolean;
  piqEnabled: boolean;
}

export interface CareTeam {
  careTeamId: number | undefined;
  careTeamName: string;
  members: CareTeamMember[];
}

export interface CareTeamMember {
  id: number;
  title: string;
  nameLocalized: string;
  phoneCountryCode: string;
  phone: string;
  email: string;
  isAdmin: boolean;
  isSurgeon: boolean;
  isRosa: boolean;
  isPiq: boolean;
  lastLogin: DateDisplay;
}

export interface DateDisplay {
  rawDateTime: Date;
  formattedDateTime: string;
}

export interface CanaryDetail {
  id: number | undefined;
  guid: string;
  accountStatus: string;
  canaryHomeBaseStationDetail: CanaryHomeBaseStationDetail;
  canaryImplantDetails: CanaryImplantDetail[];
}

export interface CanaryHomeBaseStationDetail {
  status: string;
  serialNumber: string;
  definedBedTime: string;
  effectiveTimeZone: string;
  uploadTime: string;
}

export interface CanaryImplantDetail {
  procedureLaterality: string;
  procedureNameLocalized: string;
  isPIQProcedure: boolean;
  isStagedBilateral: boolean;
  isSameDayStagedBilateral: boolean;
  surgeryDate: string;
  surgeryId: number | undefined;
  link: string;
  serialNumber: string;
  lastDateTransmitted: string;
}

export async function getPatientDetail(patientId: number): Promise<AxiosResponse<ApiResponse<GetPatientSupportDetailsResponse>>>  {
  return await axios.get(ApiRoutes.Patient.GetPatientDetail, { params: { patientId } });
}

export interface UpdatePatientRequest {
  patientId: number | undefined;
  email: string | undefined;
  phone: string | undefined;
  phoneCountryCode: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  birthday: Date | undefined;
  salesforceCaseId: string | undefined;
  notes: string | undefined;
}

export interface UpdatePatientResponse {
  success: boolean;
  errorMessage: string;
}

export async function updatePatientProfile(data: UpdatePatientRequest): Promise<AxiosResponse<ApiResponse<UpdatePatientResponse>>> {
  return await axios.post(ApiRoutes.Patient.UpdatePatientProfile, data);
}


export interface UpdatePatientProcedureRequest {
  patientId: number | undefined;
  eocId: number | undefined;
  rosaEnabled: boolean | undefined;
  piqEnabled: boolean | undefined;
  salesforceCaseId: string | undefined;
  notes: string | undefined;
}

export interface UpdatePatientProcedureResponse {
  success: boolean;
  errorMessage: string;
}

export async function updatePatientProcedure(data: UpdatePatientProcedureRequest): Promise<AxiosResponse<ApiResponse<UpdatePatientProcedureResponse>>> {
  return await axios.post(ApiRoutes.Patient.UpdatePatientProcedure, data);
}

