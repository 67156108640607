<script setup lang="ts">
  import { computed, nextTick, onActivated, onDeactivated, ref, onMounted, type Ref } from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { handleApiRequest } from "@/api/handleApiRequest";
  import { getUserChangeLog, type GetUserChangeLogResponse } from '@/api/endpoints/userWeb';
  import { i18n } from '@/plugins/i18n';
  import { useQuasar } from 'quasar';


  const $q = useQuasar()
  const router = useRouter()
  const $route = useRoute();
  const userProfileId = ref(0),
    pageErrorMessage = ref(''),
    loaded = ref(false),
    changeLog: Ref<GetUserChangeLogResponse | undefined> = ref();

  const changeLogTableColumns = ref([
    { name: 'changedDate', field: 'changedDate', sortable: true, align: 'left', sortOrder: 'da' },
    { name: 'modifiedByName', field: 'modifiedByName', sortable: true, align: 'left' },
    { name: 'update', field: 'update', sortable: false, align: 'left' },
    { name: 'notes', field: 'notes', sortable: false, align: 'left' },
    { name: 'salesforceId', field: 'salesforceId', sortable: true, align: 'left' }
  ]);

  onActivated(async () => {
    userProfileId.value = $route.params?.userProfileId != null ? parseInt($route.params.userProfileId + "") : 0;

    $q.loading.show();

    if (userProfileId?.value && userProfileId.value > 0) {
      const changeLogResponse = await handleApiRequest<GetUserChangeLogResponse>(() => getUserChangeLog(userProfileId.value));

      console.log(changeLogResponse);
      if (changeLogResponse?.data == null || changeLogResponse?.data?.errorMessage) {
        pageErrorMessage.value = changeLogResponse?.data?.errorMessage ?? i18n.global.t('error_unexpected_occured').toString();
      } else {
        console.log(changeLogResponse.data);
        const changeLogResult = changeLogResponse.data.result as GetUserChangeLogResponse;
        console.log('changeLogResult');
        console.log(changeLogResult);
        changeLog.value = changeLogResult;
      }

    } else {
      pageErrorMessage.value = i18n.global.t('error_invalid_user_id').toString();
    }

    loaded.value = true;
    $q.loading.hide();
  });

  onDeactivated(async () => {
    loaded.value = false;
  });


  const BackToUserDetail = () => {
    const userTypePage = changeLog.value?.accountType == 'Patient' ? 'Patient' : 'Clinician';
    router.push({
      name: userTypePage,
      params: { 'patientId': userProfileId.value,
        'clinicianProfileId': userProfileId.value }
    });
  }

  const formatDisplayedDate = (dateStr: string) => {
    if (!dateStr) return null;
    var d = new Date(dateStr);
    return d.toLocaleString([], { dateStyle: 'short' })+' '+d.toLocaleString([], { timeStyle: 'short' });
  }

  const getLocalizerKey = (name: string) => {
    switch (name) {

      case 'first_name':
        return 'gen_first_name_initialCap';
      case 'last_name':
        return 'gen_last_name_initialCap';
      case 'phone':
        return 'phone';
      case 'phone_country_code':
        return 'country_code';
      case 'birthday':
        return 'gen_date_of_birth_initialCap';
      case 'rosa_capable':
        return 'gen_rosa_uppercase';
      case 'has_persona_iq':
        return 'piq_stats_header';
      default:
        return 'gen_label_' + name;
    }
  }


</script>

<template>
  <q-page id="changeLogPage" padding>

    <div id="pageError" v-if="pageErrorMessage">
      <div class="errorMessage">{{pageErrorMessage}}</div>
    </div>

    <div v-if="loaded && !pageErrorMessage">

      <!-- Header -->
      <div id="changeLogHeader" class="row items-left">
        <div class="col-auto">
          <zbm-btn-icon @click="BackToUserDetail" class="q-mr-lg" color="primary-color" icon="far fa-angle-left" size="38px" />
        </div>

        <!-- Title -->
        <div class="col">
          <h5 id="changeLogHeaderPrefix">{{$t('change_log_title_prefix')}}</h5>
          <h1 id="changeLogHeaderFirstLastName">
            {{changeLog?.firstName}} {{changeLog?.lastName}}
          </h1>
        </div>
      </div>

      <!-- Change Log Results Table -->
      <div id="changeLogResults">
        <div id="noResultsMsg" v-if="!changeLog?.changes?.length">
          {{$t('gen_no_results_found')}}
        </div>

        <zbm-table :columns="changeLogTableColumns" :rows="changeLog.changes" row-key="id"
                   changeLogTableColumns v-if="changeLog.changes?.length>0"
                   expandable :hide-pagination="true" :loading="false">
          <template v-slot:header="props">
            <q-tr :props="props">
              <q-th key="changedDate" :props="props" class="text-left">{{$t('gen_date')}}</q-th>
              <q-th key="modifiedByName" :props="props" class="text-left">{{$t('gen_updated_by')}}</q-th>
              <q-th key="update" :props="props" class="text-left">{{$t('update')}}</q-th>
              <q-th key="notes" :props="props" class="text-left">{{$t('gen_notes_initialCap')}}</q-th>
              <q-th key="salesforceId" :props="props" class="text-left">{{$t('salesforce_case_id_short')}}</q-th>
            </q-tr>
          </template>
          <template v-slot:body="props">
            <q-tr :props="props" :key="`row-${props.row.id}`" >
              <q-td key="changedDate" :props="props" no-hover>
                {{ formatDisplayedDate(props.row.changedDate) }}
              </q-td>
              <q-td key="modifiedByName" :props="props" no-hover>
                {{ props.row.modifiedByName }}
              </q-td>
              <q-td key="update" :props="props" no-hover>
                {{ props.row.typeHeader }}
                <div v-for="prop in props.row.properties">
                  {{  $t(getLocalizerKey(prop.name)) }}: {{prop.oldValue}} &rarr; {{prop.newValue}}
                </div>
              </q-td>
              <q-td key="notes" :props="props" no-hover>
                {{props.row.notes }}
              </q-td>
              <q-td key="salesforceId" :props="props" no-hover>
                {{props.row.salesforceId }}
              </q-td>
            </q-tr>
          </template>
        </zbm-table>
      </div>

    </div>

  </q-page>
</template>



<style lang="scss">

  @import "@/css/styles-quasar";

  #changeLogPage {

    #pageError {
      text-align: center;
      font-weight: bold;
      padding: $space-double;

      .errorMessage {
        margin-bottom: $space-double;
        font-size: 18px;
      }
    }

    #changeLogHeader {
      margin-bottom: $space-double;

      #changeLogHeaderPrefix {
        color: $primary-color;
        margin-bottom: 0;
      }
    }


    #changeLogResults {
      @extend .panel;
      padding: $space-half;

      #noResultsMsg {
        padding: $space-half;
      }

      .q-table__container {
        border-radius: $generic-border-radius-sm;
      }
    }
  }
</style>
