<script lang="ts" setup>
import {
  type PropType
} from 'vue';
import {
  BoxPaddingEnum,
  BoxTypeEnum
} from '@/constants/enums';

const props = defineProps({
  /*
   * Controls the padding of the box. Options are:
   * none - no set padding; .box will default to existing or inherited padding styles .
   * p15 - sets the padding to 15px on all sides.
   * p20 - sets the padding to 20px on all sides.
   * p30 - sets the padding to 30px on all sides.
   * p60 - sets the padding to 60px on all sides.
   * p2025 - sets the padding of the vertical sides to 20px and the horizontal sides to 25px.
   * p4030 - sets the padding of the vertical sides to 40px and the horizontal sides to 30px.
   * p303040 - sets the top-padding to 30px, the horizontal sides to 30px and the bottom-padding to 40px.
   */
  boxPadding: {
    type: String as PropType<BoxPaddingEnum>,
    default: BoxPaddingEnum.p303040
  },
  boxType: {
    type: String as PropType<BoxTypeEnum>,
    default: BoxTypeEnum.white
  }
});
</script>

<template>
  <div :class="[props.boxType, props.boxPadding]">
    <slot />
  </div>
</template>

<style scoped lang="scss">
  @import "@/css/functions";

  // Blue
  .blue-box {
    background-color: $btn-dtealtxt-hoverfocus-bkgd-clr;
    border-radius: makeRem($generic-border-radius-lg);
  }
  
  
  // White
  .white-box {
    background-color: $white;
    border-radius: makeRem($generic-border-radius);
  
    &-border {
      border: 1px solid $border-clr;
    }
  }
</style>
