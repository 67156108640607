<script setup lang="ts">
import {onMounted, type PropType, type Ref, ref} from "vue";
import {useRoute} from "vue-router";
import {type GetClientFeature} from "@/api/endpoints/clientWeb";
import {useQuasar} from "quasar";

const props = defineProps({
  clientFeatures: {
    type: Object as PropType<GetClientFeature>
  }
});

const clientId=ref(0),
    expanded = ref([ 'Features' ]),   
    simple = ref([]),
    $route = useRoute(),
    $q=useQuasar()
    
onMounted(async () => {
  simple.value = props.clientFeatures
});

</script>

<template>
  <div class="q-pa-md q-gutter-sm">
    <q-tree
        :nodes="simple"
        node-key="label"
        v-model:expanded="expanded"
    >
      <template v-slot:default-header="prop">
        <div class="row items-center">
          <q-icon :name="prop.node.icon" size="15px" width="20px"/>
          <div class="text-primary">{{ prop.node.label }}</div>
        </div>
      </template>

      <template v-slot:default-body="prop">
        <div v-if="prop.node.body">
          <div class="text-weight-regular q-ml-lg">{{ prop.node.body }}</div>
        </div>
      </template>
    </q-tree>
  </div>
</template>

<style scoped lang="scss">

</style>