<template>
  <div class="q-pa-md canaryDetails">
    <!-- Canary Account -->
    <q-card class="no-border canaryAccountCard">
      <q-list>
        <q-item>
          <q-item-section class="canaryAccountTitle"><h5>{{ $t('piq_canary_account') }}</h5></q-item-section>
        </q-item>

        <q-item>
          <q-item-section>{{ $t('piq_canary_account_status') }}</q-item-section>
          <q-item-section>
            {{ props.canaryDetail.accountStatus }}
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>{{ $t('piq_roster_id') }}</q-item-section>
          <q-item-section>
            {{ props.canaryDetail.id }}
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>{{ $t('piq_canary_guid') }}</q-item-section>
          <q-item-section>
            {{ props.canaryDetail.guid }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>

    <!-- Home Base Station -->
    <q-card class="no-border canaryHomeBaseStationCard">
      <q-list>
        <q-item>
          <q-item-section class="canaryHomeBaseStationTitle"><h5>{{ $t('piq_canary_home_base_station') }}</h5></q-item-section>
        </q-item>

        <q-item>
          <q-item-section>{{ $t('piq_canary_setup_status') }}</q-item-section>
          <q-item-section>
            {{ props.canaryDetail.canaryHomeBaseStationDetail?.status }}
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>{{ $t('piq_canary_serial_number') }}</q-item-section>
          <q-item-section>
            {{ props.canaryDetail.canaryHomeBaseStationDetail?.serialNumber }}
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>{{ $t('piq_canary_patient_defined_bedtime') }}</q-item-section>
          <q-item-section>
            {{ props.canaryDetail.canaryHomeBaseStationDetail?.definedBedTime }}
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>{{ $t('piq_canary_patient_effective_time_zone_for_canary') }}</q-item-section>
          <q-item-section>
            {{ props.canaryDetail.canaryHomeBaseStationDetail?.effectiveTimeZone }}
          </q-item-section>
        </q-item>

        <q-item>
          <q-item-section>{{ $t('piq_canary_patient_hbs_upload_time') }}</q-item-section>
          <q-item-section>
            {{ props.canaryDetail.canaryHomeBaseStationDetail?.uploadTime }}
          </q-item-section>
        </q-item>
      </q-list>
    </q-card>
  </div>

  <!-- Implants -->
  <div class="q-pa-md row items-start q-gutter-md"
       v-if="props.canaryDetail.canaryImplantDetails != null && props.canaryDetail.canaryImplantDetails.length > 0">
    <q-card v-for="canaryImplantDetail in props.canaryDetail.canaryImplantDetails"
            class="canaryImplantDetailsCard"
            v-bind:style="canaryImplantDetail.isPIQProcedure == true && (canaryImplantDetail.isSameDayStagedBilateral == true || canaryImplantDetail.isStagedBilateral == true) ? props.selectedProcedureLaterality.length > 0 && canaryImplantDetail.procedureLaterality != props.selectedProcedureLaterality ? 'background-color: #F4F6F6; opacity:0.6; border: 0px; cursor: default; pointer-events: none !important;' : '' : 'background-color: #F4F6F6; opacity:0.6; border: 0px; cursor: default; pointer-events: none !important;'">
      <q-card-section>
        <q-list>
          <q-item>
            <q-item-section :no-wrap="true">
              <div class="procedureLaterality">
                {{ $t(`${ canaryImplantDetail.procedureLaterality }`) }}
              </div>
              <div class="procedureDetails">
                {{ $t('gen_via') }} {{ canaryImplantDetail.procedureNameLocalized }} {{ $t('gen_on') }} {{ canaryImplantDetail.surgeryDate }}
              </div>
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>{{ $t('canary_surgery_id') }}</q-item-section>
            <q-item-section>
              {{ canaryImplantDetail.surgeryId }}
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>{{ $t('canary_cte_link_to_canary_account_status') }}</q-item-section>
            <q-item-section>
              {{ canaryImplantDetail.link }}
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>{{ $t('canary_cte_serial_number') }}</q-item-section>
            <q-item-section>
              {{ canaryImplantDetail.serialNumber }}
            </q-item-section>
          </q-item>

          <q-item>
            <q-item-section>{{ $t('canary_last_date_data_received') }}</q-item-section>
            <q-item-section>
              {{ canaryImplantDetail.lastDateTransmitted }}
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>
  </div>
</template>

<script setup lang="ts">
  import { type CanaryDetail } from "@/api/endpoints/patientWeb";
  import { defineProps } from 'vue';

  const props = defineProps<{
    canaryDetail: CanaryDetail,
    selectedProcedureLaterality: string
  }>()
</script>

<style lang="scss">

  @import "@/css/styles-quasar";

  .canaryDetails {
    display:inline-flex;
    width: 100%;
  }

  .canaryAccountCard,
  .canaryHomeBaseStationCard {
    width: 50%;
  }

  .canaryImplantDetailsCard {
    width: 48%;
  }

  .canaryAccountTitle,
  .canaryHomeBaseStationTitle {
    font-size: 15pt;
    font-weight: bold;
    padding-bottom: 10px;
  }

  .procedureLaterality {
    font-weight: bold;
    padding-bottom: 5px;
  }

  .procedureDetails {
    font-size: 9pt;
  }
</style>
