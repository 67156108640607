<script setup lang="ts">
import {
  defineModel,
} from "vue";

const props = defineProps({
  disable: {
    type: Boolean,
    default: false
  },
  falseValue: {},
  indeterminateValue: {},
  leftLabel: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
  },
  name: {
    type: String
  },
  trueValue: {},
  val: {}
});

const syncValue = defineModel();
</script>

<template>
  <q-checkbox v-model="syncValue"
              checked-icon="fas fa-check"
              color="white"
              :disable="props.disable"
              :false-value="props.falseValue"
              indeterminate-icon="fas fa-minus"
              :indeterminate-value="props.indeterminateValue"
              :left-label="props.leftLabel"
              :label="props.label"
              :name="props.name"
              :true-value="props.trueValue"
              unchecked-icon=" "
              :val="props.val" />
</template>

<style scoped lang="scss"></style>