import axios from '@/api/axiosConfig'
import { ApiRoutes } from '../ApiRoutes'
import type { ApiResponse } from '@/types/apiTypes';
import type { AxiosResponse } from 'axios';


export interface GetConfigResponse {
  region: string;
  sessionPixelUrl: string;
  loginUrl: string;
  postLogoutUrl: string;
  useZBEdgeLogin: boolean;
}


export async function getConfig(): Promise<AxiosResponse<ApiResponse<GetConfigResponse>>> {
  return await axios.get(ApiRoutes.Config.GetConfig);
}
