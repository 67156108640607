<script lang="ts" setup>
import {
  computed,
  type PropType
} from "vue";
import {
  ButtonHeightEnum,
  ButtonStdColorEnum,
  ButtonTypeEnum
} from "@/constants/enums";

const props = defineProps({
  /* Controls the color scheme of the Button. Options are:
   * "accent" - sets the `background-color` to the "accent" (our magenta) and `color` to white.
   * "primaryColor" - sets the `background-color` to "primary-color" and `color` to white.
   */
  color: {
    type: String as PropType<ButtonStdColorEnum>,
    required: true
  },
  disable: {
    type: Boolean
  },
  /* Controls the height of the Button: Options are:
   * "sm" - sets the `height` to 25px with `padding-inline` to 12px.
   * "md" - sets the `height` to 30px with `padding-inline` to 20px.
   * "lg" - sets the `height` to 40px with `padding-inline` to 40px.
   */
  height: {
    type: String as PropType<ButtonHeightEnum>,
    required: true
  },
  href: {
    type: String
  },
  icon: {
    type: String
  },
  iconRight: {
    type: String
  },
  label: {
    type: String,
    required: true
  },
  replace: {
    type: Boolean
  },
  rounded: {
    type: Boolean,
    default: false
  },
  target: {
    type: String
  },
  to: {
    type: String as PropType<string | object>
  },
  /* Controls the Button `type`. Button `type` gets nullified if "href" or "to" prop is used. Options are:
   * "button"
   * "reset"
   * "submit"
   */
  type: {
    type: String as PropType<ButtonTypeEnum>,
    default: ButtonTypeEnum.button
  }
});

const height = computed(() => {
  return `q-btn--${props.height}`;
});

const textColor = computed(() => {
  if (props.color === ButtonStdColorEnum.darkTealText) {
    return 'primary-color';
  }

  return '';
});
</script>

<template>
  <q-btn :class="[
           height,
           {'text-weight-regular' : props.color === ButtonStdColorEnum.darkTealText}
         ]"
         :color="props.color"
         :disable="props.disable"
         :href="props.href"
         :icon="props.icon"
         :icon-right="props.iconRight"
         :label="props.label"
         no-caps
         :replace="props.replace"
         :ripple="false"
         :rounded="props.rounded"
         :target="props.target"
         :text-color="textColor"
         :to="props.to"
         :type="props.type"
         unelevated>
    <slot />
  </q-btn>
</template>

<style lang="scss" scoped>
  @import "@/css/functions";

  .q-btn {
    line-height: makeRem(20px);
    border-width: 1px;
    border-style: solid;
    // Color
    // Accent
    &.bg-accent { // .q-btn
      border-color: $accent;

      &:hover,
      &:focus {
        background: $btn-accent-hoverfocus-bkgd-clr !important;
        border-color: $btn-accent-hoverfocus-bkgd-clr !important;
      }

      &.disabled,
      &:disabled,
      &[disabled] {
        background: $btn-accent-disabled-bkgd-clr !important;
        border-color: $btn-accent-disabled-bkgd-clr !important;
      }
    }

    &.bg-primary-color { // .q-btn
      border-color: $primary-color;

      &:hover,
      &:focus {
        background: $btn-dteal-hoverfocus-bkgd-clr;
      }

      &.disabled,
      &:disabled,
      &[disabled] {
        background: $btn-dteal-disabled-bkgd-clr;
        border-color: $btn-dteal-disabled-bkgd-clr;
      }

      &.text-white {

        &.disabled,
        &:disabled,
        &[disabled] {
          color: $btn-dteal-disabled-txt-clr !important;
        }
      }
    }
    // Dark Teal Text
    &.bg-primary-color-txt { // .q-btn
      background: $white;
      border-color: $primary-color;

      &:hover,
      &:focus {
        background: $btn-dtealtxt-hoverfocus-bkgd-clr;
      }

      &.disabled,
      &:disabled,
      &[disabled] {
        border-color: rgba($primary-color, 0.3);
      }

      &:active,
      &.active {
        background: $primary-color;
        border-color: $primary-color;
      }

      &.text-primary-color {

        &.disabled,
        &:disabled,
        &[disabled] {
          color: rgba($primary-color, 0.3) !important;
        }

        &:active,
        &.active {
          color: $white !important;
        }
      }
    }
  }
</style>
