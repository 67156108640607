<script setup lang="ts">
import {getClients, getClientsWithFilter} from "@/api/endpoints/clientWeb";
import {computed, nextTick, ref, watch} from "vue";
import { useQuasar } from 'quasar';
import {useRouter} from 'vue-router';
import {useFilterStore} from "@/stores/filterStore";
import {storeToRefs} from "pinia";
import {i18n} from "@/plugins/i18n";
import type {DropdownItem} from "@/components/Dropdown.vue";

const $q = useQuasar(), router = useRouter();

const filterStore = useFilterStore();
const { clearClientFilterCount } = storeToRefs(filterStore)

const clientData = ref();
const clientTypeOptions = ref()
const countryOptions = ref()
const showResults = ref(false);
const getClientData = async () => {
  const clients = await getClients()
  clientData.value = clients.data.types;
  clientTypeOptions.value = clients.data.types.map((clientType: string) => {
    return {
      label: i18n.global.t(clientType),
      value: clientType
    }
  });
  countryOptions.value = clients.data.countries.map((country: string) => {
    return {
      label: country,
      value: country
    }
  });
}

( async () => await getClientData())();

watch(clearClientFilterCount, async() => await onCancel())

const clientName = ref('');
const clientTypeModel = ref({ label: i18n.global.t('gen_client_type'), value: 'default'} as DropdownItem);
const countryModel = ref({ label: i18n.global.t('gen_client_country'), value: 'default'} as DropdownItem);


// table data
const clientTable = ref()
const clientTableColumns = ref([
  { name: 'clientName', field: 'clientName', sortable: true, align: 'left', sortOrder: 'ad' },
  { name: 'clientType', field: 'clientType', sortable: true, align: 'left' },
  { name: 'clientCountry', field: 'clientCountry', sortable: true, align: 'left' }
])
const clientTableRows = ref([])
const loading = ref(false)
const filter = ref('')
const form = ref()

nextTick(() => form.value.resetValidation())

const onSubmit = async () => {
  const isValid = await form.value.validate()
  if (isValid) {
    $q.loading.show();
    filterStore.clientFilterCount = getFilterCount()

    const clientType = clientTypeModel.value.value === 'default' ? null : clientTypeModel.value.value;
    const country = countryModel.value.value === 'default' ? null : countryModel.value.value;

    initialPagination.value.page = 1;
    const filteredClientData = await getClientsWithFilter(true, clientName.value,
      clientType, country, initialPagination.value.descending, initialPagination.value.sortBy,
      initialPagination.value.page, initialPagination.value.rowsPerPage)

    filteredClientData.data.rows.forEach((row) => {
      row.clientType = i18n.global.t(row.clientType)
    })

    clientTableRows.value = filteredClientData.data.rows
    initialPagination.value.rowsNumber = filteredClientData.data.totalRows
    showResults.value = true;
    $q.loading.hide();
  }
}

function getFilterCount() {
  var count = 0;
  if(clientName.value !== '') count++
  if(countryModel.value.value !== 'default') count++
  if(clientTypeModel.value.value !== 'default') count++

  return count
}

const onCancel = () => {
  clientTypeModel.value = { label: i18n.global.t('gen_client_type'), value: 'default' } as DropdownItem
  countryModel.value = { label: i18n.global.t('gen_client_country'), value: 'default' } as DropdownItem
  clientName.value = ''
  showResults.value = false;

  initialPagination.value.sortBy = 'asc'
  initialPagination.value.descending = false

  nextTick(() => form.value.resetValidation())
  clientTableRows.value = []
  filterStore.clientFilterCount = 0
}

const onRowClick = (evt) => {
  router.push({
    name: 'Client',
    params: {
      clientId: evt.toString()
    }
  });

}

const initialPagination = ref({
  sortBy: 'asc',
  descending: false,
  page: 1,
  rowsPerPage: 15,
  rowsNumber: 5
})


async function onRequest(props) {
  const { page, rowsPerPage, sortBy, descending } = props.pagination

  loading.value = true
  $q.loading.show();

  const clientType = clientTypeModel.value.value === 'default' ? null : clientTypeModel.value.value;
  const country = countryModel.value.value === 'default' ? null : countryModel.value.value;

  const filteredClientData = await getClientsWithFilter(true, clientName.value,
    clientType, country, descending, sortBy, page, rowsPerPage, null)

  filteredClientData.data.rows.forEach((row) => {
    row.clientType = i18n.global.t(row.clientType)
  })

  clientTableRows.value = filteredClientData.data.rows

  initialPagination.value.page = page
  initialPagination.value.sortBy = sortBy
  initialPagination.value.descending = descending
  initialPagination.value.rowsNumber = filteredClientData.data.totalRows

  loading.value = false
  $q.loading.hide();

}
</script>

<template>
  <q-page padding>

    <div class="q-pb-xl">
      <h1>{{ $t('gen_clients') }}</h1>
    </div>

    <div class="row">
      <div class="col bg-white q-pa-xl rep-profile__form">
        <q-form @submit.prevent ref="form">
          <zbm-skeleton-loader height="22.5714285714rem" width="100%">

            <div class="row mb-20 rep-profile__fields">

              <div class="col q-pa-sm">
                <q-input outlined v-model="clientName" :placeholder="$t('gen_client_name')" />
              </div>
              <div class="col q-pa-sm">
                <q-select outlined v-model="clientTypeModel" :options="clientTypeOptions" :placeholder="$t('gen_client_type')" />
              </div>
              <div class="col q-pa-sm">
                <q-select outlined v-model="countryModel" :options="countryOptions" :placeholder="$t('gen_client_country')" />
              </div>
            </div>

            <!-- Form Buttons -->
            <div class="row items-center q-col-gutter-x-sm rep-profile__buttons">
              <!-- Cancel -->
              <div class="col-auto q-ml-auto">
                <zbm-skeleton-loader height="2.8571428571rem" width="3.1428571429rem">
                  <zbm-btn @click="onCancel" :color="$zb.enums.ButtonStdColorEnum.darkTealText"
                           :height="$zb.enums.ButtonHeightEnum.md"
                           :label="$t('gen_clear_filters')" :data-testid="'btnCancel'" />
                </zbm-skeleton-loader>
              </div>

              <!-- Save -->
              <div class="col-auto">
                <zbm-skeleton-loader height="2.8571428571rem" width="11.5714285714rem">
                  <zbm-btn @click="onSubmit" :color="$zb.enums.ButtonStdColorEnum.darkTeal"
                           :height="$zb.enums.ButtonHeightEnum.md" :label="$t('client_search_go_button')"
                           :type="$zb.enums.ButtonTypeEnum.submit" :data-testid="'btnSave'" />
                </zbm-skeleton-loader>
              </div>
            </div>
          </zbm-skeleton-loader>

        </q-form>
      </div>
    </div>
    
    
    <div v-if="showResults">
      <zbm-table :columns="clientTableColumns" :rows="clientTableRows" row-key="clientId"
                 expandable :hide-pagination="false" ref="clientTable"
                 v-model:pagination="initialPagination"
                 :loading="loading"
                 @request="onRequest">
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th key="clientName" :props="props" class="text-left">{{ $t('gen_client_name') }}</q-th>
            <q-th key="clientType" :props="props" class="text-left">{{ $t('gen_client_type') }}</q-th>
            <q-th key="clientCountry" :props="props" class="text-left">{{ $t('gen_client_country') }}</q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" :key="`row-${props.row.clientId}`" class="cursor-pointer"
                @click="onRowClick(props.row.clientId)">
            <q-td key="clientName" :props="props" no-hover>
              {{ props.row.clientName }}
            </q-td>

            <q-td key="clientType" :props="props" no-hover>
              {{ props.row.clientType }}
            </q-td>

            <q-td key="clientCountry" :props="props" no-hover>
              {{props.row.clientCountry }}
            </q-td>

          </q-tr>
        </template>

      </zbm-table>
    </div>
  </q-page>
</template>
