import { ref } from 'vue'
import type { Ref } from 'vue'
import { defineStore } from 'pinia'
import type { UserSession } from "@/api/models/supportUser";
import { AccountType } from "@/constants/enums";

export const useSessionStore = defineStore('session', () => {
  const user: Ref<UserSession | null> = ref(null);
  const isAuthenticated = ref(false);
  const isSuperUser = ref(false);
  const isSupportAgent = ref(false);
  const canManageAgents = ref(false);
  const ssoSignal = ref(0);
  const isHeaderVisible = ref(false);
  const utcOffset = ref(Math.floor(new Date().getTimezoneOffset() / -60));

  function setSession(userData: UserSession) {
    isAuthenticated.value = true;
    isSuperUser.value = userData?.userType == AccountType.SuperUser.toString();
    isSupportAgent.value = userData?.userType == AccountType.CustomerSupport.toString();
    canManageAgents.value = isSuperUser.value || userData?.permissions?.includes('CustomerSupportManager')==true;
    user.value = userData;
  }

  function clear() {
    isAuthenticated.value = false;
    user.value = null;
  }

  function setSSOSignal() {
    ssoSignal.value += 1;
  }

  const setIsHeaderVisible = (isVisible : boolean) => {
    isHeaderVisible.value = isVisible;
  }

  return {
    user,
    isAuthenticated,
    isSuperUser,
    isSupportAgent,
    canManageAgents,
    setSession,
    clear,
    setSSOSignal,
    ssoSignal,
    setIsHeaderVisible,
    utcOffset
  }
})
