import axios from '@/api/axiosConfig'
import {ApiRoutes} from '../ApiRoutes'
import type {Client, EpisodeResponse, RecentMFAResponse} from "@/api/endpoints/patientWeb";
import type {AxiosResponse} from "axios";
import type {ApiResponse} from "@/types/apiTypes";
import type {GetClinicianSupportDetailsResponse} from "@/api/endpoints/clinicianWeb";

export async function getClients() {
  return await axios.get(ApiRoutes.Client.GetClients);
}

export async function getClientsWithFilter(applyFilter: string, clientName: string , clientType: string,
                                           clientCountry: string, orderDesc: boolean, orderByHeader: string,
                                           page: number, pageSize: number, lastKey: Date) {
  return await axios.get(ApiRoutes.Client.GetClients,
      {params: {applyFilter, clientName, clientType, clientCountry, orderDesc, orderByHeader, page, pageSize, lastKey}});
}

export interface GetClientSupportDetailsResponse {
  clientId: number;
  name: string;
  country: string;
  languages: string;
  clientType: string;
  clientFeatures: GetClientFeature;
  clientFeaturesExpanded: string[];
}

export interface GetClientFeature {
  label: string;
  icon: string;
  children: GetClientFeature[]
}


export async function getClientDetail(clientId: number): Promise<AxiosResponse<ApiResponse<GetClientSupportDetailsResponse>>> {
  return await axios.get(ApiRoutes.Client.GetClientDetail, { params: { clientId }});
}
