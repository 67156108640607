import axios from '@/api/axiosConfig'
import {ApiRoutes} from '../ApiRoutes'
import type {AccountType} from "@/constants/enums";
import type {SupportAgentProfile} from "@/api/models/supportUser";
import type {ApiResponse} from "@/types/apiTypes";
import type { AxiosResponse } from 'axios';

export interface UpsertProfileResponse {
  userId: number;
}

export async function getSupportAgents() {
  return await axios.get(ApiRoutes.SupportAgent.GetSupportAgentsList, {params: {applyFilter: 'false', accountType: "CustomerSupport"}});
}

export async function getSupportAgentsWithFilter(applyFilter: string, accountType: AccountType, upId: string, status: string,
                                            firstName: string, lastName: string, email: string, phoneNumber: string,
                                            clientName: string, birthDate: string, mrn: string, studyId: string,
                                            clientCountry: string, clientType: string, clients: Array<string>, permissions: Array<string>,
                                            orderDesc: boolean, orderByHeader: string, page: number, pageSize: number) {
  return await axios.get(ApiRoutes.SupportAgent.GetSupportAgentsList,
      {params: {applyFilter, accountType, upId, status, firstName, lastName, email, phoneNumber, clientName,
          birthDate, mrn, studyId, clientCountry, clientType, clients, permissions, orderDesc, orderByHeader, page, pageSize},
          paramsSerializer: { indexes: null }});
}

export async function getSupportAgentProfile(userId : number) : Promise<AxiosResponse<ApiResponse<SupportAgentProfile>>> {
    return await axios.get(ApiRoutes.SupportAgent.GetSupportAgent, {params: { userId }});
}

export async function getNewSupportAgentProfile(userType : AccountType) : Promise<AxiosResponse<ApiResponse<SupportAgentProfile>>> {
  return await axios.get(ApiRoutes.SupportAgent.GetNewSupportAgent, {params: {accountType: userType}});
}

export async function postSupportAgentProfile(model : SupportAgentProfile) : Promise<AxiosResponse<ApiResponse<UpsertProfileResponse>>> {
  return await axios.post(ApiRoutes.SupportAgent.UpsertSupportAgent, model);
}
