import { AxiosError } from 'axios';
import type {AxiosResponse, AxiosInstance } from 'axios';
import axios,{ isAxiosError } from './axiosConfig'
import type {ApiResponse} from "@/api/models/general";

interface WorkCallback<TResult>{
(axios:AxiosInstance):Promise<AxiosResponse<ApiResponse<TResult>>>;
}
interface WorkCallbackShallow<TResult>{
():Promise<AxiosResponse<TResult>>;
}

interface FailureCallback{
  (response:Error|AxiosError):void;
}

export { isAxiosError } from 'axios'

export async function handleApiRequest<TResult>(work: WorkCallback<TResult>, fail?: FailureCallback, suppressErrorHandling = false): Promise<AxiosResponse<ApiResponse<TResult> | undefined>> {

  try{
    const response = await work(axios)

    return response;
  } catch(error) {
    if (fail && isAxiosError(error)) {
      fail(error)
    } else if (!suppressErrorHandling && isAxiosError(error)) {
      if (error.response) {

        if (error?.status === 400) {

        }
      }
    } else if (!suppressErrorHandling && error?.status == 401 && !window.location?.href?.toLowerCase()?.includes('login') && !window.location?.href?.toLowerCase()?.includes('logout')) {
      window.location.href = '/Logout';
    }

    return error as AxiosResponse<ApiResponse<TResult>>;
  }
}
