<script lang="ts" setup>
import {
  computed,
  defineModel,
  type PropType
} from 'vue';
import { type ValidationRule } from "quasar";

const props = defineProps({
  disable: {
    type: Boolean,
    default: false,
  },
  for: {
    type: String
  },
  inline: {
    type: Boolean,
    default: false
  },
  label: {
    type: String
  },
  name: {
    type: String
  },
  optionLabel: {
    type: String,
    default: 'label'
  },
  options: {
    type: Array,
    required: true,
    default: () => []
  },
  optionValue: {
    type: String,
    default: 'value'
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
});

const syncValue = defineModel();

const optionsComputed = computed(() => {
  const options = props.options.map((o) => ({ label: o[props.optionLabel], value: o[props.optionValue] }))
  return options
})
</script>

<template>
  
  <!-- Label -->
  <label v-if="props.label"
         :for="props.for">
    {{ props.label }}<slot name="label-append" />
  </label>
  
  <!-- Field -->
  <q-field v-model="syncValue"
           :disable="props.disable"
           hide-bottom-space
           no-error-icon
           :rules="props.rules">
    
    <!-- Control Slot -->
    <template #control>
      <q-option-group v-model="syncValue"
                      color="primary-color"
                      :inline="props.inline"
                      :name="props.name"
                      :options="optionsComputed" />
    </template>
  </q-field>
</template>

<style scoped lang="scss">
  @import "@/css/functions";

  // QField Native
  .q-field {

    // Native
    :deep(.q-field__native) {
      padding-block: 0;
      padding-inline: 0;
    }

    // Auto Height
    &--auto-height { // .q-field

      // Labeled
      &.q-field--labeled :deep(.q-field__native) {
        padding-block-start: 0;
      }
    }
    
    // Error
    &--error { // .q-field
    
      // Native
      :deep(.q-field__native) {
        background-color: initial;
        
        // Inner (QRadio)
        .q-radio__inner {
          background-color: $notify-yellow;
        }
      }
    }
  }
</style>
