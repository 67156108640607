<script lang="ts" setup>
import {
  defineExpose,
  type PropType,
  ref,
  computed
} from 'vue';
import { FormElWidthEnum } from '@/constants/enums';
import type { ValidationRule } from 'quasar';
import { i18n } from '@/plugins/i18n';

export interface DropdownItem {
  value: number | string | undefined | null;
  label: string;
  path?: string | undefined;
  disabled?: boolean;
  params: Record<string, string>;
}

const props = defineProps({
  modelValue: {
    type: Object
  },
  disable: {
    type: Boolean,
    default: false
  },
  omitDefaultOption: {
    type: Boolean,
    default: false
  },
  defaultOptionText: {
    type: String,
    default: i18n.global.t('drop_down_default')
  },
  optionDisable: {
    type: String
  },
  optionLabel: {
    type: String,
    default: 'label'
  },
  options: {
    type: Array as PropType<DropdownItem[]>,
    default: () => []
  },
  optionValue: {
    type: String,
    default: 'value'
  },
  readonly: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl125 - sets width to `125px`.
   * qFormEl300 - sets width to `300px`.
   */
  selectWidth: {
    type: String,
    default: FormElWidthEnum.qFormElAuto,
  }
})

const emit = defineEmits(['update:modelValue'])
const qfield = ref();
const syncValue = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

defineExpose({
  validate: () => qfield.value.validate()
});

</script>

<template>
  <!-- Field -->
  <q-field v-model="syncValue" :class="props.selectWidth" :disable="props.disable" hide-bottom-space no-error-icon
           outlined :readonly="props.readonly" ref="qfield" :rules="props.rules" tag="div">

    <!-- Control Slot -->
    <template #control>
      <select v-model="syncValue" :class="props.selectWidth" :disabled="disable">

        <!-- Default Option -->
        <option v-if="!props.omitDefaultOption" :label="defaultOptionText" :value="undefined" />

        <!-- Options -->
        <option v-for="(opt, i) in props.options" :disabled="opt.disabled" :key="i" :value="opt">{{
            opt.label }}</option>
      </select>
    </template>
  </q-field>
</template>

<style scoped lang="scss">
@use "sass:string";

@function angleDownSvg($color) {
  $adj-color: string.slice(#{$color}, 2);

  @return url('data:image/svg+xml;charset=utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z" fill="%23#{$adj-color}"/></svg>')
}

// QField
.q-field {
  $select-bkgd-size-w: 9px;

  // Native
  :deep(.q-field__native) {
    padding: 0;
    border: none;
  }

  // Select
  select {
    -webkit-appearance: none;
    appearance: none;
    background-color: $white;
    background-image: angleDownSvg($default-text-color);
    background-position: right center;
    background-size: $select-bkgd-size-w auto;
    background-repeat: no-repeat;
    background-clip: padding-box;
    line-height: $body-line-height;
    color: $default-text-color;
    padding-block: $field-padding-block $field-padding-block-outlined;
    padding-inline: 0 ($field-padding-inline + $select-bkgd-size-w);
    border: none;
    border-block-end: $generic-border;

    font-size: 14px;

    &:focus {
      outline: none;
    }
  }

  // Readonly
  &--readonly {
    // .q-field

    // Select
    select {
      pointer-events: none;
      background-image: angleDownSvg($body-txt-clr);
      color: $body-txt-clr;
    }

    // Outlined
    &.q-field--outlined {
      // .q-field

      // Select
      select {
        border-color: transparent;
      }
    }
  }

  // Disabled
  &--disabled {
    // .q-field

    // Outlined
    &.q-field--outlined {
      // .q-field

      // Select
      select {
        border-color: rgba($body-txt-clr, 0.5);
      }
    }
  }

  // Outlined
  &--outlined {
    // .q-field

    // Native
    :deep(.q-field__native) {
      border: none;
    }

    // Select
    select {
      $select-bkgd-pos-offset-x: 12px;

      background-position: right $select-bkgd-pos-offset-x center;
      padding-block-start: $field-padding-block-outlined;
      padding-inline: $field-padding-inline (
        $field-padding-inline + $select-bkgd-size-w + $select-bkgd-pos-offset-x
      );
      border: 1px solid $field-brdr-clr;
      border-radius: $generic-border-radius-sm;
    }
  }

  // Error
  &--error {
    // .q-field

    select {
      background-color: $notify-yellow;
    }
  }

  // Auto Height
  &--auto-height {
    // .q-field

    // Outlined
    &.q-field--outlined {

      // Native
      :deep(.q-field__native) {
        padding: 0;
      }
    }
  }

  // Dark
  &--dark {
    // .q-field

    // Native & Input
    :deep(.q-field__native) {

      select {
        background-color: transparent;
        background-image: angleDownSvg($white);
        color: $white;
        border-block-end-color: $white;
      }
    }

    // Error
    &.q-field--error {

      :deep(.q-field__bottom) {
        color: $warning;
      }
    }
  }
}
</style>
