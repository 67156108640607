import { ref } from 'vue'
import { defineStore } from 'pinia'

export const useConfigStore = defineStore('config', () => {
  const configIsLoaded = ref(false);
  const sessionPixelUrl = ref('');
  const region = ref('');
  const loginUrl = ref('');
  const postLogoutUrl = ref('');
  const useZBEdgeLogin = ref(false);

  const setConfig = (settings) => {
    sessionPixelUrl.value = settings.sessionPixelUrl;
    region.value = settings.region;
    loginUrl.value = settings.loginUrl;
    postLogoutUrl.value = settings.postLogoutUrl;
    useZBEdgeLogin.value = settings.useZBEdgeLogin;
  }

  return {
    configIsLoaded,
    sessionPixelUrl,
    region,
    setConfig,
    loginUrl,
    postLogoutUrl,
    useZBEdgeLogin
  }
})
