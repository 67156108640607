<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import {
  computed,
  watch,
  ref,
  defineModel,
  type PropType
} from 'vue';
import { FormElWidthEnum } from '@/constants/enums';
import { type ValidationRule } from "quasar";
import moment from 'momentCultured';
import {biListOl} from "@quasar/extras/bootstrap-icons";

const emit = defineEmits(['update:model-value']);

const props = defineProps({
  disable: {
    type: Boolean,
    default: false,
  },
  for: {
    type: String
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl150 - sets width to `150px`.
   * qFormEl200 - sets width to `200px`.
   * qFormEl250 - sets width to `250px`.
   * qFormEl310 - sets width to `310px`.
   */
  inputWidth: {
    type: String,
    default: FormElWidthEnum.qFormEl150
  },
  label: {
    type: String
  },
  minDate: {
    type: String,
    required: true
  },
  maxDate: {
    type: String,
    required: true
  },
  readonly: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  placeholder: {
    type: String
  },
  showIcon: {
    type: Boolean
  }
});

const datepicker = ref()
const syncSelectedDate = defineModel();

const regionalMask = computed(() => {

  if(props.placeholder != undefined)
  {
    return props.placeholder
  }
  
  return moment.localeData()._longDateFormat['L'].toLowerCase()
      .replace(/m/gi, '0')
      .replace(/d/gi, '0')
      .replace(/y/gi, '0');
});

const regionalMaskPattern = computed(() => {
  return moment.localeData()._longDateFormat['L'];
});

const eventWrapper = (handler, event) => {
  handler(event)
}
</script>

<template>

  <!-- Label -->
  <label v-if="props.label" :for="props.for">
    {{ props.label }}
    <slot name="label-append" />
  </label>

  <!-- Field -->
  <q-field v-model="syncSelectedDate" :class="[props.inputWidth]" :disable="props.disable" hide-bottom-space
    no-error-icon outlined :readonly="props.readonly" :rules="props.rules" tag="div" >
    
    <template v-slot:prepend>
      <q-icon v-if="showIcon" name="fas fa-calendar" />
    </template>
    
    <!-- Control Slot -->
    <template #control>
      <VDatePicker v-model="syncSelectedDate" ref="datepicker" :attributes="[{
    key: 'today',
    bar: {
      color: 'blue',
      fillMode: 'solid'
    },
    dates: new Date()
  }]" clearable color="teal" locale="en-US" :masks="{ input: regionalMaskPattern }" :max-date="maxDate"
        :min-date="minDate" mode="date" :popover="{
    placement: 'bottom',
    visibility: 'click'
  }" :updateOnInput="true">
        <template #default="{ inputValue, inputEvents }">
          <input  @change.native="(e) => eventWrapper(inputEvents.change, e)"
            @click.native="(e) => eventWrapper(inputEvents.click, e)"
            @input.native="(e) => eventWrapper(inputEvents.input, e)"
            @keyup.native="(e) => eventWrapper(inputEvents.keyup, e)" autocomplete="off" :name="props.for"
            :placeholder="regionalMask" type="text" :value="inputValue" />
        </template>
      </VDatePicker>
    </template>
  </q-field>
</template>

<style scoped lang="scss">
@import "@/css/functions";

// QField
  .q-field {
    // Native
    :deep(.q-field__native) {
      padding: 0;
    }
    // Date Picker Input
    input {
      width: 100%;
      background-color: $white;
      line-height: makeRem($field-line-height);
      color: $primary-color;
      padding-block: makeRem($field-padding-block);
      padding-inline: makeRem($field-padding-inline);
      border: none;

      &:focus {
        outline: none;
      }
      // Instead of `.q-placeholder` which seems to effect non-placeholder text.
      &::placeholder {
        color: $field-brdr-clr;
        opacity: 1;
      }
    }
    // Readonly
    &--readonly {
      // .q-field
      // Date Picker Input
      input {
        pointer-events: none;
        color: $body-txt-clr;
      }
      // Outlined
      &.q-field--outlined {
        // .q-field
        // Date Picker Input
        input {
          border-color: transparent;
        }
      }
    }
    // Disabled
    &--disabled {
      // .q-field
      // Inner
      :deep(.q-field__inner) {
        cursor: not-allowed;
      }
      // Outlined
      &.q-field--outlined {
        // .q-field
        // Date Picker Input
        input {
          border-color: rgba($field-brdr-clr, 0.3);
        }
      }
    }
    // Outlined
    &--outlined {
      // .q-field
      // Native
      :deep(.q-field__native) {
        border: none;
      }
      // Date Picker Input
      input {
        padding-block: makeRem($field-padding-block-outlined);
        border: 1px solid $field-brdr-clr;
        border-radius: makeRem($generic-border-radius-sm);
      }
    }
    // Error
    &--error {
      // .q-field
      // Date Picker Input
      input {
        background-color: $notify-yellow;
      }
    }
    // Auto Height
    &--auto-height {
      // .q-field
      // Outlined
      &.q-field--outlined {
        // Native
        :deep(.q-field__native) {
          padding: 0;
        }
      }
    }
  }

:deep(button) {
  background-color: transparent;
}

:deep(.vc-container) {
  width: auto;
  height: auto;
}
</style>
