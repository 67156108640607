import { debounce } from 'quasar';
import { handleApiRequest } from '@/api/handleApiRequest';

const config = {
  inactiveTimeout: (25 * 60 * 1000) // 25 mins
};

async function invokeLogout() {
  // hit the server to logout

  //TODO CALL SESSION TIMEOUT ENDPOINT
}

const idleLogoutTimer = debounce(invokeLogout, config.inactiveTimeout);// throttles events.

export function setupInactivityRedirect() {
  ['mousemove', 'scroll', 'keypress', 'mousedown', 'click', 'touchstart'].forEach((event) => {
    document.addEventListener(event, idleLogoutTimer);
  });
}

function teardownInactivityRedirect() {
  idleLogoutTimer.cancel();
  ['mousemove', 'scroll', 'keypress', 'mousedown', 'click', 'touchstart'].forEach((event) => {
    document.removeEventListener(event, idleLogoutTimer);
  });
}

export function handleLogout() {
  teardownInactivityRedirect()
}
