<script setup lang="ts">
import {computed, nextTick, ref, watch} from "vue";
import {AccountType} from "@/constants/enums";
import { useQuasar } from 'quasar';
import {useRoute, useRouter} from 'vue-router';
import {getClinicians, getCliniciansWithFilter} from "@/api/endpoints/clinicianWeb";
import {useFilterStore} from "@/stores/filterStore";
import {storeToRefs} from "pinia";
import type {VueTelInputData} from "@/types/phoneTelNumberInputTypes";
import {i18n} from "@/plugins/i18n";
import type {DropdownItem} from "@/components/Dropdown.vue";

const $q = useQuasar(), router = useRouter(), route = useRoute()

const filterStore = useFilterStore();
const { clearClinicianFilterCount } = storeToRefs(filterStore)

const clientTypeOptions = ref()
const countryOptions = ref()
const permissionOptions = ref()
const getClinicianFilterData = async () => {
  const clinicians = await getClinicians()
  countryOptions.value = clinicians.data.countries.map((country: string) => {
    return {
      label: country,
      value: country
    }
  });
  clientTypeOptions.value = clinicians.data.types.map((clientType: string) => {
    return {
      label: i18n.global.t(clientType),
      value: clientType
    }
  });
  permissionOptions.value = clinicians.data.permissions.map((permission: string) => {
    return {
      label: getPermissionLabel(permission),
      value: permission
    }
  })
}

const getPermissionLabel = (permission: string) => {
  if (permission == 'Admin')
    return i18n.global.t('gen_admin_initialCap');
  else if (permission == 'Surgeon')
    return i18n.global.t('gen_surgeon_initialCap');
  else if (permission == 'Piq')
    return i18n.global.t('gen_piq_uppercase');
  else if (permission == 'Rosa')
    return i18n.global.t('gen_rosa_uppercase');
  else return permission;
}

( async () => await getClinicianFilterData())();

watch(clearClinicianFilterCount, () => onCancel())

// table data
const clinicianTable = ref()
const clinicianTableColumns = ref([
  { name: 'firstName', field: 'firstName', sortable: true, align: 'left' },
  { name: 'lastName', field: 'lastName', sortable: true, align: 'left' },
  { name: 'client', field: 'client', sortable: true, align: 'left' },
  { name: 'country', field: 'country', sortable: true, align: 'left' },
  { name: 'upId', field: 'upId', sortable: true, align: 'left', sortOrder: 'ad' },
  { name: 'email', field: 'email', sortable: true, align: 'left' },
  { name: 'phoneNumber', field: 'phoneNumber', sortable: true, align: 'left' },
  { name: 'permissions', field: 'permissions', sortable: true, align: 'left' },
])
const clinicianTableRows = ref([])

const loading = ref(false)
const filter = ref('')
const showResults = ref(false);

const upIdModel = ref('');
const firstNameModel = ref('');
const lastNameModel = ref('');
const emailModel = ref('');
const clientNameModel = ref('');
const countryModel = ref({ label: i18n.global.t('gen_country'), value: 'default'} as DropdownItem);
const clientTypeModel = ref({ label: i18n.global.t('gen_client_type'), value: 'default'} as DropdownItem);
const clientsModel = ref('');
const permissionsModel = ref([] as DropdownItem);
                         //ref([{ label: i18n.global.t('gen_permissions'), value: 'default' }] as DropdownItem);


const form = ref()

const phoneTelNumberInputModel = ref({
  value: '',
  phoneObject: {} as VueTelInputData
});

nextTick(() => form.value.resetValidation())

const onSubmit = async () => {
  const isValid = await form.value.validate()
  if (isValid) {
    $q.loading.show();
    filterStore.clinicianFilterCount = getFilterCount()

    const permissions = permissionsModel.value.map((permission) => permission.value);
    const clientType = clientTypeModel.value.value !== 'default' ? clientTypeModel.value.value : null;
    const country = countryModel.value.value !== 'default' ? countryModel.value.value : null;

    initialPagination.value.page = 1;
    const filteredClinicianData = await getCliniciansWithFilter('true', AccountType.Clinician, upIdModel.value,
        null, firstNameModel.value, lastNameModel.value,
        emailModel.value, phoneTelNumberInputModel.value?.phoneObject?.nationalNumber, clientNameModel.value, null, null, null,
        country, clientType, clientsModel.value, permissions,
        initialPagination.value.descending, initialPagination.value.sortBy, initialPagination.value.page, initialPagination.value.rowsPerPage)

    filteredClinicianData.data.rows.forEach((row) => {
      row.permissions = row.permissions.map((permission: string) => {
        return getPermissionLabel(permission)
      })
    });
    clinicianTableRows.value = filteredClinicianData.data.rows
    initialPagination.value.rowsNumber = filteredClinicianData.data.totalRows
    showResults.value = true;
    $q.loading.hide();
  }
}

function getFilterCount() {
  var count = 0;
  if(upIdModel.value !== '') count++
  if(firstNameModel.value !== '') count++
  if(lastNameModel.value !== '') count++
  if(emailModel.value !== '') count++
  if(phoneTelNumberInputModel.value.value !== '') count++
  if(clientNameModel.value !== '') count++
  if(countryModel.value.value !== 'default') count++
  if(clientTypeModel.value.value !== 'default') count++
  if(clientsModel.value !== '') count++
  if (permissionsModel.value.length > 1) count++

  return count
}

const onCancel = () => {
  upIdModel.value = ''
  firstNameModel.value = ''
  lastNameModel.value = ''
  emailModel.value = ''
  clientNameModel.value = ''
  countryModel.value = { label: i18n.global.t('gen_country'), value: 'default' } as DropdownItem
  clientTypeModel.value = { label: i18n.global.t('gen_client_type'), value: 'default' } as DropdownItem
  clientsModel.value = ''
  permissionsModel.value = [ ] as DropdownItem[]
                           //[{ label: i18n.global.t('gen_permissions') }] as DropdownItem[]
  showResults.value = false;

  phoneTelNumberInputModel.value.value = ''
  phoneTelNumberInputModel.value.phoneObject = null

  initialPagination.value.sortBy = 'asc'
  initialPagination.value.descending = false

  nextTick(() => form.value.resetValidation())

  clinicianTableRows.value = []
  filterStore.clinicianFilterCount = 0
}

const onRowClick = (evt: number) => {
  router.push({
    name: 'Clinician',
    params: {
      clinicianProfileId: evt.toString()
    }
  });
}

const initialPagination = ref({
  sortBy: 'asc',
  descending: false,
  page: 1,
  rowsPerPage: 15,
  rowsNumber: 5
})

async function onRequest(props: any) {
  const { page, rowsPerPage, sortBy, descending } = props.pagination

  loading.value = true
  $q.loading.show();

  const permissions = permissionsModel.value.map((permission) => permission.value);
  const clientType = clientTypeModel.value.value !== 'default' ? clientTypeModel.value.value : null;
  const country = countryModel.value.value !== 'default' ? countryModel.value.value : null;

  const filteredClinicianData = await getCliniciansWithFilter('true', AccountType.Clinician, upIdModel.value,
      null, firstNameModel.value, lastNameModel.value,
      emailModel.value, phoneTelNumberInputModel.value?.phoneObject?.nationalNumber, clientNameModel.value, null, null, null,
    country, clientType, clientsModel.value, permissions,
      descending, sortBy, page, rowsPerPage)

  filteredClinicianData.data.rows.forEach((row) => {
    row.permissions = row.permissions.map((permission: string) => {
      return getPermissionLabel(permission)
    })
  })
  clinicianTableRows.value = filteredClinicianData.data.rows

  initialPagination.value.page = page
  initialPagination.value.sortBy = sortBy
  initialPagination.value.descending = descending
  initialPagination.value.rowsNumber = filteredClinicianData.data.totalRows

  loading.value = false
  $q.loading.hide();
}


const permissionsSelected = computed(() => {
  return permissionsModel.value != null && permissionsModel.value.length > 0;
})

</script>

<template>
  <q-page padding>

    <div class="q-pb-xl">
      <h1>{{ $t('gen_clinicians') }}</h1>
    </div>

    <div class="row">
      <div class="col bg-white q-pa-xl rep-profile__form">
        <q-form @submit.prevent ref="form">
          <zbm-skeleton-loader height="22.5714285714rem" width="100%">

            <div class="row mb-20 rep-profile__fields">

              <div class="col q-pa-sm">
                <q-input outlined v-model="upIdModel" :placeholder="$t('gen_upid')" />
              </div>
              <div class="col q-pa-sm">
                <q-select outlined v-model="countryModel" :options="countryOptions" :placeholder="$t('gen_status')" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="clientNameModel" :placeholder="$t('gen_client_name')" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="emailModel" :placeholder="$t('gen_email')" />
              </div>
              <div class="col q-pa-sm">
                <q-select outlined v-model="permissionsModel" :options="permissionOptions" :placeholder="permissionsSelected ? undefined : $t('gen_permissions')" 
                          :use-input="!permissionsSelected"
                          multiple
                          use-chips
                />
              </div>
            </div>
            <div class="row mb-20 rep-profile__fields">
              <div class="col q-pa-sm">
                <q-input outlined v-model="firstNameModel" :placeholder="$t('gen_first_name_initialCap')" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="lastNameModel" :placeholder="$t('gen_last_name_initialCap')" />
              </div>
              <div class="col q-pa-sm">
                <zbm-telephone-with-validation v-model="phoneTelNumberInputModel"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull" countryCode="US"
                                               :placeholder="$t('gen_mobile_number_initialCap')" />
              </div>
              <div class="col q-pa-sm">
                <q-select outlined v-model="clientTypeModel" :options="clientTypeOptions" :placeholder="$t('gen_client_type')" />
              </div>

            </div>
            <!-- Form Buttons -->
            <div class="row items-center q-col-gutter-x-sm rep-profile__buttons">
              <!-- Cancel -->
              <div class="col-auto q-ml-auto">
                <zbm-skeleton-loader height="2.8571428571rem" width="3.1428571429rem">
                  <zbm-btn @click="onCancel" :color="$zb.enums.ButtonStdColorEnum.darkTealText"
                           :height="$zb.enums.ButtonHeightEnum.md"
                           :label="$t('gen_clear_filters')" :data-testid="'btnCancel'" />
                </zbm-skeleton-loader>
              </div>

              <!-- Save -->
              <div class="col-auto">
                <zbm-skeleton-loader height="2.8571428571rem" width="11.5714285714rem">
                  <zbm-btn @click="onSubmit" :color="$zb.enums.ButtonStdColorEnum.darkTeal"
                           :height="$zb.enums.ButtonHeightEnum.md" :label="$t('clinician_search_go_button')"
                           :type="$zb.enums.ButtonTypeEnum.submit" :data-testid="'btnSave'" />
                </zbm-skeleton-loader>
              </div>
            </div>
          </zbm-skeleton-loader>

        </q-form>
      </div>
    </div>

    <div v-if="showResults">
      <zbm-table :columns="clinicianTableColumns" :rows="clinicianTableRows" row-key="upId"
                 expandable :hide-pagination="false" ref="clinicianTable"
                 v-model:pagination="initialPagination"
                 :loading="loading"
                 @request="onRequest">
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th key="firstName" :props="props" class="text-left">{{ $t('gen_first_name') }}</q-th>
            <q-th key="lastName" :props="props" class="text-left">{{ $t('gen_last_name') }}</q-th>
            <q-th key="client" :props="props" class="text-left">{{ $t('gen_client') }}</q-th>
            <q-th key="country" :props="props" class="text-left">{{ $t('gen_country') }}</q-th>
            <q-th key="upId" :props="props" class="text-left">{{ $t('gen_upid') }}</q-th>
            <q-th key="email" :props="props" class="text-left">{{ $t('gen_email') }}</q-th>
            <q-th key="phoneNumber" :props="props" class="text-left">{{ $t('gen_phone') }}</q-th>
            <q-th key="permissions" :props="props" class="text-left">{{ $t('gen_permissions') }}</q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" :key="`row-${props.row.upId}`" class="cursor-pointer"
                @click="onRowClick(props.row.upId)">
            <q-td key="firstName" :props="props" no-hover>
              {{props.row.firstName }}
            </q-td>

            <q-td key="lastName" :props="props" no-hover>
              {{props.row.lastName }}
            </q-td>

            <q-td key="client" :props="props" no-hover>
              {{props.row.client }}
            </q-td>

            <q-td key="country" :props="props" no-hover>
              {{props.row.country }}
            </q-td>

            <q-td key="upId" :props="props" no-hover>
              {{ props.row.upId }}
            </q-td>

            <q-td key="email" :props="props" no-hover>
              {{props.row.email }}
            </q-td>

            <q-td key="phoneNumber" :props="props" no-hover>
              {{props.row.phoneNumber }}
            </q-td>

            <q-td key="permissions" :props="props" no-hover>
              <template v-for="(element, index) in props.row.permissions">
                <q-chip outline square color="grey" text-color="grey">
                  {{ element }}
                </q-chip>
              </template>
            </q-td>

          </q-tr>
        </template>

      </zbm-table>
    </div>
  </q-page>
</template>
