<script lang="ts" setup>
import {type PropType} from "vue";
import {
  ButtonLnkColorEnum,
  ButtonTypeEnum
} from "@/constants/enums";

const props = defineProps({
  alignWText: {
    type: Boolean,
    default: false
  },
  /* Controls the color scheme of the Button. Options are:
   * "default" - sets the color scheme to match the default anchor styling.
   * "body" - sets the `color` to the default body color.
   * "bodyAlt" - sets the `color` to the default body color and the `:hover` `color` to the "other" body color.
   */
  color: {
    type: String as PropType<ButtonLnkColorEnum>
  },
  disable: {
    type: Boolean
  },
  href: {
    type: String
  },
  icon: {
    type: String
  },
  iconRight: {
    type: String
  },
  label: {
    type: String,
    required: true
  },
  replace: {
    type: Boolean
  },
  square: {
    type: Boolean,
    default: false
  },
  target: {
    type: String
  },
  to: {
    type: String as PropType<string | object>
  },
  /* Controls the Button `type`. Button `type` gets nullified if "href" or "to" prop is used. Options are:
   * "button"
   * "reset"
   * "submit"
   */
  type: {
    type: String as PropType<ButtonTypeEnum>,
    default: ButtonTypeEnum.button
  }
});
</script>

<template>
  <q-btn :class="[
           'q-btn-link',
           {'q-btn-link--text' : props.alignWText}
         ]"
         :disable="props.disable"
         :href="props.href"
         :icon="props.icon"
         :icon-right="props.iconRight"
         :label="props.label"
         no-caps
         :replace="props.replace"
         :ripple="false"
         :square="props.square"
         :target="props.target"
         :text-color="props.color"
         :to="props.to"
         :type="props.type"
         unelevated />
</template>

<style lang="scss" scoped>
  @import "@/css/functions";

  .q-btn-link { // .q-btn
    font-weight: map-get($text-weights, regular);
    color: $primary-color;
    text-decoration: underline;
    padding: 0;

    :deep(.q-icon) {
      line-height: inherit;
    }

    &:hover,
    &:focus {
      color: $a-hover-clr;
    }

    &:disabled {
      color: $a-disabled-clr;
    }
    // Text
    // Make the `<button>` text align baseline with a line of text.
    &--text { // .q-btn, .q-btn-link
      vertical-align: baseline;
    }
    // Themes
    // Body (color)
    &.text-body { // .q-btn, .q-btn-link
      color: $body-txt-clr;
    }
    // Body Alt (color)
    &.text-body-alt { // .q-btn, .q-btn-link
      color: $body-txt-clr;

      &:hover,
      &:focus {
        color: $body-txt-other-clr;
      }

      &:disabled {
        color: $hr-brdr-clr;
      }
    }
  }
</style>
