import axios from '@/api/axiosConfig'
import { ApiRoutes } from '../ApiRoutes'
import { getCookieValue } from '@/utils/cookies'
import type { ApiResponse } from '@/types/apiTypes';
import type { AxiosResponse } from 'axios';
import type {AccountType} from "@/constants/enums";


export interface GetUserChangeLogResponse {
  firstName: string;
  lastName: string;
  accountType: string;
  changes: ChangeLogChange[];
}

export interface ChangeLogChange {
  id: number;
  modifiedByName: string;
  salesforceId: string;
  notes: string;
  typeHeader: string;
  changedDate: Date;
  properties: ChangeLogProperty[];
}

export interface ChangeLogProperty {
  name: string;
  oldValue: string;
  newValue: string;
}

export async function getUserChangeLog(userProfileId: number): Promise<AxiosResponse<ApiResponse<GetUserChangeLogResponse>>> {
  return await axios.get(ApiRoutes.User.GetUserChangeLog, { params: { userProfileId } });
}

export async function getAntiForgeryToken() {
  const requestTokenResult = await axios.get(ApiRoutes.User.GetAntiForgeryToken)
    .then(response => {
      const requestToken = getCookieValue('XSRF-REQUEST-TOKEN');
      return requestToken;
    });

  return requestTokenResult;
}


