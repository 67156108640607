import axios from '@/api/axiosConfig'
import {ApiRoutes} from '../ApiRoutes'

export interface LocalLoginModel {
  username: string;
  password: string;
  utcOffset: number;
}

export async function postLocalLogin(model : LocalLoginModel){
  return await axios.post(ApiRoutes.Login.PostLocalLogin, model);
}

export async function getSession() {
  return await axios.get(ApiRoutes.Login.GetSession);
}

export async function Logout() {
  return await axios.post(ApiRoutes.Login.Logout);
}
