<template>
  <q-page padding id="clinicianDetailPage">
    <div v-if="loaded && clinician">
      <div id="clinicianDetailHeader">
        <h1>
          <q-icon name="far fa-solid fa-chevron-left" class="cursor-pointer" @click="ToCliniciansList" />
          {{fullName}}
        </h1>
        <span>
          {{ $t('local_time_label_prefix') }} <b>{{clinicianLocalTime}}</b>
        </span>
      </div>

      <div id="clinicianAccountSection">

        <div id="clinicianAccount" v-bind:class="{ editMode: editAccountMode }">
          <div class="panelActionButtonWrap">
            <q-icon name="far fa-solid fa-pencil" class="editIcon" @click="toggleEditProfile" />
            <q-icon name="far fa-solid fa-xmark" class="cancelIcon" @click="toggleEditProfile" />
          </div>
          <h5>{{$t('clinician_details_title')}}</h5>

          <q-form @submit.prevent ref="formClinician">
            <div class="row">
              <div class="column">
                <dl>
                  <dt>{{$t('gen_first_name_initialCap')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{dashesIfMissing(clinician?.firstName)}}</span>
                    <zbm-input-with-validation v-else v-model="pendingProfile.firstName"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                               :rules="[$zb.validators.required(),$zb.validators.maxLen(100),$zb.validators.minLen(1)]" />

                  </dd>
                  <dt>{{$t('gen_last_name_initialCap')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{dashesIfMissing(clinician?.lastName)}}</span>
                    <zbm-input-with-validation v-else v-model="pendingProfile.lastName"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                               :rules="[$zb.validators.required(),$zb.validators.maxLen(100),$zb.validators.minLen(1)]" />
                  </dd>
                  <dt>{{$t('my_profile_unique_identifier')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{dashesIfMissing(clinician?.npi)}}</span>
                    <zbm-input-with-validation v-else v-model="pendingProfile.npi"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull" />
                  </dd>
                </dl>
              </div>
              <div class="column">
                <dl>
                  <dt>{{$t('phone')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode">{{dashesIfMissing(formatPhoneNumber( clinician?.phoneCountryCode, clinician?.phone ))}}</span>
                    <zbm-telephone-with-validation v-model="phoneTelNumberInputModel" v-else
                                                   :countryCode="pendingProfile?.phoneCountryCode"
                                                   placeholder="(###) ###-####"
                                                   :rules="[$zb.validators.required()]" />

                  </dd>
                  <dt>{{$t('gen_label_email')}}</dt>
                  <dd>
                    <span v-if="!editAccountMode && canEditEmail">{{dashesIfMissing(clinician?.email)}}</span>
                    <zbm-input-with-validation v-else v-model="pendingProfile.email"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                               :rules="[$zb.validators.required(),$zb.validators.maxLen(100),$zb.validators.minLen(1), $zb.validators.email()]" />
                  </dd>
                </dl>
              </div>
            </div>

            <div id="editAccountControls" v-if="editAccountMode">
              <dl>
                <dt>{{$t('gen_salesforce_case_id')}}</dt>
                <dd>
                  <zbm-input-with-validation v-model="pendingProfile.salesforceCaseId"
                                             :input-width="$zb.enums.FormElWidthEnum.qFormElFull"
                                             :rules="[$zb.validators.required(),$zb.validators.maxLen(50),$zb.validators.minLen(1)]" />
                </dd>
                <dt>{{$t('gen_additional_notes')}}</dt>
                <dd><q-input outlined placeholder="" type="textarea" v-model="pendingProfile.notes" /></dd>
              </dl>

              <div class="column items-end">
                <div class="col q-gutter-sm">
                  <BtnStandard :color="ButtonStdColorEnum.primaryColorText"
                               :height="ButtonHeightEnum.md"
                               :to="{ name: 'cancelChanges', params: {  } }"
                               :label="$t('gen_cancel')"
                               @click="toggleEditProfile" />

                  <BtnStandard :color="ButtonStdColorEnum.primaryColor"
                               :height="ButtonHeightEnum.md"
                               :to="{ name: 'saveChanges', params: {  } }"
                               :label="$t('gen_save_changes')"
                               :disabled="!isClinicianFormDirty"
                               @click="saveClinicianProfile" />
                </div>
              </div>
            </div>

          </q-form>

        </div>

        <div id="clinicianAccount2FAWrap">
          <div id="clinicianAccount2FA">
            <h5>{{$t('latest_mfa_title')}}</h5>

            <div id="clinicianAccount2FAError" v-if="clinician?.recent2FAErrorMsg">
              {{ clinician?.recent2FAErrorMsg }}
            </div>

            <div id="noVerificationsMsg" v-if="clinician?.recent2FAAttempts && clinician?.recent2FAAttempts.length==0 && !clinician?.recent2FAErrorMsg">
              {{$t('gen_no_results_found')}}
            </div>

            <ul v-if="clinician?.recent2FAAttempts && clinician?.recent2FAAttempts.length>0">
              <li v-for="mfa in clinician.recent2FAAttempts">
                <span>
                  <q-icon v-if="mfa.status=='Verified'" name="far fa-solid fa-circle-check" title="{{$t('gen_verified')}}" color="success-icon-color" />
                  <q-icon v-if="mfa.status=='Pending'" name="far fa-solid fa-clock" title="{{$t('gen_pending')}}" />
                  <q-icon v-if="mfa.status=='Expired' || mfa.status==='Invalid'" name="far fa-solid fa-xmark" title="{{$t('gen_expired')}}" color="error-icon-color" />
                </span>
                <span>{{formatRecentDayTime(mfa.sentTime)}}</span>
                <span>{{mfa.trailingPinCode}}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="clientDataSelectorWrap" class="q-mb-lg">
        <h3 class="q-mb-lg text-primary-color">{{$t('clinician_client_memberships_title')}}</h3>
        <Dropdown v-model="selectedClient" :options="clientOptions" omitDefaultOption :select-width="FormElWidthEnum.qFormElFull" />
      </div>

      <div id="clinicianClientMembershipDetailsSection">
        <div id="clientMembershipDetails">
          <h5 class="q-mb-md">{{$t('clinician_client_membership_details_title')}}</h5>
            <div class="row">
              <dl class="column">
                <dt>{{$t('gen_title')}}</dt>
                <dd>{{dashesIfMissing(clientInfo?.clinicianTitle)}}</dd>
                <dt>{{$t('gen_admin_initialCap')}}</dt>
                <dd v-if="clientInfo?.clinicianIsAdmin"><q-icon name="fas fa-solid fa-check-square" color="success-icon-color" /></dd>
                <dd v-else><q-icon name="fas fa-do-not-enter" color="error-icon-color" /></dd>
                <dt>{{$t('gen_surgeon_initialCap')}}</dt>
                <dd v-if="clientInfo?.clinicianIsSurgeon"><q-icon name="fas fa-solid fa-check-square" color="success-icon-color" /></dd>
                <dd v-else><q-icon name="fas fa-do-not-enter" color="error-icon-color" /></dd>
                <dt>{{$t('gen_piq_uppercase')}}</dt>
                <dd v-if="clientInfo?.clinicianIsPIQEnabled"><q-icon name="fas fa-solid fa-check-square" color="success-icon-color" /></dd>
                <dd v-else><q-icon name="fas fa-do-not-enter" color="error-icon-color" /></dd>
                <dt>{{$t('gen_rosa_uppercase')}}</dt>
                <dd v-if="clientInfo?.clinicianIsROSAEnabled"><q-icon name="fas fa-solid fa-check-square" color="success-icon-color" /></dd>
                <dd v-else><q-icon name="fas fa-do-not-enter" color="error-icon-color" /></dd>
              </dl>
              <dl class="column">
                <dt>{{ $t('care_teams') }}</dt>
                <dd v-for="team in clientInfo?.careTeamNames">{{team}}</dd>
              </dl>
            </div>
        </div>
      </div>

      <BtnStandard :color="ButtonStdColorEnum.primaryColorText"
                   :height="ButtonHeightEnum.md"
                   :to="{ name: 'ChangeLog', params: { 'userProfileId': clinicianProfileId  } }"
                   :label="$t('gen_view_change_log')"/>

    </div>
  </q-page>
</template>

<script setup lang="ts">
import { ref, computed, onActivated, type Ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useQuasar } from 'quasar';
import BtnStandard from "@/components/BtnStandard.vue"
import {ButtonHeightEnum, ButtonStdColorEnum, FormElWidthEnum} from "@/constants/enums";
import { handleApiRequest } from '@/api/handleApiRequest';
import {
  type ClinicianClientDetailsResponse,
  getClinicianDetail, type GetClinicianSupportDetailsResponse, updateClinicianDetails, type UpdateClinicianRequest,
} from "@/api/endpoints/clinicianWeb";
import { parsePhoneNumber, type CountryCode } from 'libphonenumber-js';
import {i18n} from '@/plugins/i18n';
import type { VueTelInputData } from "@/types/phoneTelNumberInputTypes";
import { getAntiForgeryToken } from "@/api/endpoints/userWeb";
import Dropdown, {type DropdownItem} from "@/components/Dropdown.vue";


const clinicianProfileId = ref(0),
  emptyClinicianObject = {} as UpdateClinicianRequest,
  pendingProfile = ref({ ...emptyClinicianObject }),
  editAccountMode = ref(false),
  loaded = ref(false),
  clinician: Ref<GetClinicianSupportDetailsResponse | undefined> = ref(),
  formClinician = ref(),
  $q = useQuasar(),
  $route = useRoute(),
  router = useRouter(),
  phoneTelNumberInputModel = ref({
    value: '',
    phoneObject: {} as VueTelInputData
  }),
  selectedClient = ref<DropdownItem>();

let initialClinicianFormState = '';

onActivated(async () => {
  clinicianProfileId.value = $route.params?.clinicianProfileId != null ? parseInt($route.params.clinicianProfileId + "") : 0;
  editAccountMode.value = false;
  await loadClinician();
});

const loadClinician = async () => {
  loaded.value = false;
  $q.loading.show();

  if (clinicianProfileId?.value && clinicianProfileId.value > 0) {
    const response = await handleApiRequest<GetClinicianSupportDetailsResponse>(() => getClinicianDetail(clinicianProfileId.value));

    if (response?.data?.result == null || response?.data?.errorMessage) {
        const message = response?.data?.errorMessage || i18n.global.t('error_unexpected_occured').toString();
        $q.notify({
          message: message,
          type: 'warning'
        });
    } else {
      clinician.value = response.data.result as GetClinicianSupportDetailsResponse;

      const client = clinician?.value?.clientDetails[0]
      selectedClient.value = {
        value: client.clientId,
        label: client.clientName,
      } as DropdownItem
    }
  } else {
    $q.notify({
      message: i18n.global.t('error_invalid_user_id').toString(),
      type: 'warning'
    });
  }

  loaded.value = true;
  $q.loading.hide();
}

const fullName = computed(() => {
  let prefix = "";
  let suffix = "";
  if (clinician?.value?.prefix){
    prefix = clinician?.value?.prefix + " ";
  }
  if (clinician?.value?.suffix){
    suffix = ", " + clinician?.value?.suffix;
  }

  return `${prefix}${clinician?.value?.firstName} ${clinician?.value?.lastName}${suffix}`;
})

const clientOptions = computed(() => {
  return clinician?.value?.clientDetails.map((client) => {
    return {
      value: client.clientId,
      label: client.clientName,
    } as DropdownItem
  })})

const clientInfo = computed(() => {
  return clinician?.value?.clientDetails.find((client) => client.clientId === selectedClient.value?.value) as ClinicianClientDetailsResponse;
})

const clinicianLocalTime = computed(() => {
  let d = new Date();
  const clinicianOffset = clinician?.value?.timezoneOffset ?? 0;
  const timezoneOffset = d.getTimezoneOffset() / 60;
  const difference = clinicianOffset + timezoneOffset;
  const localDate = new Date(d.getTime() + difference * 3600000);
  const day = localDate.toLocaleDateString('default', { weekday: 'long' });
  const timeAMPM = localDate.toLocaleTimeString(undefined, { hour12: true, timeStyle: 'short' });
  const time24Hr = localDate.toLocaleTimeString(undefined, { hour12: false, timeStyle: 'short' });
  return `${day}, ${timeAMPM} (${time24Hr})`;
})

const formatRecentDayTime = (dateStr: string) => {
  if (!dateStr) return null;
  var d = new Date(dateStr);
  return d.toLocaleDateString('default', { weekday: 'long' }) +", "+d.toLocaleString('default', { month: 'long' }) + ", " + d.getDate() + " " + d.toLocaleTimeString();
}

const canEditEmail = computed(() => {
  const isFederatedTenant = clinician?.value?.federatedTenant === true;
  const inviteRedeemed = clinician?.value?.inviteStatus === "Redeemed";

  return !isFederatedTenant || !inviteRedeemed;
})

const formatPhoneNumber = (countryCodeNumber: string, phoneNumber: string) => {
  if (!countryCodeNumber || !phoneNumber || phoneNumber?.length < 3)
    return phoneNumber;

  let fullNumber = '+' + countryCodeNumber + phoneNumber;
  let countryCodeStr = (countryCodeNumber == '1' ? 'US' : '');
  let parsedPhoneNumber = parsePhoneNumber(fullNumber, countryCodeStr as CountryCode);

  return parsedPhoneNumber
    ? parsedPhoneNumber.formatInternational()
    : countryCodeNumber +" "+phoneNumber;
}

const dashesIfMissing = (str: string) => {
  return str ? str : '--';
}

const toggleEditProfile = () => {
  formClinician.value.resetValidation();
  editAccountMode.value = !editAccountMode.value;
  const data = {} as UpdateClinicianRequest;
  data.clinicianProfileId = clinician?.value?.clinicianProfileId ?? 0;
  data.firstName = clinician?.value?.firstName;
  data.lastName = clinician?.value?.lastName;
  data.email = clinician?.value?.email;
  data.npi = clinician?.value?.npi;
  data.phone = clinician?.value?.phone;
  data.phoneCountryCode = clinician?.value?.phoneCountryCode;
  data.salesforceCaseId = '';
  data.notes = '';
  if (clinician?.value?.phone != null) {
    phoneTelNumberInputModel.value.value = '+' + clinician?.value?.phoneCountryCode + clinician?.value?.phone;
  } else {
    phoneTelNumberInputModel.value.value = '';
  }
  initialClinicianFormState = JSON.stringify(data);
  pendingProfile.value = data;
}

const isClinicianFormDirty = computed(() => {
  return initialClinicianFormState !== JSON.stringify(pendingProfile.value);
});

const saveClinicianProfile = async () => {

  if (!pendingProfile) return;

  const isValid = await formClinician.value.validate()
  if (!isValid) return;

  $q.loading.show();

  const updateClinicianObj = {
    ...pendingProfile.value,
    phoneCountryCode: phoneTelNumberInputModel.value.phoneObject.countryCallingCode,
    phone: phoneTelNumberInputModel.value.phoneObject.nationalNumber,
  }

  await getAntiForgeryToken();
  const response = await handleApiRequest(() => updateClinicianDetails(updateClinicianObj));

  if (response?.data?.result) {
    $q.notify({
      message: i18n.global.t('edit_profile_changes_saved').toString(),
      type: 'message'
    });

    loaded.value = true;
    editAccountMode.value = false;
    await loadClinician();
  }
  else {
    const message = response?.data?.errorMessage || i18n.global.t('error_unexpected_occured').toString();
    $q.notify({
      message: message,
      type: 'warning'
    });
  }

  $q.loading.hide();
}

const ToCliniciansList = () => {
  router.push({
    name: 'Clinicians',
    params: {}
  });
}

</script>

<style lang="scss">

@import "@/css/styles-quasar";

  #clinicianDetailPage {

    .panelActionButtonWrap {
      float: right;
      text-align: right;
      width: 20px;
      height: 20px;

      .editIcon,
      .cancelIcon {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      .cancelIcon {
        display: none;
      }
    }

    .editMode {
      .panelActionButtonWrap {
        .editIcon {
          display: none;
        }

        .cancelIcon {
          display: block;
        }
      }
    }

    dl {
      display: grid;
      grid-gap: 4px 16px;
      grid-template-columns: auto minmax(0, 1fr);
      align-content: flex-start;
      padding: 0;
      max-width: 100%;

      dt {
        color: $body-dim-txt-clr;
      }

      dd {
        margin: 0;
        grid-column-start: 2;
        overflow-wrap: anywhere;
      }
    }

    .row {
      gap: $space-base;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      max-width: 100%;

      .column {
        flex: 1;
        max-width: 48%;
      }
    }

    #clinicianDetailHeader {
      margin-bottom: $space-double;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: $space-base;

      .fa-chevron-left {
        font-weight: bold;
        margin-right: $space-base;
      }

      span {
        padding-top: 10px;
      }
    }

    #clinicianAccountSection {
      display: flex;
      flex-direction: row;
      gap: $space-base;
      justify-content: space-between;
      margin-bottom: $space-double;

      #clinicianAccount {
        @extend .panel;
        flex: 66%;
        position: relative;

        #editAccountControls {
          border-top: 1px solid $border-clr;
          margin-top: $space-base;
          padding-top: $space-base;
        }
      }

      #clinicianAccount2FAWrap {
        flex: 33%;
        padding: 0;

        #clinicianAccount2FA {
          @extend .panel;
          flex: 33%;
          padding: 0;

          h5 {
            padding: $space-base;
          }

          #clinicianAccount2FAError {
            padding: 0 $space-base $space-base $space-base;
          }

          #noVerificationsMsg {
            color: $body-dim-txt-clr;
            padding: 0 $space-base $space-base $space-base;
          }

          ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
              display: flex;
              border-top: 1px solid $border-clr;
              justify-content: space-between;
              gap: $space-half;
              padding: $space-half $space-base;
              margin: 0;

              span {
                flex: 70%;
                color: $body-txt-clr;
                text-align: left;

                &:first-child {
                  flex: 5%;
                  font-size: 1.1rem;
                  line-height: 1.1rem;
                  text-align: center;
                }

                &:last-child {
                  flex: 25%;
                  font-size: $note-font-size;
                  line-height: $note-line-height;
                  color: $body-dim-txt-clr;
                  text-align: right;
                }
              }
            }
          }
        }
      }

      @media screen and ( max-width:$breakpoint-lg ) {
        flex-direction: column;
      }

    }

    #clinicianClientMembershipDetailsSection {
      @extend .panel;
      margin-bottom: $space-double;

      #clientMembershipDetails {
        position: relative;
      }
    }
  }
</style>
