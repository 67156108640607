<script lang="ts" setup>
import {
  computed,
  type PropType
} from 'vue';
import { FormElWidthEnum } from '@/constants/enums';
import { type ValidationRule } from "quasar";

const props = defineProps({
  disable: {
    type: Boolean,
    default: false,
  },
  for: {
    type: String
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl150 - sets width to `150px`.
   * qFormEl200 - sets width to `200px`.
   * qFormEl250 - sets width to `250px`.
   * qFormEl310 - sets width to `310px`.
   */
  inputWidth: {
    type: String,
    default: FormElWidthEnum.qFormEl250
  },
  label: {
    type: String
  },
  modelValue: {
    type: Object,
    required: true
  },
  placeholder: {
    type: String,
  },
  countryCode: {
    type: String
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  }
})

const emit = defineEmits(['update:modelValue'])

const syncValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value)
  }
})

const onInput = (number, phoneObject) => {
  syncValue.value = {
    value: number,
    phoneObject: { ...phoneObject },
  };
}
</script>

<template>
  
  <!-- Label -->
  <label v-if="props.label"
         :for="props.for">
    {{ props.label }}<slot name="label-append" />
  </label>

  <!-- Field -->
  <q-field v-model="syncValue.phoneObject"
           :class="[props.inputWidth]"
           hide-bottom-space
           no-error-icon
           :rules="[$zb.validators.phoneTelInput()]"
           tag="div">
    <template #control>
      <vue-tel-input v-model="syncValue.value"
                     v-bind="{
                       defaultCountry: countryCode,
                       inputOptions: {
                         placeholder,
                       },
                       mode: 'national',
                     }"
                     @on-input="onInput"
                     :auto-default-country="false"
                     :validCharactersOnly="true"
                     :disabled="props.disable" />
    </template>
  </q-field>
</template>

<style scoped lang="scss">
  @import "@/css/functions";

  // QField
  .q-field {

    // Native
    :deep(.q-field__native) {
      padding: 0;

      .disabled {
        background-color: $grey-01;
      }
    }

    // Auto Height
    &--auto-height { // .q-field

      // Labeled
      &.q-field--labeled { // .q-field

        // Native
        :deep(.q-field__native) {
          padding-block-start: 0;
        }
      }
    }

    // Error
    &--error {

      :deep(.vue-tel-input) {
        background-color: $notify-yellow;

        .vti__input {
          background-color: $notify-yellow;
        }
      }
    }
  }

  // Vue Tel Input
  :deep(.vue-tel-input) {
    $vue-tel-brdr-radius: makeRem($generic-border-radius-sm);

    width: 100%;
    background-color: $white;
    //border: 1px solid $field-brdr-clr;
    border: 0 none;
    //border-radius: $vue-tel-brdr-radius;

    &:focus-within {
      border-color: $field-brdr-clr;
      box-shadow: none;
    }

    .vti {

      &__dropdown {
        padding: 0;
        padding-block: makeRem($field-padding-block-outlined);
        padding-inline-start: makeRem($field-padding-inline);

        &:hover,
        &.open {
          background-color: transparent;
        }
      }

      &__flag {
        margin-left: 0;
      }

      &__dropdown-list {
        width: makeRem(250px);
        border: 1px solid $field-brdr-clr;
        border-radius: $vue-tel-brdr-radius;

        &.below {
          top: makeRem(31px);
        }
      }

      &__input {
        line-height: makeRem($field-line-height);
        color: $primary-color;
        padding: 0;
        padding-block: makeRem($field-padding-block-outlined);
        padding-inline: makeRem($field-padding-inline);
        border-radius: 0 $vue-tel-brdr-radius $vue-tel-brdr-radius 0;

        &::placeholder {
          color: $field-brdr-clr;
          opacity: 1;
        }
      }
    }
  }
</style>
