<script lang="ts" setup>
import {
  type PropType
} from 'vue';

const props = defineProps({
  class: {
    type: String
  },
  height: {
    type: String
  },
  loading: {
    type: Boolean,
    default: false
  },
  size: {
    type: String
  },
  square: {
    type: Boolean
  },
  tag: {
    type: String
  },
  type: {
    type: String as PropType<'text' | 'rect' | 'circle' | 'QBtn' | 'QBadge' | 'QChip' | 'QToolbar' | 'QCheckbox'
      | 'QRadio' | 'QToggle' | 'QSlider' | 'QRange' | 'QInput' | 'QAvatar' | undefined>
  },
  width: {
    type: String
  }
});
</script>

<template>
  
  <!-- QSkeleton -->
  <q-skeleton v-if="loading"
              animation-speed="3000"
              :class="props.class"
              :height="props.height"
              :size="props.size"
              :square="props.square"
              :tag="props.tag"
              :type="props.type"
              :width="props.width" />
  
  <!-- Content -->
  <div v-show="!loading">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
  .text-center > .q-skeleton {
    margin-inline: auto;
  }
</style>