<script setup lang="ts">
import {computed, nextTick, ref, watch} from "vue";
import {AccountType, ButtonHeightEnum, ButtonStdColorEnum} from "@/constants/enums";
import {useQuasar} from 'quasar';
import {useRoute, useRouter} from 'vue-router';
import {getSupportAgents, getSupportAgentsWithFilter} from "@/api/endpoints/supportAgentWeb";
import {useFilterStore} from "@/stores/filterStore";
import {storeToRefs} from "pinia";
import BtnStandard from "@/components/BtnStandard.vue";
import type {DropdownItem} from "@/components/Dropdown.vue";
import {i18n} from "@/plugins/i18n";

const $q = useQuasar(), router = useRouter(), route = useRoute()

const filterStore = useFilterStore();
const {clearSupportAgentFilterCount} = storeToRefs(filterStore)

const permissionOptions = ref()
const form = ref()

nextTick(() => form.value.resetValidation())


const getSupportAgentFilterData = async () => {
  const supportAgents = await getSupportAgents()
  permissionOptions.value = supportAgents.data.permissions.filter((p: string) => p != 'SupportAgent').map((permission: string) => {
    return {
      label: getPermissionLabel(permission),
      value: permission
    }
  })
}

const getPermissionLabel = (permission: string) => {
  if (permission == 'SupportManager')
    return i18n.global.t('gen_support_manager');
  else return permission;
}

(async () => await getSupportAgentFilterData())();

watch(clearSupportAgentFilterCount, () => onCancel())

// table data
const supportAgentTable = ref()
const supportAgentTableColumns = ref([
  {name: 'upId', field: 'upId', sortable: true, align: 'left', sortOrder: 'ad'},
  {name: 'firstName', field: 'firstName', sortable: true, align: 'left'},
  {name: 'lastName', field: 'lastName', sortable: true, align: 'left'},
  {name: 'status', field: 'status', sortable: true, align: 'left'},
  {name: 'lastLogin', field: 'lastLogin', sortable: true, align: 'left'},
  {name: 'permissions', field: 'permissions', sortable: false, align: 'left'},
  {name: 'phoneNumber', field: 'phoneNumber', sortable: true, align: 'left'},
  {name: 'email', field: 'email', sortable: true, align: 'left'},
])
const supportAgentTableRows = ref([])

const loading = ref(false)
const filter = ref('')
const showResults = ref(false);

const firstNameModel = ref('');
const lastNameModel = ref('');
const emailModel = ref('');
const permissionsModel = ref([] as DropdownItem[]);

const onSubmit = async () => {
  const isValid = await form.value.validate()
  if (isValid) {
    $q.loading.show();
    filterStore.supportAgentFilterCount = getFilterCount()

    const permissions = permissionsModel.value.map((permission) => permission.value);
    initialPagination.value.page = 1;
    const filteredSupportAgentData = await getSupportAgentsWithFilter('true', AccountType.CustomerSupport, null,
      null, firstNameModel.value, lastNameModel.value, emailModel.value, null, null, null,
      null, null, null, null, null, permissions,
      initialPagination.value.descending, initialPagination.value.sortBy, initialPagination.value.page, initialPagination.value.rowsPerPage)

    filteredSupportAgentData.data.rows.forEach((row) => {
      row.permissions = row.permissions.map((permission: string) => {
        return getPermissionLabel(permission)
      })
    })
    supportAgentTableRows.value = filteredSupportAgentData.data.rows
    initialPagination.value.rowsNumber = filteredSupportAgentData.data.totalRows
    showResults.value = true;
    $q.loading.hide();
  }
}


function getFilterCount() {
  var count = 0;
  if (firstNameModel.value !== '') count++
  if (lastNameModel.value !== '') count++
  if (emailModel.value !== '') count++
  if (permissionsModel.value.length > 1) count++

  return count
}

const onCancel = () => {
  firstNameModel.value = ''
  lastNameModel.value = ''
  emailModel.value = ''
  permissionsModel.value = [] as DropdownItem[]
  showResults.value = false;

  initialPagination.value.sortBy = 'asc'
  initialPagination.value.descending = false

  nextTick(() => form.value.resetValidation())
  supportAgentTableRows.value = []
  filterStore.supportAgentFilterCount = 0
}


const onRowClick = (evt: number) => {
  router.push({
    name: 'EditSupportAgent',
    params: {
      userId: evt.toString()
    }
  });
}

const initialPagination = ref({
  sortBy: 'asc',
  descending: false,
  page: 1,
  rowsPerPage: 15,
  rowsNumber: 5
})

async function onRequest(props: any) {
  const {page, rowsPerPage, sortBy, descending} = props.pagination

  loading.value = true;
  $q.loading.show();

  const permissions = permissionsModel.value.map((permission) => permission.value);

  const filteredSupportAgentsData = await getSupportAgentsWithFilter('true', AccountType.CustomerSupport, null,
    null, firstNameModel.value, lastNameModel.value, emailModel.value, null, null, null,
    null, null, null, null, null, permissions,
    descending, sortBy, page, rowsPerPage)

  loading.value = false
  $q.loading.hide();

  filteredSupportAgentsData.data.rows.forEach((row) => {
    row.permissions = row.permissions.map((permission: string) => {
      return getPermissionLabel(permission)
    })
  })
  supportAgentTableRows.value = filteredSupportAgentsData.data.rows

  initialPagination.value.page = page
  initialPagination.value.sortBy = sortBy
  initialPagination.value.descending = descending
  initialPagination.value.rowsNumber = filteredSupportAgentsData.data.totalRows
}


const permissionsSelected = computed(() => {
  return permissionsModel.value != null && permissionsModel.value.length > 0;
})


</script>

<template>
  <q-page padding>

    <div class="row items-center">
      <div class="q-pb-xl col">
        <h1>{{$t('gen_support_agents')}}</h1>
      </div>

      <div class="q-mb-xl col text-right">
        <BtnStandard :color="ButtonStdColorEnum.darkTealText"
                     :height="ButtonHeightEnum.md"
                     :to="{ name: 'CreateSupportAgent' }"
                     :label="$t('create_support_profile')" />
      </div>
    </div>

    <div class="row">
      <div class="col bg-white q-pa-xl rep-profile__form">
        <q-form @submit.prevent ref="form">
          <zbm-skeleton-loader height="22.5714285714rem" width="100%">

            <div class="row mb-20 rep-profile__fields">

              <div class="col q-pa-sm">
                <q-input outlined v-model="firstNameModel" placeholder="First Name"/>
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="lastNameModel" placeholder="Last Name"/>
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="emailModel" placeholder="Email"/>
              </div>
              <div class="col q-pa-sm">
                <q-select outlined v-model="permissionsModel" :options="permissionOptions" :placeholder="permissionsSelected ? undefined : $t('gen_permissions')"
                          :use-input="!permissionsSelected"
                          multiple
                          use-chips />
              </div>
            </div>
            
            <!-- Form Buttons -->
            <div class="row items-center q-col-gutter-x-sm rep-profile__buttons">
              <!-- Cancel -->
              <div class="col-auto q-ml-auto">
                <zbm-skeleton-loader height="2.8571428571rem" width="3.1428571429rem">
                  <zbm-btn @click="onCancel" :color="$zb.enums.ButtonStdColorEnum.darkTealText"
                           :height="$zb.enums.ButtonHeightEnum.md"
                           :label="$t('gen_clear_filters')" :data-testid="'btnCancel'"/>
                </zbm-skeleton-loader>
              </div>

              <!-- Save -->
              <div class="col-auto">
                <zbm-skeleton-loader height="2.8571428571rem" width="11.5714285714rem">
                  <zbm-btn @click="onSubmit" :color="$zb.enums.ButtonStdColorEnum.darkTeal"
                           :height="$zb.enums.ButtonHeightEnum.md" :label="$t('admin_search_go_button')"
                           :type="$zb.enums.ButtonTypeEnum.submit" :data-testid="'btnSave'"/>
                </zbm-skeleton-loader>
              </div>
            </div>
          </zbm-skeleton-loader>

        </q-form>
      </div>
    </div>
    
    <div v-if="showResults">
      <zbm-table :columns="supportAgentTableColumns" :rows="supportAgentTableRows" row-key="upId"
                 expandable :hide-pagination="false" ref="supportAgentTable"
                 v-model:pagination="initialPagination"
                 :loading="loading"
                 @request="onRequest">
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th key="upId" :props="props" class="text-left">{{ $t('gen_upid') }}</q-th>
            <q-th key="firstName" :props="props" class="text-left">{{ $t('gen_first_name') }}</q-th>
            <q-th key="lastName" :props="props" class="text-left">{{ $t('gen_last_name') }}</q-th>
            <q-th key="status" :props="props" class="text-left">{{ $t('gen_status') }}</q-th>
            <q-th key="lastLogin" :props="props" class="text-left">{{ $t('gen_last_login') }}</q-th>
            <q-th key="permissions" :props="props" class="text-left">{{ $t('gen_permissions') }}</q-th>
            <q-th key="phoneNumber" :props="props" class="text-left">{{ $t('gen_phone') }}</q-th>
            <q-th key="email" :props="props" class="text-left">{{ $t('gen_email') }}</q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" :key="`row-${props.row.upId}`" class="cursor-pointer"
                @click="onRowClick(props.row.upId)">
            <q-td key="upId" :props="props" no-hover>
              {{ props.row.upId }}
            </q-td>

            <q-td key="firstName" :props="props" no-hover>
              {{ props.row.firstName }}
            </q-td>

            <q-td key="lastName" :props="props" no-hover>
              {{ props.row.lastName }}
            </q-td>

            <q-td key="status" :props="props" no-hover>
              {{ props.row.status }}
            </q-td>

            <q-td key="lastLogin" :props="props" no-hover>
              {{ props.row.lastLogin }}
            </q-td>

            <q-td key="permissions" :props="props" no-hover>
              <template v-for="(element, index) in props.row.permissions">
                <q-chip outline square color="grey" text-color="grey">
                  {{ element }}
                </q-chip>
              </template>
            </q-td>

            <q-td key="phoneNumber" :props="props" no-hover>
              {{ props.row.phoneNumber }}
            </q-td>

            <q-td key="email" :props="props" no-hover>
              {{ props.row.email }}
            </q-td>

          </q-tr>
        </template>

      </zbm-table>
    </div>
  </q-page>
</template>
