<script lang="js" setup>
import { computed, onMounted, onBeforeMount, ref} from 'vue';
import {postLocalLogin} from "@/api/endpoints/login";
import {useSessionStore} from "@/stores/sessionStore";
import { handleApiRequest } from '@/api/handleApiRequest';
import { useConfigStore } from "@/stores/configStore";
import Box from '@/components/Box.vue';
import {
  ButtonHeightEnum,
  ButtonStdColorEnum,
  ButtonTypeEnum
} from '@/constants/enums';
import {useRouter} from 'vue-router';
import BtnStandard from '@/components/BtnStandard.vue';
import{ useQuasar} from 'quasar'

const router = useRouter();
const sessionStore = useSessionStore();
const $q = useQuasar();
const configStore = useConfigStore();

// Reactive data
const loginModel = ref({
  password: '',
  username: '',
  utcOffset: sessionStore.utcOffset,
});
const inputType = ref(true);
const eyeClosed = ref(false);

// Computed properties
const utcOffset = computed(() => sessionStore.utcOffset);

// Template Refs
const loginForm = ref(null);

const onSubmitSave = async () => {
  try {
    $q.loading.show()
    const response = await handleApiRequest(() => postLocalLogin(loginModel.value));
    if (response?.data?.result) {
      await router.push({name: 'PostAuth'});
    }
    else {
      $q.notify({ type: 'warning', message: 'Invalid username or password' });
    }
  } finally {
    $q.loading.hide()
  }

  loginModel.value.password = '';

  requestAnimationFrame(() => {
    loginForm.value?.resetValidation();
  });
};

// Lifecycle hooks

onBeforeMount(async () => {
  if (configStore.useZBEdgeLogin && configStore.loginUrl)
    window.location.href = configStore.loginUrl;
});

onMounted(async () => {

  document.querySelectorAll('html')[0].scrollTop = 0;
  (document.querySelectorAll('input:first-child')[0]).focus();

  sessionStore.setIsHeaderVisible(false);
});

// Expose properties for template usage
defineExpose({
  loginModel,
  inputType,
  eyeClosed,
  utcOffset,
  onSubmitSave,
});
</script>

<template>
  <q-page class="no-header login-form" padding>
    <div class="row justify-center">

      <Box class="col-auto">

        <!-- Heading -->
        <div class="row no-wrap login-form__heading">
          <div class="col">
            <h1>Login</h1>
          </div>
        </div>

        <!-- Log In Form -->
        <q-form @submit.prevent="onSubmitSave"
                ref="loginForm">

          <!-- Username -->
          <div class="row justify-center">
            <div class="col-auto">
              <zbm-input-with-validation autofocus v-model="loginModel.username" id="Username" placeholder="Username"
                                         :rules="[$zb.validators.required(' ')]">
              </zbm-input-with-validation>
            </div>
          </div>

          <!-- Password -->
          <div class="row justify-center">
            <div class="col-auto">
              <zbm-password-input-with-validation v-model="loginModel.password"
                                                  id="Password" name="Password"
                                                  placeholder="Password"
                                                  >
              </zbm-password-input-with-validation>
            </div>
          </div>

          <!-- Log In Button -->
          <div class="row justify-center" id="loginButtonRow">
            <BtnStandard class="glow--white"
                         :color="ButtonStdColorEnum.darkTeal"
                         :height="ButtonHeightEnum.lg"
                         label="Log In"
                         :type="ButtonTypeEnum.submit" />
          </div>
        </q-form>
      </Box>
    </div>
  </q-page>
</template>

<style lang="scss">
@use "sass:math";

  .login-form { // .q-page
    // Heading
    &__heading { // .row
      margin-block-end: $space-double;
      text-align: center;
    }
    // Form
    .q-form { // <form>

      > .row {
        margin-block-end: $space-base;
        &:last-child{ margin-block-end: 0; }
      }

      .q-field-password .q-field__control-container {
          padding-block-start: 0 !important;
      }

      #loginButtonRow {
        margin-block-start: $space-base;
      }
    }

    @media (max-width: 479px) {
      // Heading
      &__heading { // .row

        > [class*="col"]:first-child {
          display: none;
        }
      }
    }

    @media (min-width: 600px) {
      // Form
      .q-form {
      }
    }
  }
</style>
