<script setup lang="ts">
import {
  defineModel,
  type PropType
} from 'vue';
import {
  type DialogBorderRadius,
  type DialogPadding, DialogType
} from "@/constants/enums";

const props = defineProps({
  /* Controls the `border-radius` of the Dialog. Options are:
   * "br12" - sets the `border-radius` to 12px.
   */
  borderRadius: {
    type: String as PropType<DialogBorderRadius>,
  },
  closeButton: {
    type: Boolean,
    default: true
  },
  noBackdropDismiss: {
    type: Boolean,
    default: false
  },
  noEscDismiss: {
    type: Boolean,
    default: false
  },
  noRefocus: {
    type: Boolean,
    default: true
  },
  noRouteDismiss: {
    type: Boolean,
    default: false
  },
  /* Controls the `padding` of the Dialog. Options are:
   * "pbs40" - sets the `padding-block-start` (`padding-top`) to 40px.
   */
  padding: {
    type: String as PropType<DialogPadding>,
  },
  persistent: {
    type: Boolean,
    default: false
  },
  type: {
    type: String as PropType<DialogType>
  }
});

const showDialog = defineModel({
  type: Boolean,
  required: true
});
</script>

<template>
  <q-dialog v-model="showDialog"
            :class="[props.type]"
            :no-backdrop-dismiss="props.noBackdropDismiss"
            :no-esc-dismiss="props.noEscDismiss"
            :no-refocus="props.noRefocus"
            :no-route-dismiss="props.noRouteDismiss"
            :persistent="props.persistent">
    <div :class="[props.padding, props.borderRadius]">
      
      <!-- Close (X) Button -->
      <q-btn v-if="props.closeButton"
             @click="showDialog = !showDialog"
             icon="far fa-times"
             padding="0"
             :ripple="false"
             square
             unelevated />
      
      <!-- Dialog Content -->
      <slot />
    </div>
  </q-dialog>
</template>

<style lang="scss" scoped>
@import "@/css/functions";

.q-dialog__inner > div {
  
  &.pbs-40 {
    padding-block-start: makeRem(40px);
  }

  &.br-12 {
    border-radius: $generic-border-radius * 2;
  }
}

.q-btn {
  $q-btn-size: makeRem(30px);
  
  position: absolute;
  top: 0;
  right: 0;
  width: $q-btn-size;
  height: $q-btn-size;
  background-color: $body-bkgd-clr;
  font-size: makeRem(20px);
  color: $body-txt-clr;
}
</style>