<template>

</template>

<script lang="js" setup>
import { onMounted } from "vue";
import {useRouter} from "vue-router";
import { useSessionStore } from '@/stores/sessionStore';
import { useConfigStore } from "@/stores/configStore";
import {Logout} from "@/api/endpoints/login";
import {handleApiRequest} from "@/api/handleApiRequest";
import { useQuasar } from 'quasar';

const configStore = useConfigStore();
const sessionStore = useSessionStore(),
    router = useRouter(),
    $q=useQuasar();

onMounted(async () => {
  $q.loading.show();
  sessionStore.clear();
  const response = await handleApiRequest(() => Logout());
  window.location.href = configStore.postLogoutUrl ?? '/';
});

</script>
