import axios from '@/api/axiosConfig'
import {ApiRoutes} from '../ApiRoutes'
import type { AxiosResponse } from 'axios';
import {AccountType} from "@/constants/enums";
import type {ApiResponse} from "@/types/apiTypes";


export interface GetClinicianSupportDetailsResponse {
  clinicianProfileId: number;
  email: string;
  phone: string;
  phoneCountryCode: string;
  prefix: string;
  suffix: string;
  firstName: string;
  lastName: string;
  npi: string;
  isDeactivated: boolean;
  timezoneOffset: number;
  federatedTenant: boolean;
  inviteStatus: string;
  recent2FAErrorMsg: string;
  recent2FAAttempts: RecentMFAResponse[];
  clientDetails: ClinicianClientDetailsResponse[];
}

export interface RecentMFAResponse {
  trailingPinCode: string;
  sentTime: Date;
  sentTimeLocalized: string;
  status: string;
}

export interface ClinicianClientDetailsResponse {
  clientId: number;
  clientName: string
  isDeactivated: boolean;
  clinicianTitle: string
  clinicianIsAdmin: boolean;
  clinicianIsSurgeon: boolean;
  clinicianIsPIQEnabled: boolean;
  clinicianIsROSAEnabled: boolean;
  careTeamNames: string[];
}

export interface UpdateClinicianRequest {
  clinicianProfileId: number | undefined;
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  phoneCountryCode: string | undefined;
  phone: string | undefined;
  npi: string | undefined;
  salesforceCaseId: string | undefined;
  notes: string | undefined;
}

export async function getClinicians() {
  return await axios.get(ApiRoutes.Clinician.GetClinicianList, {params: {applyFilter: 'false', accountType: "Clinician"}});
}

export async function getCliniciansWithFilter(applyFilter: string, accountType: AccountType, upId: string, status: string,
                                            firstName: string, lastName: string, email: string, phoneNumber: string,
                                            clientName: string, birthDate: string, mrn: string, studyId: string,
                                            clientCountry: string, clientType: string, clients: Array<string>, permissions: Array<string>,
                                            orderDesc: boolean, orderByHeader: string, page: number, pageSize: number) {
    return await axios.get(ApiRoutes.Clinician.GetClinicianList,
      {params: {applyFilter, accountType, upId, status, firstName, lastName, email, phoneNumber, clientName,
          birthDate, mrn, studyId, clientCountry, clientType, clients, permissions, orderDesc, orderByHeader, page, pageSize},
          paramsSerializer: { indexes: null }});
}

export async function getClinicianDetail(clinicianProfileId: number): Promise<AxiosResponse<ApiResponse<GetClinicianSupportDetailsResponse>>>  {
  return await axios.get(ApiRoutes.Clinician.GetClinicianDetail, { params: { clinicianProfileId } });
}

export async function updateClinicianDetails(request: UpdateClinicianRequest) {
  return await axios.post(ApiRoutes.Clinician.UpdateClinicianDetails, request);
}
