<script setup lang="ts">

</script>

<template>
  <h2 class="q-ma-lg">{{ $t('error_unexpected_occured') }}</h2>
</template>

<style scoped lang="scss">

</style>
