<template>
  <q-page padding id="clientDetailPage">

    <div id="pageError" v-if="pageErrorMessage">
      <div class="errorMessage">{{pageErrorMessage}}</div>
      <button class="button cancel" type="button" @click="ToClientsList">Return to Clients</button>
    </div>

    <div v-if="loaded && !pageErrorMessage">

      <div id="clientDetailHeader">
        <h1>
          <q-icon name="far fa-solid fa-chevron-left" class="cursor-pointer" @click="ToClientsList" />
          {{ client?.name }}
        </h1>
      </div>

      <div id="clientAccountSection">
          <div id="clientAccount">
            <h5>Client Details</h5>
            <div class="row">
              <div class="column">
                <dl>
                  <dt>Client Type</dt>
                  <dd>
                    <span>{{client?.clientType}}</span>
                  </dd>
                  <dt>Client Country</dt>
                  <dd>
                    <span>{{client?.country}}</span>
                  </dd>
                  <dt>Supported Languages</dt>
                  <dd>
                    <span>{{client?.languages}}</span>
                   </dd>
                </dl>
              </div>
            </div>
        </div>
        
      </div>
      <h2>Features</h2>

      <div id="clientAccountSection">
        <div id="clientAccount">
          <h5>Features</h5>
          <ClientFeatures :clientId=client?.clientId :client-features=client?.clientFeatures></ClientFeatures>
        </div>
      
      </div>

    </div>
  </q-page>
</template>

<script setup lang="ts">
import {defineComponent, ref, computed, onMounted, type Ref, onActivated} from 'vue';
  import { useRouter, useRoute } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { handleApiRequest } from '@/api/handleApiRequest';
  import {getClientDetail, type GetClientSupportDetailsResponse} from "@/api/endpoints/clientWeb"
import ClientFeatures from "@/pages/Client/components/ClientFeatures.vue";
  
  const expanded = ref([ 'Features' ])
  const simple = ref([])
  
  const clientId=ref(0),
        loaded=ref(false),
        pageErrorMessage = ref(''),
        priorNotify= function(){ },
        client: Ref<GetClientSupportDetailsResponse | undefined> = ref(),
        $q=useQuasar(),
        $route = useRoute(),
    router = useRouter()

  onMounted(async () => {
    clientId.value = $route.params?.clientId != null ? parseInt($route.params.clientId + "") : 0;
    await loadClient();
  });

  onActivated(async () => {
    clientId.value = $route.params?.clientId != null ? parseInt($route.params.clientId + "") : 0;
    await loadClient();
  });


const loadClient = async () => {
    loaded.value = false;
    $q.loading.show();
  
    if (clientId?.value && clientId.value > 0) {
      const clientResponse = await handleApiRequest < GetClientSupportDetailsResponse > (() => getClientDetail(clientId.value));

      console.log(clientResponse);
      if (clientResponse?.data == null || clientResponse?.data?.errorMessage) {
        pageErrorMessage.value = clientResponse?.data?.errorMessage ?? "unable to load client.";
      }
      else {
        client.value = clientResponse.data;
        simple.value = client?.value?.clientFeatures
      }

    } else {
      pageErrorMessage.value = 'Invalid Client ID.';
    }

    loaded.value = true;
    $q.loading.hide();
  }

  const ToClientsList = (evt: any) => {
    router.push({
      name: 'Clients',
      params: {  }
    });
  }

</script>

<style lang="scss">

  @import "@/css/styles-quasar";

  #clientDetailPage {

    #pageError {
      text-align: center;
      font-weight: bold;
      padding: $space-double;

      .errorMessage {
        margin-bottom: $space-double;
        font-size: 18px;
      }
    }

    .panelActionButtonWrap {
      float: right;
      text-align: right;
      position: absolute;
      width: 20px;
      height: 20px;
      right: $space-base;
      top: $space-base;

      .editIcon,
      .cancelIcon {
        cursor: pointer;
        width: 20px;
        height: 20px;
      }

      .cancelIcon {
        display: none;
      }
    }

    .editMode {
      .panelActionButtonWrap {
        .editIcon {
          display: none;
        }

        .cancelIcon {
          display: block;
        }
      }
    }

    dl {
      display: grid;
      grid-gap: 4px 16px;
      grid-template-columns: auto minmax(0, 1fr);
      align-content: flex-start;
      padding: 0;
      max-width: 100%;

      dt {
        color: $body-dim-txt-clr;
      }

      dd {
        margin: 0;
        grid-column-start: 2;
        overflow-wrap: anywhere;
      }
    }

    .row {
      gap: $space-base;
      justify-content: space-between;
      display: flex;
      flex-direction: row;
      max-width: 100%;

      .column {
        flex: 1;
        max-width: 48%;
      }
    }

    #clientDetailHeader {
      margin-bottom: $space-double;

      .fa-chevron-left {
        font-weight: bold;
        margin-right: $space-base;
      }
    }

    #clientAccountSection {
      display: flex;
      flex-direction: row;
      gap: $space-base;
      justify-content: space-between;
      margin-bottom: $space-double;

      #clientAccount {
        @extend .panel;
        flex: 66%;
        position: relative;

        #editAccountControls {
          border-top: 1px solid $border-clr;
          margin-top: $space-base;
          padding-top: $space-base;
        }
      }
      
      @media screen and ( max-width:$breakpoint-lg ) {
        flex-direction: column;
      }
    }

    #procedureSelectorWrap {
      margin-bottom: $space-base;
    }
    
  }
</style>
