<template>
  <q-input v-model="syncValue" :class="[inputWidth, 'q-field-password']" :type="toggleShow ? 'text' : 'password'" :label="label"
           :placeholder="placeholder" :rules="rules" no-error-icon trim outlined>
    <template v-slot:append>
      <q-icon :name="toggleShow ? 'far fa-eye-slash' : 'far fa-eye'" class="cursor-pointer" @click="toggleHidePassword" />
    </template>
  </q-input>
</template>

<script lang="js" setup>
import { FormElWidthEnum, InputGroupColorSchemeEnum } from '@/constants/enums';
import { ref } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  rules: {
    type: Array,
    default: []
  },
  label: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  inputWidth: {
    type: String,
    default: FormElWidthEnum.qFormEl310
  },
  inputColorScheme: {
    type: String,
    default: InputGroupColorSchemeEnum.default,
  },
  autocomplete: {
    type: String,
    default: 'off',
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})

const toggleShow = ref(false);
const syncValue = defineModel();

function toggleHidePassword() {
  toggleShow.value = !toggleShow.value;
}

</script>
