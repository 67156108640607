<script setup lang="ts">
import {
  defineModel,
  defineProps
} from "vue";

const props = defineProps({
  disable: {
    type: Boolean,
    default: false
  },
  falseValue: {},
  leftLabel: {
    type: Boolean,
    default: false
  },
  label: {
    type: String,
  },
  name: {
    type: String
  },
  trueValue: {},
  val: {}
});

const syncValue = defineModel();
</script>

<template>
  <q-toggle v-model="syncValue"
            color="white"
            :disable="props.disable"
            :false-value="props.falseValue"
            :left-label="props.leftLabel"
            :label="props.label"
            :name="props.name"
            :true-value="props.trueValue"
            :val="props.val" />
</template>

<style scoped lang="scss"></style>
