import { i18n } from "./i18n"


export const required = (errorMessageKey?: string | undefined) => (val : string | undefined) => {
  return (typeof val !== 'undefined' && val !== null && val.toString().trim() !== '') || i18n.global.t(errorMessageKey || 'form_errors_parsley_required')
}

export const requiredIf = (conditionCallback : any | undefined, errorMessageKey?: string | undefined) => (val : any) => {
  return (!conditionCallback() || (typeof val !== 'undefined' && val !== null && val.trim() !== '')) || i18n.global.t(errorMessageKey || 'form_errors_parsley_required')
}

export const alphaNum = (errorMessageKey?: string | undefined) => (val: any) => {
  return (typeof val !== 'undefined' && /^[a-zA-Z0-9]*$/.test(val)) || i18n.global.t(errorMessageKey || 'form_errors_parsley_type_alphanum')
}

export const alphaZb = (errorMessageKey?: string | undefined) => (val: any) => {
  return (typeof val !== 'undefined' && /([\p{L}]|[\p{sc=Arabic}])+[\s-]*([\p{L}]|[\p{sc=Arabic}])*/gu.test(val)) || i18n.global.t(errorMessageKey || 'form_errors_parsley_type_alphanum')
}

export const email = (errorMessageKey?: string | undefined) => (val: any) => {
  const emailRegex = /^$|null|^(?:[A-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9]{2,}(?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
  return (val != 'undefined' && emailRegex.test(val)) || i18n.global.t(errorMessageKey || 'form_errors_parsley_email')
}

export const minLen = (len: number, errorMessageKey?: string | undefined) => (val: any) => {
  if (val && val.length >= len) return undefined;

  let error = i18n.global.t(errorMessageKey || 'form_errors_parsley_min_length');

  // Handle legacy format
  if (error.includes('%s'))
    error = error.replace('%s', len.toString())

  return error;
}

export const maxLen = (len: any, errorMessageKey?: string | undefined) => (val: any) => {
  if (!val || val.length <= len) return undefined;

  let error = i18n.global.t(errorMessageKey || 'form_errors_parsley_max_length');

  // Handle legacy format
  if (error.includes('%s'))
    error = error.replace('%s', len.toString())

  return error;
}

export const betweenLen = (minLength: any, maxLength: any, errorMessageKey?: string | undefined) => (val: any) => {
  return minLen(minLength, errorMessageKey) && maxLen(maxLength, errorMessageKey) || i18n.global.t(errorMessageKey || 'form_errors_parsley_length_error, minLength, maxLength')
}

export const minVal = (min: number, errorMessageKey?: string | undefined) => (val: any) => {
  return (val >= min) || i18n.global.t(errorMessageKey || 'form_errors_parsley_min_val', min.toString())
}

export const maxVal = (max: any, errorMessageKey?: string | undefined) => (val: any) => {
  return (val <= max) || i18n.global.t(errorMessageKey || 'form_errors_parsley_max_val', max)
}

export const betweenVal = (min: any, max: any, errorMessageKey?: string | undefined) => (val: any) => {
  return (minVal(min, errorMessageKey) && maxVal(max, errorMessageKey)) || i18n.global.t(errorMessageKey || 'form_errors_parsley_range_error', min, max)
}

export const phoneTelInput = (errorMessageKey?: string | undefined) => (phoneObject: any) => {
  return phoneObject === null || typeof phoneObject.valid === 'undefined' || phoneObject.valid || i18n.global.t(errorMessageKey || 'error_email_phone_invalid')
}

export const postalCodeFormat = (errorMessageKey?: string | undefined) => (val: any) => {
  const followsPattern = /^$|^([a-zA-Z0-9]+[ -]*)+/g.test(val);
  const noSpecialChars = /[~`!@#$%()_./:?^&*+=<>;'"\[\]{}\\]/g.test(val);
  return (followsPattern && !noSpecialChars) || i18n.global.t(errorMessageKey || 'form_errors_parsley_defaultMessage') ;
}
