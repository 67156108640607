<script setup lang="ts">
import {computed, defineProps} from "vue";

const props = defineProps({
  class: {
    type: String
  },
  color: {
    type: String
  },
  icon: {
    type: String,
    required: true
  },
  size: {
    type: String
  }
});
</script>

<template>
  <q-btn :class="props.class"
         :icon="props.icon"
         no-caps
         padding="0"
         :ripple="false"
         :size="props.size"
         square
         :text-color="props.color"
         unelevated />
</template>

<style lang="scss" scoped></style>