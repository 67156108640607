import { useQuasar } from 'quasar'
import {getSession} from "@/api/endpoints/login";
import {handleApiRequest} from "@/api/handleApiRequest";
import {useSessionStore} from "@/stores/sessionStore";
import type { UserSession } from "@/api/models/supportUser";
import {validRoutesByUser} from "@/constants/authorization";

export default async (to, from) => {
  const matchedAnonRoute = to.matched.find(record => record.meta.allowAnonymous)
  const matchedLoginRoute = to.matched.find(record => record.name === 'LocalLogin')
  const matchedLogoutRoute = to.matched.find(record => record.name === 'Logout')
  const isAuthRestrictedRoute = to.matched.find(record => record.meta.authRestricted)

  const $q = useQuasar()
  const sessionStore = useSessionStore()

  sessionStore.setSSOSignal();

  if (!sessionStore.isAuthenticated && !matchedLoginRoute && !matchedLogoutRoute){
    const response = await handleApiRequest(() => getSession(), undefined, true);
    if (response == null || response.status === 401) {
      const authenticationUrl = response?.headers['x-authentication-url'];
      if (authenticationUrl) {
        window.location.href = authenticationUrl;
      }
      else {
        return { name: 'LocalLogin' }
      }
    }
    else if (response?.data?.result) {
      const userSession = response?.data?.result as UserSession;
      if (userSession?.userId)
        sessionStore.setSession(userSession);
    }
  }

  if (isAuthRestrictedRoute) {
    const validRoutes = validRoutesByUser;
    const route = to.path?.split('/')[1];

    if (sessionStore.isSupportAgent && validRoutes.supportAgent.find((r) => route.startsWith(r))) {
      return true;
    }
    if (sessionStore.canManageAgents && validRoutes.supportManager.find((r) => route.startsWith(r))) {
      return true;
    }

    return { name: 'PostAuth' }
  }

  if (matchedLoginRoute) {
    sessionStore.clear()
    return true;
  }

  if (sessionStore.isAuthenticated || matchedAnonRoute) {
    return true;
  }
}
