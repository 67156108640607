<template>
  <q-layout view="lhr LpR lfr">

    <!-- Header -->
    <Header />

    <!-- Left Drawer -->
    <q-drawer v-model="leftDrawerOpen" side="left" bordered overlay>
      <!-- Left drawer content would go here. -->
    </q-drawer>

    <!-- Right Drawer -->
    <q-drawer v-model="rightDrawerOpen" side="right" bordered overlay>
      <!-- Right drawer content would go here. -->
    </q-drawer>

    <!-- Page Container -->
    <q-page-container>

      <!-- Page Specific Content -->
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </q-page-container>

    <!-- Footer -->
    <Footer />
    <img v-if="configLoaded && isAuthenticated" :src="signal" alt="">
  </q-layout>
</template>

<script lang="js" setup>
import { computed, onMounted } from "vue";
import { useConfigStore } from "@/stores/configStore";
import { useSessionStore } from "@/stores/sessionStore";
import { useFilterStore } from "@/stores/filterStore";
import { getConfig } from "@/api/endpoints/config";
import { handleApiRequest } from "@/api/handleApiRequest";
import { registerSyntheticMomentCultures } from 'momentCultured'
import {useLocalizationStore} from "@/stores/localizationStore";
import { storeToRefs } from 'pinia';


const configStore = useConfigStore();
const sessionStore = useSessionStore();
const filterStore = useFilterStore();
const localizationStore = useLocalizationStore();

const isAuthenticated = computed(() => sessionStore.isAuthenticated);
const configLoaded = computed(() => configStore.configIsLoaded);
const signal = computed(() => {
  return configStore.sessionPixelUrl + "?signal=" + sessionStore.ssoSignal;
});

const loadAppConfig = async () => {
  const configClaims = await handleApiRequest(() => getConfig());
  if (configClaims?.data?.result) {
    configStore.setConfig(configClaims.data.result);
    configStore.configIsLoaded = true;
  }
}

const loadLocalization = async () => {
  await localizationStore.loadLocalizedStrings();
}

onMounted(async () => {
  registerSyntheticMomentCultures('en-US');

  var promises = [
    loadAppConfig(),
    loadLocalization()
  ];

  const results = await Promise.all(promises).catch((error) => {
    console.error(error);
  });

  //console.log(results);
});

</script>
