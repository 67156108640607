<template>
  <div>
  </div>
</template>

<script lang="ts" setup>
import {useSessionStore} from '@/stores/sessionStore';
import {getSession} from "@/api/endpoints/login";
import {handleApiRequest} from "@/api/handleApiRequest";
import {useRouter} from 'vue-router';
import {onActivated} from "vue";
import type {UserSession} from "@/api/models/supportUser";
import {AccountType} from "@/constants/enums";

const sessionStore = useSessionStore();
const router = useRouter();

onActivated(async () => {
  const response = await handleApiRequest(() => getSession());
  const user = response?.data?.result as UserSession;
  if (user && user.hasFullAccess) {
    sessionStore.setSession(user);
  }

  if (user?.userType === AccountType.SuperUser.toString()) {
    await router.push({name: 'SupportAgents'});
  }
  /*else if (user?.userType === AccountType.CustomerSupport.toString()) {
    await router.push({name: 'Dashboard'});
  }
  else */
  else await router.push({name: 'Patients'});

});

</script>


