<template>

  <!-- Label -->
  <label v-if="props.label"
         :for="props.for">
    {{ props.label }}<slot name="label-append" />
  </label>

  <!-- Field -->
  <q-field v-model="syncValue"
           bg-color="transparent"
           borderless
           :disable="props.disable"
           hide-bottom-space
           no-error-icon
           ref="qfield"
           tag="div">

    <!-- Control Slot -->
    <template #control v-if="$slots.control">
      <slot name="control" />
    </template>
  </q-field>
</template>

<script lang="ts" setup>
import { defineModel, ref } from 'vue'

const props = defineProps({
  disable: {
    type: Boolean,
    default: false
  },
  for: {
    type: String
  },
  label: {
    type: String
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
});

const qfield = ref()
const syncValue = defineModel();

defineExpose({
  validate: () => qfield.value.validate(),
  reset: () => qfield.value.reset()
})
</script>