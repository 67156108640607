<script lang="ts" setup>
import {
  defineModel,
  ref,
  type PropType
} from "vue";
import type { ValidationRule } from "quasar";
import { FormElWidthEnum } from '@/constants/enums';

const props = defineProps({
  autocomplete: {
    type: String,
    default: 'off'
  },
  disable: {
    type: Boolean,
    default: false
  },
  for: {
    type: String
  },
  label: {
    type: String,
  },
  optionDisable: {
    type: String
  },
  optionLabel: {
    type: String
  },
  options: {
    type: Array,
    default: () => []
  },
  optionValue: {
    type: String
  },
  readonly: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl150 - sets width to `150px`.
   * qFormEl200 - sets width to `200px`.
   * qFormEl250 - sets width to `250px`.
   * qFormEl310 - sets width to `310px`.
   */
  selectWidth: {
    type: String,
    default: FormElWidthEnum.qFormEl250,
  },
  omitDefaultOption: {
    type: Boolean,
    default: false,
  }
})

const qfield = ref()
const syncValue = defineModel();

defineExpose({
  validate: () => qfield.value.validate()
})
</script>

<template>

  <!-- Label -->
  <label v-if="props.label"
         :for="props.for">
    {{ props.label }}<slot name="label-append" />
  </label>

  <!-- Field -->
  <q-field v-model="syncValue"
           :class="[props.selectWidth]"
           :disable="disable"
           hide-bottom-space
           no-error-icon
           outlined
           :readonly="props.readonly"
           ref="qfield"
           :rules="props.rules"
           tag="div">
    
    <!-- Control Slot -->
    <template #control>
      <select v-model="syncValue"
              :autocomplete="props.autocomplete"
              :name="props.name"
              :disabled="disable">
        
        <!-- Default Option -->
        <option v-if="!omitDefaultOption"
                :label="$t('drop_down_default')"
                :value="undefined" />
        
        <!-- Options -->
        <option v-for="(opt, i) in props.options"
                :disabled="opt[props.optionDisable]"
                :key="i"
                :label="opt[props.optionLabel]"
                :value="props.optionValue ? opt[props.optionValue] : opt" />
      </select>
    </template>
  </q-field>
</template>

<style scoped lang="scss">
  @import "@/css/functions";
  
  // QField
  .q-field {
    // Native
    :deep(.q-field__native) {
      padding: 0;
    }
    // Select
    select {
      $select-bkgd-pos-offset-x: 10px;
      $select-bkgd-size-w: 9px;

      -webkit-appearance: none;
      appearance: none;
      width: 100%;
      background-color: $white;
      background-image: angleDownSvg($primary-color);
      background-position: right makeRem($select-bkgd-pos-offset-x) center;
      background-size: makeRem($select-bkgd-size-w) auto;
      background-repeat: no-repeat;
      background-clip: padding-box;
      line-height: makeRem($field-line-height);
      color: $primary-color;
      padding-block: makeRem($field-padding-block);
      padding-inline: makeRem($field-padding-inline) makeRem($field-padding-inline + $select-bkgd-size-w + $select-bkgd-pos-offset-x);
      border: none;

      &:focus {
        outline: none;
      }
    }
    // Readonly
    &--readonly { // .q-field
      // Select
      select {
        pointer-events: none;
        background-image: angleDownSvg($body-txt-clr);
        color: $body-txt-clr;
      }
      // Outlined
      &.q-field--outlined { // .q-field
        // Select
        select {
          border-color: transparent;
        }
      }
    }
    // Disabled
    &--disabled { // .q-field
      // Outlined
      &.q-field--outlined { // .q-field
        // Select
        select {
          border-color: rgba($field-brdr-clr, 0.3);
        }
      }
    }
    // Outlined
    &--outlined { // .q-field
      // Native
      :deep(.q-field__native) {
        border: none;
      }
      // Select
      select {
        padding-block: makeRem($field-padding-block-outlined);
        border: 1px solid $field-brdr-clr;
        border-radius: makeRem($generic-border-radius-sm);
      }
    }
    // Error
    &--error { // .q-field

      select {
        background-color: $notify-yellow;
      }
    }
    // Auto Height
    &--auto-height { // .q-field
      // Outlined
      &.q-field--outlined {
        // Native
        :deep(.q-field__native) {
          padding: 0;
        }
      }
    }
  }
</style>
