import { ref, computed } from 'vue'
import { defineStore } from 'pinia'

export const useFilterStore = defineStore('filter', () => {
  const clientFilterCount = ref(0);
  const patientFilterCount = ref(0);
  const clinicianFilterCount = ref(0);
  const supportAgentFilterCount = ref(0);
  const clearClientFilterCount = ref(0);
  const clearPatientFilterCount = ref(0);
  const clearSupportAgentFilterCount = ref(0);
  const clearClinicianFilterCount = ref(0);

  function setClearFilter() {
    clearPatientFilterCount.value++
    clearClientFilterCount.value++
    clearSupportAgentFilterCount.value++
    clearClinicianFilterCount.value++
  }

  return {
    clientFilterCount,
    patientFilterCount,
    clinicianFilterCount,
    supportAgentFilterCount,
    clearClientFilterCount,
    clearPatientFilterCount,
    clearSupportAgentFilterCount,
    clearClinicianFilterCount,
    setClearFilter,
  }
})
