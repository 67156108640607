import Clients from '@/pages/Client/Clients.vue';
import Clinician from '@/pages/Clinician/Clinician.vue';
import Patient from '@/pages/Patient/Patient.vue';
import LocalLogin from '@/pages/Login/LocalLogin.vue';
import PostAuth from '@/pages/Login/PostAuth.vue';
import Logout from '@/pages/Login/Logout.vue';
import Patients from '@/pages/Patient/Patients.vue';
import SupportAgent from '@/pages/SupportAgent/SupportAgent.vue';
import SupportAgents from '@/pages/SupportAgent/SupportAgents.vue';
import Clinicians from '@/pages/Clinician/Clinicians.vue';
import UserChangeLog from '@/pages/ChangeLog/UserChangeLog.vue';
import Client from "@/pages/Client/Client.vue";
import Error from '@/pages/Error.vue';

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        redirect: { name: 'PostAuth' }
      },
      {
        path: '/Clients',
        name: 'Clients',
        component: Clients,
        meta: {
          title: 'select_client_title',
          authRestricted: true
        }
      },
      {
        path: '/Client/:clientId',
        name: 'Client',
        component: Client,
        props: true,
        meta: {
          title: 'client_detail_title',
          authRestricted: true
        }
      },
      {
        path: '/Clinicians',
        name: 'Clinicians',
        component: Clinicians,
        meta: {
          title: 'select_clinician_title',
          authRestricted: true
        }
      },
      {
        path: '/Clinician/:clinicianProfileId',
        name: 'Clinician',
        component: Clinician,
        meta: {
          title: 'select_clinician_title',
          authRestricted: true
        }
      },
      {
        path: '/Patients',
        name: 'Patients',
        component: Patients,
        meta: {
          title: 'patient_detail_title',
          authRestricted: true
        }
      },
      {
        path: '/Patient/:patientId',
        name: 'Patient',
        component: Patient,
        meta: {
          title: 'patient_detail_title',
          authRestricted: true
        }
      },
      {
        path: '/ChangeLog/:userProfileId',
        name: 'ChangeLog',
        component: UserChangeLog,
        meta: {
          title: 'user_change_log_title',
          authRestricted: true
        }
      },
      {
        path: '/SupportAgents',
        name: 'SupportAgents',
        component: SupportAgents,
        meta: {
          title: 'admin_detail_title',
          authRestricted: true
        }
      },
      {
        path: '/SupportAgent/Edit/:userId',
        name: 'EditSupportAgent',
        component: SupportAgent,
        props: true,
        meta: {
          title: 'edit_rep_title',
          authRestricted: true
        }
      },
      {
        path: '/SupportAgent/Create',
        name: 'CreateSupportAgent',
        component: SupportAgent,
        props: true,
        meta: {
          title: 'create_rep_title',
          authRestricted: true
        }
      },
      {
        path: '/LocalLogin',
        name: 'LocalLogin',
        component: LocalLogin,
        meta: { allowAnonymous: true }
      },
      {
        path: '/PostAuth',
        name: 'PostAuth',
        component: PostAuth
      },
      {
        path: '/Logout',
        name: 'Logout',
        component: Logout
      },
      {
        path: '/Error',
        name: 'Error',
        component: Error
      },
    ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/pages/ErrorNotFound.vue')
  }
]

export default routes
