<script setup lang="ts">
const props = defineProps({
  avatarUrl: {
    type: String
  },
  displayName: {
    type: String,
    required: true
  },
  hasAvatarImage: {
    type: Boolean,
    required: true
  },
  iconBorder: {
    type: Boolean,
    default: true,
  },
  iconFontSize: {
    type: String,
    required: true
  },
  iconName: {
    type: String,
    required: false,
    default: 'fas fa-user-alt'
  },
  color: {
    type: String,
    required: false,
  },
  iconTopMargin: {
    type: String
  },
  size: {
    type: String,
    required: true
  }
});
</script>

<template>
  <q-avatar :class="{ 'no-border': !props.iconBorder }" :text-color="color" :size="props.size">
    <img v-if="props.hasAvatarImage" :alt="props.displayName" :src="props.avatarUrl" />
    <q-icon v-else :name="iconName" :size="props.iconFontSize" />
  </q-avatar>
</template>

<style lang="scss" scoped>
.q-icon {
  margin-block-start: v-bind(iconTopMargin);
}
</style>