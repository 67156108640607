<template>
  <h5>Client</h5>

  <ClientFeatures :client-features=props.clientFeatures ></ClientFeatures>
</template>

<script setup lang="ts">
import {defineProps, type PropType} from "vue";
import ClientFeatures from "@/pages/Client/components/ClientFeatures.vue";
import type {GetClientFeature} from "@/api/endpoints/clientWeb";

const props = defineProps({
  clientFeatures: {
  type: Object as PropType<GetClientFeature>
}
});

</script>

<style lang="scss">

  @import "@/css/styles-quasar";
</style>

