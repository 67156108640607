import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getLocalizedStrings } from '@/api/endpoints/localizationWeb';
import { i18n } from '@/plugins/i18n';

export const useLocalizationStore = defineStore('localization', () => {
  const locale = ref();
  const region = ref();

  const setLocaleMutation = (incLocale: string) => {
    locale.value = incLocale;
  }

  const setRegionMutation = (incRegion: string) => {
    region.value = incRegion.substring(3);
  }

  const setLocale = (locale: string) => {
    setLocaleMutation(locale);
  }

  const setRegion = (locale: string) => {
    setRegionMutation(locale);
  }

  const loadLocalizedStrings = async () => {
    try {
      const s = sessionStorage.getItem('localeStrings');
      if (s == null) throw new Error();
      else {
        const f = JSON.parse(s)
        if (f?.locale == null)
          throw new Error();
        setLocale(f.locale);
        setRegion(f.locale);
        i18n.global.locale = locale.value;
        i18n.global.setLocaleMessage(locale.value, f.strings);

        // double check that translations loaded correctly 
        if (i18n.global.t('error_unexpected_occured').toString() == 'error_unexpected_occured')
          throw new Error();
      }
    } catch (e) {
      const result = await getLocalizedStrings();
      if (result.locale != null) {
        const { locale : incLocale, strings } = result;
        sessionStorage.setItem('localeStrings', JSON.stringify(result));
        setLocale(incLocale);
        setRegion(incLocale);
        i18n.global.locale = locale.value;
        i18n.global.setLocaleMessage(locale.value, strings);
      }
    }
  }

  return {
    locale,
    region,
    loadLocalizedStrings,
    setRegion,
    setLocale,
  }
})
