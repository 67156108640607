<template>
  <q-table :columns="columnsComputed" :rows="rows" :row-key="rowKey" v-model:expanded="expandedRowKeys" flat wrap-cells
    square binary-state-sort :table-class="tableClassesComputed" ref="tableRef" :pagination="paginationComputed"
    :rows-per-page-options="rowsPerPageOptionsComputed" :hide-bottom="hidePagination">
    <template v-slot:top v-if="$slots.top">
      <slot name="top"></slot>
    </template>
    <template v-slot:header="props">
      <slot name="header" v-bind="{ ...props, hasExpandedRows, onExpandAll }"></slot>
    </template>
    <template v-slot:body="props">
      <slot name="body" v-bind="props"></slot>

      <slot name="body-expanded" v-bind="props">
        <q-tr :props="props" class="border-row-none" v-if="props.expand">
          <q-td key="expanded-row" colspan="100%" :props="props" class="q-px-none">
            <slot name="body-expanded-contents" v-bind="props" v-if="$slots['body-expanded-contents']"></slot>
          </q-td>
        </q-tr>
      </slot>
    </template>
  </q-table>
</template>

<script lang="js" setup>
import { ref, computed } from 'vue'
const props = defineProps({
  rowKey: String,
  columns: { type: Array, default: () => [] },
  rows: { type: Array, default: () => [] },
  hidePagination: { type: Boolean, default: false },
  expandable: { type: Boolean, default: false },
  tableClass: { type: Array, default: () => [] },
  sortBy: String,
  sort: { type: String, default: 'asc' }
})

const tableRef = ref()

const tableClassesComputed = computed(() => {
  return [
    ...(props.tableClass || []),
  ].filter(Boolean);
})

const columnsComputed = computed(() => {
  return [
    ...(props.columns.map(c => ({ align: 'left', ...c, })) || []),
    props.expandable ? { name: 'expanded-row', align: 'left' } : null
  ].filter(Boolean);
})

const hasExpandedRows = computed(() => expandedRowKeys.value.length > 0)

const onExpandAll = () => {
  const x = tableRef.value
  if (hasExpandedRows.value) {
    expandedRowKeys.value = []
    return false
  } else {
    expandedRowKeys.value = props.rows.map(row => row[props.rowKey])
    return true
  }
}

const paginationComputed = computed(() => {
  const obj = {
    sortBy: props.sortBy,
    descending: props.sort?.toLowerCase() !== 'asc',
  }

  if (props.hidePagination) {
    obj.rowsPerPage = 0
  }

  return obj
})
const rowsPerPageOptionsComputed = computed(() => props.hidePagination ? 0 : 5)

const expandedRowKeys = ref([])

defineExpose({
  reset: () => {
    expandedRowKeys.value = []
    tableRef.value = null
  }
})
</script>