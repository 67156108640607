<script lang="ts" setup>
import {
  defineModel,
  type PropType
} from 'vue';
import {FormElWidthEnum} from '@/constants/enums';
import {type ValidationRule} from "quasar";

const props = defineProps({
  autocomplete: {
    type: String,
    default: 'off'
  },
  autogrow: {
    type: Boolean,
    default: false
  },
  class: {
    type: String
  },
  disable: {
    type: Boolean,
    default: false,
  },
  for: {
    type: String
  },
  /*
   * Controls the width value of the form element. Options are:
   * qFormElNone - does not apply a class, therefore no width rule.
   * qFormElAuto - sets width to `auto`.
   * qFormEl100 - sets width to `100px`.
   * qFormEl150 - sets width to `150px`.
   * qFormEl200 - sets width to `200px`.
   * qFormEl250 - sets width to `250px`.
   * qFormEl310 - sets width to `310px`.
   */
  inputWidth: {
    type: String,
    default: FormElWidthEnum.qFormEl310
  },
  label: {
    type: String,
  },
  name: {
    type: String
  },
  placeholder: {
    type: String
  },
  readonly: {
    type: Boolean,
    default: false
  },
  clearable: {
    type: Boolean,
    default: false
  },
  rows: {
    type: Number,
    default: 3
  },
  rules: {
    type: Array as PropType<ValidationRule[]>,
    default: () => []
  },
  type: {
    type: String as PropType<'text' | 'password' | 'textarea' | 'email' | 'search' | 'tel' | 'file' | 'number' | 'url'
      | 'time' | 'date' | 'datetime-local'>
  },
  debounce: {
    type: Number
  }
});

const syncValue = defineModel<string | number | null | undefined>();
</script>

<template>
  
  <!-- Label -->
  <label v-if="props.label"
         :for="props.for">
    {{ props.label }}<slot name="label-append" />
  </label>
  
  <!-- Field -->
  <q-input v-model="syncValue"
           :autocomplete="props.autocomplete"
           :autogrow="props.autogrow"
           :class="props.class"
           :disable="props.disable"
           hide-bottom-space
           :input-class="[props.inputWidth]"
           :name="props.name"
           no-error-icon
           outlined
           :clearable="props.clearable"
           :debounce="props.debounce"
           :placeholder="props.placeholder"
           :readonly="props.readonly"
           :rows="props.type === 'textarea' ? props.rows : null"
           :rules="props.rules"
           :type="props.type" />
</template>