

export function getCookieValue(cookieName: string) {
  const allCookies = decodeURIComponent(document.cookie).split('; ');

  for (let i = 0; i < allCookies.length; i++) {
    const cookie = allCookies[i];

    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return '';
}
