<script setup lang="ts">
import {computed, nextTick, type Ref, ref, watch} from "vue";
import {AccountType, ButtonHeightEnum, ButtonStdColorEnum} from "@/constants/enums";
import { useQuasar } from 'quasar';
import {useRoute, useRouter} from 'vue-router';
import {getPatients, getPatientsWithFilter} from "@/api/endpoints/patientWeb";
import {useFilterStore} from "@/stores/filterStore";
import {storeToRefs} from "pinia";
import type {PhoneTelNumberInputModel, VueTelInputData} from "@/types/phoneTelNumberInputTypes";
import type {SupportUserProfile} from "@/api/models/supportUser";
import {i18n} from "@/plugins/i18n";
import moment from "moment";

const $q = useQuasar(), router = useRouter(), route = useRoute()

const filterStore = useFilterStore();
const { clearPatientFilterCount } = storeToRefs(filterStore)

const statusOptions = ref()

const getPatientFilterData = async () => {
  const patients = await getPatients()
  statusOptions.value = patients.data.statuses;
}

( async () => await getPatientFilterData())();

watch(clearPatientFilterCount,  () => onCancel())

// table data
const patientTable = ref()
const patientTableColumns = ref([
  { name: 'upId', field: 'upId', sortable: true, align: 'left', sortOrder: 'ad' },
  { name: 'status', field: 'status', sortable: true, align: 'left' },
  { name: 'firstName', field: 'firstName', sortable: true, align: 'left' },
  { name: 'lastName', field: 'lastName', sortable: true, align: 'left' },
  { name: 'email', field: 'email', sortable: true, align: 'left' },
  { name: 'phoneNumber', field: 'phoneNumber', sortable: true, align: 'left' },
  { name: 'client', field: 'client', sortable: true, align: 'left' },
  { name: 'clinician', field: 'clinician', sortable: true, align: 'left' }
])
const patientTableRows = ref([])

const loading = ref(false)
const filter = ref('')

const upIdModel = ref('');
const statusModel = ref('Status' );
const firstNameModel = ref('');
const lastNameModel = ref('');
const emailModel = ref('');
const clientNameModel = ref('');
const birthdateModel = ref('');
const mrnModel = ref('');
const studyIdModel = ref('');
const countryModel = ref('Client Country');
const clientTypeModel = ref('Client Type');
const clientsModel = ref('');
const permissionsModel = ref('');

const phoneTelNumberInputModel = ref({
  value: '',
  phoneObject: {} as VueTelInputData
});

const phoneInputRef = ref(null)
const isLoading = ref(true)
const emptyRepObject = { } as SupportUserProfile;
const supportRep = ref({ ...emptyRepObject })
const form = ref()
const showResults = ref(false);

const props = defineProps({
  minBirthDate: {
    type: String,
    default: moment().add(-110, 'year').format()
  },
  maxBirthDate: {
    type: String,
    default: moment().add(-13, 'year').format()
  },
})

nextTick(() => form.value.resetValidation())

const onCancel = () => {

  upIdModel.value = ''
  statusModel.value = 'Status'
  firstNameModel.value = ''
  lastNameModel.value = ''
  emailModel.value = ''
  clientNameModel.value = ''
  birthdateModel.value = ''
  mrnModel.value = ''
  studyIdModel.value = ''
  countryModel.value = 'Client Country'
  clientTypeModel.value = 'Client Type'
  clientsModel.value = ''
  permissionsModel.value = ''
  showResults.value = false;

  phoneTelNumberInputModel.value.value = ''
  phoneTelNumberInputModel.value.phoneObject = null
  
  initialPagination.value.sortBy = 'asc'
  initialPagination.value.descending = false
  
  nextTick(() => form.value.resetValidation())
  patientTableRows.value = []
  filterStore.patientFilterCount = 0
}

const onSubmit = async () => {
  const isValid = await form.value.validate()
  if (isValid) {
    $q.loading.show();
    filterStore.patientFilterCount = getFilterCount()

    const dob = moment(birthdateModel.value)
    const formattedDob = dob.isValid() ? dob.format('YYYY-MM-DD') : birthdateModel.value;

    initialPagination.value.page = 1;
    const filteredPatientData = await getPatientsWithFilter('true', AccountType.Patient, upIdModel.value,
        statusModel.value === 'Status' ? null : statusModel.value, firstNameModel.value, lastNameModel.value,
        emailModel.value, phoneTelNumberInputModel.value?.phoneObject?.nationalNumber, clientNameModel.value, formattedDob, mrnModel.value, studyIdModel.value,
        countryModel.value === 'Client Country' ? null : countryModel.value,
        clientTypeModel.value === 'Client Type' ? null : clientTypeModel.value, clientsModel.value, permissionsModel.value,
        initialPagination.value.descending, initialPagination.value.sortBy, initialPagination.value.page, initialPagination.value.rowsPerPage)

    patientTableRows.value = filteredPatientData.data.rows
    initialPagination.value.rowsNumber = filteredPatientData.data.totalRows
    showResults.value = true;
    $q.loading.hide();
  }
}

function getFilterCount() {
  var count = 0;
  if(upIdModel.value !== '') count++
  if(statusModel.value !== 'Status') count++
  if(firstNameModel.value !== '') count++
  if(lastNameModel.value !== '') count++
  if(emailModel.value !== '') count++
  if(phoneTelNumberInputModel.value.value != '') count++
  if(clientNameModel.value !== '') count++
  if(birthdateModel.value !== '') count++
  if(mrnModel.value !== '') count++
  if(studyIdModel.value !== '') count++
  if(countryModel.value !== 'Client Country') count++
  if(clientTypeModel.value !== 'Client Type') count++
  if(clientsModel.value !== '') count++
  if(permissionsModel.value !== '') count++
  
  return count
}

const onRowClick = (evt: number) => {
  router.push({
    name: 'Patient',
    params: {
      patientId: evt.toString()
    }
  });
}

const initialPagination = ref({
  sortBy: 'asc',
  descending: false,
  page: 1,
  rowsPerPage: 15,
  rowsNumber: 5
})

async function onRequest(props: any) {
  const { page, rowsPerPage, sortBy, descending } = props.pagination

  loading.value = true
  $q.loading.show();

  console.log('calling api sql query')
  
  const filteredPatientData = await getPatientsWithFilter('true', AccountType.Patient, upIdModel.value,
      statusModel.value === 'Status' ? null : statusModel.value, firstNameModel.value, lastNameModel.value,
      emailModel.value, phoneTelNumberInputModel.value?.phoneObject?.nationalNumber, clientNameModel.value, birthdateModel.value, mrnModel.value, studyIdModel.value,
      countryModel.value === 'Client Country' ? null : countryModel.value,
      clientTypeModel.value === 'Client Type' ? null : clientTypeModel.value, clientsModel.value, permissionsModel.value,
      descending, sortBy, page, rowsPerPage)

  loading.value = false
  $q.loading.hide();

  patientTableRows.value = filteredPatientData.data.rows

  initialPagination.value.page = page
  initialPagination.value.sortBy = sortBy
  initialPagination.value.descending = descending
  initialPagination.value.rowsNumber = filteredPatientData.data.totalRows
}


</script>

<template>
  <q-page padding>

    <div class="q-pb-xl">
      <h1>{{ $t('gen_patients') }}</h1>
    </div>

    <div class="row">
      <div class="col bg-white q-pa-xl rep-profile__form">
        <q-form @submit.prevent ref="form">
          <zbm-skeleton-loader height="22.5714285714rem" width="100%">

            <div class="row mb-20 rep-profile__fields">

              <div class="col q-pa-sm">
                <q-input outlined v-model="upIdModel" placeholder="UPID" />
              </div>
              <div class="col q-pa-sm">
                <q-select outlined v-model="statusModel" :options="statusOptions" placeholder="Status" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="lastNameModel" placeholder="Last Name" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="firstNameModel" placeholder="First Name" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="emailModel" placeholder="Email" />
              </div>
            </div>
            <div class="row mb-20 rep-profile__fields">
              <div class="col q-pa-sm">
                <zbm-telephone-with-validation v-model="phoneTelNumberInputModel" :disable="supportRep.isDeactivated"
                                               :input-width="$zb.enums.FormElWidthEnum.qFormElFull" countryCode="US"
                                               placeholder="Phone Number" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="clientNameModel" placeholder="Client Name" />
              </div>
              <div class="col q-pa-sm">
                <zbm-date-picker v-model="birthdateModel" placeholder="Birth Date" :showIcon=true
                                 :maxDate="maxBirthDate" :minDate="minBirthDate" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="mrnModel" placeholder="MRN" />
              </div>
              <div class="col q-pa-sm">
                <q-input outlined v-model="studyIdModel" placeholder="Study ID" />
              </div>
            </div>
            <!-- Form Buttons -->
            <div class="row items-center q-col-gutter-x-sm rep-profile__buttons">
              <!-- Cancel -->
              <div class="col-auto q-ml-auto">
                <zbm-skeleton-loader height="2.8571428571rem" width="3.1428571429rem">
                  <zbm-btn @click="onCancel" :color="$zb.enums.ButtonStdColorEnum.darkTealText"
                           :height="$zb.enums.ButtonHeightEnum.md"
                           :label="$t('gen_clear_filters')" :data-testid="'btnCancel'" />
                </zbm-skeleton-loader>
              </div>

              <!-- Save -->
              <div class="col-auto">
                <zbm-skeleton-loader height="2.8571428571rem" width="11.5714285714rem">
                  <zbm-btn @click="onSubmit" :color="$zb.enums.ButtonStdColorEnum.darkTeal"
                           :height="$zb.enums.ButtonHeightEnum.md" :label="$t('patient_search_go_button')"
                           :type="$zb.enums.ButtonTypeEnum.submit" :data-testid="'btnSave'" />
                </zbm-skeleton-loader>
              </div>
            </div>
          </zbm-skeleton-loader>

        </q-form>
      </div>
    </div>

    <div v-if="showResults">
      <zbm-table :columns="patientTableColumns" :rows="patientTableRows" row-key="upId"
                 expandable :hide-pagination="false" ref="patientTable"
                 v-model:pagination="initialPagination"
                 :loading="loading"
                 @request="onRequest">
        <template v-slot:header="props">
          <q-tr :props="props">
            <q-th key="upId" :props="props" class="text-left">{{ $t('gen_upid') }}</q-th>
            <q-th key="status" :props="props" class="text-left">{{ $t('gen_status') }}</q-th>
            <q-th key="firstName" :props="props" class="text-left">{{ $t('gen_first_name') }}</q-th>
            <q-th key="lastName" :props="props" class="text-left">{{ $t('gen_last_name') }}</q-th>
            <q-th key="email" :props="props" class="text-left">{{ $t('gen_email') }}</q-th>
            <q-th key="phoneNumber" :props="props" class="text-left">{{ $t('gen_phone') }}</q-th>
            <q-th key="client" :props="props" class="text-left">{{ $t('gen_client') }}</q-th>
            <q-th key="clinician" :props="props" class="text-left">{{ $t('gen_clinician') }}</q-th>
          </q-tr>
        </template>
        <template v-slot:body="props">
          <q-tr :props="props" :key="`row-${props.row.upId}`" class="cursor-pointer"
                @click="onRowClick(props.row.upId)">
            <q-td key="upId" :props="props" no-hover>
              {{ props.row.upId }}
            </q-td>

            <q-td key="status" :props="props" no-hover>
              {{ props.row.status }}
            </q-td>

            <q-td key="firstName" :props="props" no-hover>
              {{props.row.firstName }}
            </q-td>

            <q-td key="lastName" :props="props" no-hover>
              {{props.row.lastName }}
            </q-td>

            <q-td key="email" :props="props" no-hover>
              {{props.row.email }}
            </q-td>

            <q-td key="phoneNumber" :props="props" no-hover>
              {{props.row.phoneNumber }}
            </q-td>

            <q-td key="client" :props="props" no-hover>
              {{props.row.client }}
            </q-td>

            <q-td key="clinician" :props="props" no-hover>
              {{props.row.clinician }}
            </q-td>

          </q-tr>
        </template>

      </zbm-table>
    </div>
  </q-page>
</template>
