
//  REMEMBER, Document Your Component in src/vue.dt.s in order to expose it to Intellisense

import InputWithValidation from '@/components/form/InputWithValidation.vue'
import PasswordInputWithValidation from "@/components/form/PasswordInputWithValidation.vue";
import PhoneTelNumberInput from '@/components/form/PhoneTelNumberInput.vue'
import RadioGroupWithValidation from '@/components/form/RadioGroupWithValidation.vue'
import DatePickerWithValidation from '@/components/form/DatePickerWithValidation.vue'
import SelectWithValidation from '@/components/form/SelectWithValidation.vue'
import Field from '@/components/form/Field.vue'
import Checkbox from '@/components/form/Checkbox.vue'
import Required from '@/components/form/Required.vue'
import Optional from '@/components/form/Optional.vue'
import SkeletonLoader from '@/components/SkeletonLoader.vue'
import BtnStandard from '@/components/BtnStandard.vue'
import BtnLink from '@/components/BtnLink.vue'
import BtnIcon from '@/components/BtnIcon.vue'
import DialogDefault from '@/components/DialogDefault.vue'
import Table from '@/components/Table.vue'
import MarkupTable from '@/components/MarkupTable.vue'
import Avatar from '@/components/Avatar.vue'
import Box from '@/components/Box.vue'

export function registerCustomComponents(app) {
  app.component('zbm-skeleton-loader', SkeletonLoader)
  app.component('zbm-radio-group-with-validation', RadioGroupWithValidation)
  app.component('zbm-input-with-validation', InputWithValidation)
  app.component('zbm-password-input-with-validation', PasswordInputWithValidation)
  app.component('zbm-telephone-with-validation', PhoneTelNumberInput)
  app.component('zbm-select-with-validation', SelectWithValidation)
  app.component('zbm-checkbox', Checkbox)
  app.component('zbm-date-picker', DatePickerWithValidation)
  app.component('zbm-field', Field)
  app.component('zbm-btn', BtnStandard)
  app.component('zbm-btn-link', BtnLink)
  app.component('zbm-btn-icon', BtnIcon)
  app.component('zbm-dialog', DialogDefault)
  app.component('zbm-table', Table)
  app.component('zbm-markup-table', MarkupTable)
  app.component('zbm-avatar', Avatar)
  app.component('zbm-box', Box)
  app.component('zbm-optional', Optional)
  app.component('zbm-required', Required)
}

