import { createApp } from 'vue';
import {
    createRouter,
    createWebHistory
} from 'vue-router';
import App from './App.vue';
import { i18n } from '@/plugins/i18n';
import { createPinia } from 'pinia';
import guardCheck from './router/guards';
import routes from './router/routes';
import { registerCustomComponents } from './component-registration';
import { registerQuasar } from './plugins/quasar';
import { registerVueTelInput } from './plugins/vue-tel-input';
import { registerVCalendar } from './plugins/v-calendar';
import * as Enums from '@/constants/enums';
import * as Validators from '@/plugins/quasar-validators'
import { setupInactivityRedirect } from '@/utils/idleLogout'

const app = createApp(App);

registerQuasar(app)
registerCustomComponents(app)
registerVueTelInput(app)
registerVCalendar(app)

function useGlobalEnums(app: any) {
  //Make console available in template bindings for debugging purposes
  app.config.globalProperties.productionTip = false;
  app.config.globalProperties.console = window.console;
  app.config.globalProperties.$zb = {
    enums: Enums,
    validators: Validators
  };
}

const router = createRouter({ history: createWebHistory(), routes });
router.beforeEach(guardCheck);

useGlobalEnums(app);
app.use(router)
app.use(i18n)
app.use(createPinia());
app.mount('#app');

setupInactivityRedirect()
