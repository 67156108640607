import axios from 'axios'

export { isAxiosError } from 'axios'

const csrf_token = (function(){
  let csrfToken;

  if (document.cookie.indexOf('CSRF-TOKEN') > -1) {
    const v = document.cookie.match('(^|;) ?' + 'CSRF-TOKEN' + '=([^;]*)(;|$)');

    csrfToken = v ? v[2] : '';
  } else {
    csrfToken = '';
  }
  return csrfToken;
}());

const axiosInstance = axios.create({
  timeout: 60000,
  headers: {
    post: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-TOKEN': csrf_token,
      'X-Requested-With': 'XMLHttpRequest'
    },
    put: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-CSRF-TOKEN': csrf_token,
      'X-Requested-With': 'XMLHttpRequest'
    },
    get: {
      'X-Requested-With': 'XMLHttpRequest'
    }
  }
})

axiosInstance.interceptors.response.use(function (response) {
  if (response && response.status === 200 && response.headers?.location) {
    return Promise.reject();
  }

  return response;

}, function ({response}) {
  if (response && response.status === 401) {
    const authenticationUrl = response.headers['x-authentication-url'];
    if (authenticationUrl) {
      window.location.href = authenticationUrl;
    }
  }

  return Promise.reject(response);
});
export default axiosInstance
