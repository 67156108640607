<template>
  <div class="q-pa-md">
    <q-table id="careTeamTable"
             :rows="rows"
             :columns="columns"
             row-key="surgeonId"
             hide-bottom
             v-model:pagination="pagination"
             binary-state-sort
             :rows-per-page-options="[0]"
             title="{{ props.careTeam?.careTeamName }}">
      <template v-slot:top>
        <h5 class="careTeamName">{{ props.careTeam?.careTeamName }}</h5>
      </template>

      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="surgeonName" :props="props">
            {{ props.row.nameLocalized }}
          </q-td>

          <q-td key="role" id="role" :props="props">
            {{ props.row.title }}
          </q-td>

          <q-td key="lastLogin" id="lastLogin" :props="props">
            {{ props.row.lastLogin.formattedDateTime }}
          </q-td>

          <q-td key="permissions" :props="props" class="permissions">
            <q-btn outline disable v-if="props.row.isAdmin == true">
              {{ $t('gen_admin_initialCap') }}
            </q-btn>
            <q-btn outline disable v-if="props.row.isSurgeon == true">
              {{ $t('gen_surgeon_initialCap') }}
            </q-btn>
            <q-btn outline disable v-if="props.row.isRosa == true">
              {{ $t('gen_rosa_uppercase') }}
            </q-btn>
            <q-btn outline disable v-if="props.row.isPiq == true">
              {{ $t('gen_piq_uppercase') }}
            </q-btn>
          </q-td>

          <q-td key="contacts" :props="props">
            <q-icon id="phoneIcon" name="fa fa-solid fa-phone fa-flip-horizontal" size="12px" @click="copyPhone(formatPhoneNumber(props.row.phoneCountryCode, props.row.phone))"
                    style="padding-right: 5px; cursor: pointer;" v-if="props.row.phone != null && props.row.phone.length > 0">
              <q-tooltip class="contactPhoneNumber text-body2">
                {{ formatPhoneNumber(props.row.phoneCountryCode, props.row.phone) }}
              </q-tooltip>
            </q-icon>
            <span id="contactsSpace" v-if="props.row.email != null && props.row.email.length > 0" />
            <q-icon id="emailIcon" name="fa fa-solid fa-envelope" size="12px" @click="copyEmail(props.row.email)"
                    style="padding-left: 10px; cursor: pointer;" v-if="props.row.email != null && props.row.email.length > 0">
              <q-tooltip class="contactEmail text-body2">
                {{ props.row.email }}
              </q-tooltip>
            </q-icon>
          </q-td>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script setup lang="ts">
  import { type CareTeam } from "@/api/endpoints/patientWeb";
  import { parsePhoneNumber, type CountryCode } from 'libphonenumber-js';
  import { defineProps, ref, computed } from 'vue';
  import { i18n } from '@/plugins/i18n';
  import { useQuasar, copyToClipboard } from 'quasar'

  const $q = useQuasar();

  const props = defineProps<{
    careTeam: CareTeam
  }>(),
    fullNameAndTitleLabel: string = i18n.global.t('gen_name_initialCap'),
    roleLabel: string = i18n.global.t('gen_role'),
    lastLoginLabel: string = i18n.global.t('gen_last_login'),
    permissionsLabel: string = i18n.global.t('gen_permissions'),
    contactsLabel: string = i18n.global.t('gen_contacts');

  const columns = [
    {
      name: 'surgeonName',
      required: true,
      label: `${fullNameAndTitleLabel}`,
      align: 'left',
      field: row => row.nameLocalized,
      sortable: true
    },
    {
      name: 'role',
      label: `${roleLabel}`,
      field: 'title',
      sortable: true,
      align: 'left'
    },
    {
      name: 'lastLogin',
      label: `${lastLoginLabel}`,
      field: row => row.lastLogin.rawDateTime,
      format: row => row.lastLogin.formattedDateTime,
      align: 'left',
      sortable: true,
      //classes: 'text-right',
      //headerClasses: 'text-right'
    },
    {
      name: 'permissions',
      label: `${permissionsLabel}`,
      field: 'isAdmin',
      align: 'left',
      //: 'text-right',
      //headerClasses: 'text-right'
    },
    {
      name: 'contacts',
      label: `${contactsLabel}`,
      field: 'phone',
      align: 'right',
      classes: 'text-right contacts',
      headerClasses: 'text-right'
    },
  ];

  const rows = computed(() => {
    return props.careTeam?.members;
  });

  const pagination = ref({
    rowsPerPage: 0,
    sortBy: 'lastLogin',
    descending: true
  })

  const formatPhoneNumber = (countryCodeNumber: string, phoneNumber: string) => {
    if (!countryCodeNumber || !phoneNumber || phoneNumber?.length < 3)
      return phoneNumber;

    let fullNumber = '+' + countryCodeNumber + phoneNumber;
    let countryCodeStr = (countryCodeNumber == '1' ? 'US' : '');
    let parsedPhoneNumber = parsePhoneNumber(fullNumber, countryCodeStr as CountryCode);

    return parsedPhoneNumber
      ? parsedPhoneNumber.formatInternational()
      : countryCodeNumber + " " + phoneNumber;
  };

  const copyPhone = (phoneNumber: string) => {
      copyToClipboard(phoneNumber)
        .then(() => {
          // success
        })
        .catch(() => {
          // fail
        })
  }

  const copyEmail = (email: string) => {
    copyToClipboard(email)
      .then(() => {
        // success
      })
      .catch(() => {
        // fail
      })
  }
</script>

<style lang="scss">
  @import "@/css/styles-quasar";

  .careTeamName {
    //font-size: 20pt;
    //color: $primary-color;
    margin-left: 5px;
    padding-bottom: 15px;
  }

  #careTeamTable tr {
    th {
      background-color: $primary-faint-background;
    }

    .contacts {
      max-width: 45px;
      white-space: nowrap;
    }

    .permissions button {
      margin-right: 4px;
      margin-bottom: 4px;
      padding: 2px 5px 2px 5px;
      font-size: 10px;
      &:last-child {
        margin-inline: 0;
      }
    }
  }

  #permissionsSpace {
    margin: 3px;
  }

  #contactsSpace {
    margin: 10px;
  }

  #phoneIcon,
  #emailIcon {
    color: gray;
  }
</style>
